export enum EventKey {
  PRODUCT_DETAILS_VIEWED="product_details.viewed",
  PRODUCT_VIEWED="product.viewed",
  CART_ADDITION="cart.add",
  CART_REMOVAL="cart.remove",

  SEARCH="search",

  ORDER_CREATED="order.created",
}

export interface Event {
  name: EventKey,
  category?: string,
  label?: string,
  nonInteraction?: boolean;
}

interface MapObject {
  [k: string]: any;
}

export class MetricsService {
  enabled = false;
  gtag: any = null;

  constructor() {
    this.enabled = process.env.VUE_APP_METRICS_ENABLED === "true";
  }

  init(gtag: any) {
    this.gtag = gtag;
  }

  emitEvent(event: Event, details: MapObject) {
    if ( this.enabled && !this.gtag ) {
      console.error('Internal GTag object has not been initialized');
      return;
    }

    if ( this.enabled ) {
      console.log('emiting metric', this.enabled, process.env.VUE_APP_METRICS_ENABLED, details);
      this.gtag.event(event.name, Object.assign({
        event_category: event.category,
        event_label: event.label,
        non_interaction: event.nonInteraction
      }, details));
    }
  }
}

export default new MetricsService();
