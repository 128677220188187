<template>
  <div id="cho-container" class="cho-container"></div>
</template>

<script>
export default {
  name: 'Payment',
  props: ['preferenceId'],
  watch: {
    preferenceId: function(prefId) {
      console.log('Updated prefrence id: ' + prefId);
      if (prefId) {
        // Agrega credenciales de SDK
        try {
          const mp = new MercadoPago(this.public_key, {
            locale: 'es-MX'
          });

          // Inicializa el checkout
          mp.checkout({
            preference: {
              id: prefId
            },
            autoOpen: true
          });
        } catch (e) {
          console.log(e);
        }
      }
    }
  },
  computed: {
    public_key: function() {
      return this.$store.getters['application/public_key'];
    }
  }
};
</script>

<style lang="css" scoped></style>
