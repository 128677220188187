var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"mx-4 p-4"},[_c('div',{staticClass:"flex items-center"},[_vm._l((_vm.steps),function(step,index){return [_c('div',{key:'step-' + index,staticClass:"flex items-center",class:[index !== _vm.steps.length - 1 ? 'w-full' : '']},[_c('div',{staticClass:"relative flex flex-col items-center",class:_vm.step_txt[_vm.getStepStatus(index, step)]},[_c('div',{staticClass:"rounded-full transition duration-500 ease-in-out border-2 h-12 w-12 flex items-center justify-center py-3",class:[
                _vm.step_bg[_vm.getStepStatus(index, step)],
                _vm.step_border[_vm.getStepStatus(index, step)]
              ]},[_c('i',{class:[
                  _vm.getStepStatus(index, step) === 'completed'
                    ? 'fa fa-check'
                    : step.icon
                ],attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"absolute top-0 text-center mt-14 w-32 text-xs font-medium uppercase ",class:_vm.step_label[_vm.getStepStatus(index, step)]},[_vm._v(" "+_vm._s(step.title)+" ")])]),(index < _vm.steps.length - 1)?_c('div',{staticClass:"flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300 ",class:[
              _vm.getStepStatus(index, step) === 'completed'
                ? 'border-green-600'
                : ''
            ]}):_vm._e()])]})],2)]),_c('div',{class:['animated-content', _vm.getLoading]},[_c('transition',{attrs:{"enter-active-class":_vm.enterAnimation,"leave-active-class":_vm.leaveAnimation,"mode":"out-in"}},[(_vm.keepAliveData)?_c('keep-alive',[_c(_vm.steps[_vm.currentStep.index].component,{tag:"component",on:{"next":_vm.nextStep,"loading":_vm.isLoading}})],1):_c(_vm.steps[_vm.currentStep.index].component,{tag:"component"})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }