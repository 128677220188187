<template>
  <div class="ui main container">
    <div class="font-bold text-center text-xl sm:text-3xl text-gray-800">
      Productos en Preventa
    </div>
    <div class="sixteen wide computer sixteen wide tablet column">
      <ProductList2 :isMatrix="isMatrix" :isPresale="true" />
      <div v-if="showLoader">
        <br />
        <sui-loader active centered inline>Cargando más productos</sui-loader>
        <br />
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import ProductList2 from '../components/product/ProductList2';

export default {
  name: 'Presale',
  data() {
    return {
      showLoader: false
    };
  },
  computed: {
    busy: function() {
      return this.$store.getters['products/isBusy'];
    },
    isMatrix: function() {
      return this.$store.getters['application/isMatrix'];
    }
  },
  created: async function() {
    try {
      const resp = await this.$store.dispatch('auth/refreshCode');
      this.loadCategoryProds();
    } catch (e) {
      this.$router.push({ name: 'PresaleLogin' }).catch(() => {});
    }
  },
  methods: {
    loadMore: async function() {
      const resp = await this.$store.dispatch('products/presale');
    },
    loadCategoryProds: async function(payload) {
      await this.$store.dispatch('products/presale', payload);
    }
  },
  components: {
    ProductList2
  }
};
</script>

<style>
.main.container {
  margin-top: 7em;
}
</style>
