<template>
  <div class="ui main container">
    <div class="flex justify-center my-6">
      <div
        class="flex flex-col w-full px-0 py-8 text-gray-800 bg-white shadow-lg pin-r pin-y md:w-4/5 lg:w-4/5"
      >
        <div class="flex-1">
          <div class="flex px-8">
            <div class="flex-1 text-2xl text-gray-900 font-bold w-64">Carrito</div>
            <div class="self-end">
              <a @click="goHome" class="cursor-pointer underline"> Seguir comprando </a>
            </div>
          </div>

          <div class="bg-white rounded my-4">
            <table class="w-full text-sm lg:text-base" cellspacing="0">
              <thead>
                <tr class="bg-gray-300 text-gray-600 uppercase text-sm leading-normal">
                  <th class="hidden md:table-cell"></th>
                  <th class="py-3 px-3 text-left">Producto</th>
                  <th class="lg:text-center text-left pl-2 lg:pl-0">
                    <span class="lg:hidden" title="Cantidad">Cant</span>
                    <span class="hidden lg:inline">Cantidad</span>
                  </th>
                  <th class="hidden text-center md:table-cell">Precio Unitario</th>
                  <th class="pr-3 text-right">Total</th>
                </tr>
              </thead>
              <tbody v-if="totalItems > 0">
                <ProductDetails
                  v-for="product in cart.products"
                  :key="product.attributeId"
                  :product="product"
                  :show-buttons="true"
                  :isWholesale="isWholesale"
                  :isSlave="isSlave"
                  @updateQuantity="openUpdate"
                  @removeProduct="openRemoveDialog"
                />
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6">
                    <h2 class="text-lg text-gray-500">
                      No tienes productos en el carrito
                    </h2>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="my-4 mt-6 mx-4 lg:flex">
            <div class="lg:px-2 lg:w-1/2"></div>
            <div class="lg:px-2 lg:w-1/2">
              <div class="p-4 bg-gray-200 rounded-full">
                <h1 class="ml-2 font-bold uppercase">Total del pedido</h1>
              </div>
              <div class="p-4">
                <p class="mb-6 italic">
                  Envío y manejo serán calculados una vez que se seleccione un método de
                  envío
                </p>
                <div class="flex justify-between border-b">
                  <div
                    class="lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-center text-gray-800"
                  >
                    Total
                  </div>
                  <div
                    class="lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-center text-gray-900"
                  >
                    {{ total | numeralFormat("$ 0,0.00") }}
                  </div>
                </div>

                <button
                  class="flex justify-center w-full px-10 py-3 mt-10 font-medium text-white uppercase bg-gray-700 rounded-full shadow item-center hover:bg-gray-600 focus:shadow-outline focus:outline-none"
                  @click="checkout"
                  v-if="totalItems > 0"
                >
                  <svg
                    aria-hidden="true"
                    data-prefix="far"
                    data-icon="credit-card"
                    class="w-8"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="currentColor"
                      d="M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z"
                    />
                  </svg>
                  <span class="ml-2 mt-5px">Continuar con el pago</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sui-modal v-model="open" size="mini">
      <sui-modal-header>Actualizar cantidad</sui-modal-header>
      <sui-modal-content image>
        <sui-modal-description>
          <div class="ui form">
            <h3>{{ name }}</h3>
            <table class="ui very basic compact table unstackable">
              <thead>
                <tr>
                  <th class="one wide" scope="col">Existencias</th>
                  <th class="one wide" scope="col">Cantidad</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="one wide">
                    {{ maxQuantity }}
                  </td>
                  <td class="two wide">
                    <vue-numeric-input
                      v-model="tmpQuantity"
                      :min="0"
                      :max="maxQuantity"
                      :step="1"
                      size="100px"
                      align="center"
                    ></vue-numeric-input>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <button class="ui gray button" @click="closeModal">Cancelar</button>
        <button class="ui black button" @click="updateQuantity">Aceptar</button>
      </sui-modal-actions>
    </sui-modal>
    <sui-modal v-model="openRemove" size="mini">
      <sui-modal-header>¿Desea eliminar el producto del carrito?</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <sui-header>{{ currentQuantity }} Pieza(s) - {{ name }} </sui-header>
          <p>
            <b></b>
          </p>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <button class="ui gray button" @click="closeRemove">Cancelar</button>
        <button class="ui black button" @click="remove">Aceptar</button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import VueNumericInput from "vue-numeric-input";
import ProductDetails from "../components/product/ProductDetails2";
import MetricsService, { EventKey } from "@/services/metrics";

export default {
  name: "Cart",
  data: function () {
    return {
      open: false,
      openRemove: false,
      name: "",
      maxQuantity: 0,
      currentQuantity: 0,
      tmpQuantity: 0,
      newProds: false,
    };
  },
  created: function () {
    this.$store.dispatch("cart/load");
  },
  computed: {
    cart: function () {
      return this.$store.getters["cart/getCart"];
    },
    total: function () {
      return this.$store.getters["cart/total"];
    },
    totalItems: function () {
      return this.$store.getters["cart/totalItems"];
    },
    wholesaleQty: function () {
      return this.$store.getters["application/getWholeSaleQty"];
    },
    isWholesaleEnabled: function () {
      return this.$store.getters["application/isWholesaleEnabled"];
    },
    isSlave: function () {
      return this.$store.getters["application/isSlave"];
    },
    isWholesale: function () {
      if (this.isWholesaleEnabled) {
        return this.totalItems >= this.wholesaleQty;
      } else {
        return false;
      }
    },
  },
  methods: {
    openUpdate: async function (product) {
      const stocks = await this.$store.dispatch("products/getStock", product.productId);
      const stock = stocks.find((s) => s._id === product._id);

      const isPresale = this.cart.isPresale;

      this.newProds = stocks.every((s) => s.stock === 0);

      console.log("isPresale: ", isPresale);
      console.log("newProds: ", this.newProds);

      if (isPresale && this.newProds) {
        this.maxQuantity = 10 - product.quantity;
      } else {
        this.maxQuantity = stock.stock + product.quantity;
      }

      this.tmpQuantity = product.quantity;
      this.currentQuantity = product.quantity;
      this.name = product.name;
      this.attributeId = product._id;
      this.productId = product.productId;
      this.open = !this.open;
    },

    closeModal: function () {
      this.open = !this.open;
    },

    updateQuantity: async function () {
      if (this.currentQuantity !== this.tmpQuantity) {
        if (Number.isInteger(this.tmpQuantity)) {
          const payload = {
            attributeId: this.attributeId,
            newQuantity: this.tmpQuantity,
            productId: this.productId,
          };

          try {
            const resp = await this.$store.dispatch("cart/updateQuantity", payload);
          } catch (e) {
            console.log(e);
          }
        } else {
          console.log("new quantity is not a number");
        }
      }
      this.open = !this.open;
    },
    openRemoveDialog: function (product) {
      this.name = product.name;
      this.attributeId = product._id;
      this.productId = product.productId;
      this.currentQuantity = product.quantity;
      this.openRemove = !this.openRemove;
    },
    remove: async function () {
      const payload = {
        attributeId: this.attributeId,
        newQuantity: 0,
        productId: this.productId,
      };
      try {
        const resp = await this.$store.dispatch("cart/updateQuantity", payload);
      } catch (e) {
        console.log(e);
      }
      // metric cart removal
      MetricsService.emitEvent(
        { name: EventKey.CART_REMOVAL },
        {
          product_name: this.name,
        }
      );
      this.openRemove = !this.openRemove;
    },
    closeRemove: function () {
      this.openRemove = !this.openRemove;
    },
    checkout: function () {
      this.$router.push({ name: "Checkout" });
    },
    goHome: function () {
      this.$router.push({ name: "Home" });
    },
  },
  components: {
    ProductDetails,
    VueNumericInput,
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.main.container {
  margin-top: 7em;
}
</style>
