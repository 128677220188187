import axios from 'axios';

const state = {};

const getters = {};

const actions = {
  getByKey: ({ commit, getters }, key) => {
    return new Promise(
      (resolve, reject) => {
        axios.get(`/api/v1/payment-methods/${key}`).then(response => {
          resolve(response.data);
        });
      },
      error => {
        reject(error);
      }
    );
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
