<template>
  <div class="p-5 ui main container">
    test
  </div>
</template>

<script>
export default {
  name: 'Test'
};
</script>

<style></style>
