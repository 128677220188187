import axios from 'axios';

const state = {
  prices: []
};

const getters = {
  getIncrement: state => id => {
    const obj = state.prices.find(p => p.brandId === id);

    return obj ? obj.increment : 0;
  }
};

const actions = {
  loadPrices: ({ commit, rootGetters }) => {
    const appId = rootGetters['application/getId'];

    axios.get(`/api/v1/app/${appId}/prices`).then(response => {
      commit('LOAD_PRICES_SUCCESS', response.data);
    });
  }
};

const mutations = {
  ['LOAD_PRICES_SUCCESS']: (state, payload) => {
    state.prices = payload.prices;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
