<template>
  <div class="ui left aligned basic segment">
    <form class="ui form">
      <div
        class="field required"
        :class="{ error: $v.tmpAddress.street.$error }"
      >
        <label>Calle y Número</label>
        <input
          type="text"
          name="shipping[first-name]"
          placeholder="Encino 1328"
          v-model.trim="$v.tmpAddress.street.$model"
        />
      </div>
      <div
        class="field required"
        :class="{ error: $v.tmpAddress.neigh.$error }"
      >
        <label>Colonia</label>
        <input
          type="text"
          name="shipping[first-name]"
          placeholder="Colonia"
          v-model.trim="$v.tmpAddress.neigh.$model"
        />
      </div>
      <div
        class="field required"
        :class="{ error: $v.tmpAddress.postalCode.$error }"
      >
        <label>Código Postal</label>

        <input
          type="number"
          name="shipping[first-name]"
          placeholder="63000"
          v-model.trim="$v.tmpAddress.postalCode.$model"
        />
      </div>
      <div class="field required" :class="{ error: $v.tmpAddress.city.$error }">
        <label>Ciudad</label>

        <input
          type="text"
          name="shipping[first-name]"
          placeholder="Guadalajara"
          v-model.trim="$v.tmpAddress.city.$model"
        />
      </div>
      <div
        class="field required"
        :class="{ error: $v.tmpAddress.state.$error }"
      >
        <label>Estado</label>
        <input
          type="text"
          name="shipping[first-name]"
          placeholder="Jalisco"
          v-model.trim="$v.tmpAddress.state.$model"
        />
      </div>
      <div class="field disabled required">
        <label>País</label>
        <input
          type="text"
          name="shipping[first-name]"
          placeholder="Jalisco"
          v-model="tmpAddress.country"
        />
      </div>
      <button
        class="ui red tiny button"
        type="button"
        name="button"
        @click="cancel"
      >
        Cancelar
      </button>
      <button
        class="ui primary tiny button"
        type="button"
        name="button"
        @click="save"
        :class="{ disabled: $v.$invalid }"
      >
        Guardar
      </button>
    </form>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'AddressForm',
  props: ['address'],
  data: function() {
    return {
      tmpAddress: {
        street: '',
        neigh: '',
        postalCode: '',
        city: '',
        state: '',
        country: 'México'
      }
    };
  },
  created: function() {
    this.tmpAddress = _.cloneDeep(this.address);
  },
  watch: {
    address: function(obj) {
      this.tmpAddress = _.cloneDeep(obj);
    }
  },
  validations: {
    tmpAddress: {
      street: {
        required
      },
      neigh: {
        required
      },
      postalCode: {
        required,
        minLength: minLength(5)
      },
      city: {
        required
      },
      state: {
        required
      }
    }
  },
  methods: {
    save: function() {
      this.$emit('onSave', this.tmpAddress);
    },
    cancel: function() {
      this.$emit('onCancel');
    }
  }
};
</script>

<style></style>
