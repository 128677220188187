<template>
  <div class="ui main container">
    <CategorySelector @onSelectionChanged="loadCategoryProds" />
    <div class="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 mt-6 ui bg-transparent">
      <div class="flex flex-col md:flex-row -mx-4">
        <ProductGallery :images="product.images" />
        <div class="md:flex-1 px-8">
          <h2
            class="mb-2 leading-tight tracking-tight font-bold text-gray-800 text-2xl md:text-3xl"
          >
            {{ product.name }}
          </h2>
          <p class="text-gray-500 text-lg">
            <a href="#" class="text-gray-600 hover:underline">{{
              product.brands[0].name
            }}</a>
          </p>

          <div class="flex items-center space-x-4 my-8">
            <div>
              <div class="rounded-lg flex py-2 px-3">
                <span
                  v-if="showWholesalePrice"
                  class="font-bold text-gray-600 text-3xl"
                  >{{ wholesalePrice | numeralFormat('$ 0,0.00') }}</span
                >
                <span v-else class="font-bold text-gray-600 text-3xl">{{
                  price | numeralFormat('$ 0,0.00')
                }}</span>
              </div>
            </div>
            <div class="flex-1" v-if="showWholesalePrice">
              <p class="text-green-500 text-xl font-semibold">
                {{ price | numeralFormat('$ 0,0.00') }}
              </p>
              <p class="text-gray-400 text-sm">
                El precio menor se refleja en compras mayores a 10 piezas
              </p>
            </div>
          </div>

          <p class="text-gray-500">
            {{ product.description }}
          </p>

          <div class="flex my-8">
            <span
              class=" border border-2 rounded-l px-4 py-2 bg-gray-800 whitespace-no-wrap text-white"
              >Talla</span
            >
            <div class="flex-initial w-32">
              <sui-dropdown
                fluid
                placeholder="Tamaño"
                selection
                :options="stockOpts"
                v-model="current"
              />
            </div>
          </div>

          <div class="flex py-4 space-x-4">
            <NumberInput
              v-model="quantity"
              :min="1"
              :max="currentStock.stock"
              :step="1"
              :precision="0"
              align="center"
              width="w-16"
              height="h-14"
            />

            <button
              @click="addTocart"
              type="button"
              class="h-14 px-6 my-1 font-semibold rounded-xl bg-gray-800 hover:bg-gray-600 text-white"
            >
              Agregar al carrito
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumberInput from '../../components/NumberInput';
import ProductGallery from '../../components/product/ProductGallery';
import CategorySelector from '../../components/app/CategorySelector';
import MetricsService, { EventKey } from '@/services/metrics';

export default {
  name: 'ProductDetails2',
  data: function() {
    return {
      current: '',
      quantity: 1,
      product: {
        name: '',
        description: '',
        brand: {
          name: ''
        },
        brands: [{ name: '' }],
        images: [],
        price: 0,
        stocks: [{ _id: '' }]
      }
    };
  },
  created: async function() {
    const productId = this.$route.params['productId'];

    if (productId) {
      const product = await this.$store.dispatch(
        'products/productById',
        productId
      );
      // metric producrt details viewed
      MetricsService.emitEvent(
        { name: EventKey.PRODUCT_DETAILS_VIEWED },
        {
          product_name: product.name
        }
      );

      this.product = product;
      this.isProductLoaded = true;
    }
  },
  watch: {
    stockOpts: function(newVal, old) {
      if (newVal && newVal.length > 0)
        this.current = newVal[0] ? newVal[0].value : '';
    }
  },
  computed: {
    stockOpts: function() {
      const cart = this.$store.getters['cart/getCart'];

      const options = this.product.stocks
        .filter(s => s.stock > 0 && !cart.products.find(p => p._id === s._id))
        .map(s => {
          return { value: s._id, text: s.attribute, stock: s.stock };
        });

      return options;
    },
    currentStock: function() {
      return this.product.stocks.find(s => s._id === this.current);
    },
    wholesalePrice: function() {
      if (!this.isMatrix) {
        return this.price - this.wholesaleDto;
      } else {
        return this.product.wholesalePrice;
      }
    },
    wholesaleDto: function() {
      return this.$store.getters['application/getWholeSaleDto'];
    },
    price: function() {
      if (!this.isMatrix) {
        if (this.product.isLocal) {
          return this.product.shops.find(s => s.shopId === this.shopId).price;
        } else {
          const increment = this.$store.getters['prices/getIncrement'](
            this.product.brands[0]._id
          );

          return this.product.price + increment;
        }
      } else {
        return this.product.price;
      }
    },
    isWholesaleEnabled: function() {
      return this.$store.getters['application/isWholesaleEnabled'];
    },
    showWholesalePrice: function() {
      if (this.isWholesaleEnabled) {
        if (this.$store.getters['application/showWholesalePrice']) {
          if (this.product.price === this.wholesalePrice) {
            return false;
          } else {
            return true;
          }
        }
      }
      return false;
    },
    isMatrix: function() {
      return this.$store.getters['application/isMatrix'];
    },
    shopId: function() {
      return this.$store.getters['application/getId'];
    }
  },
  methods: {
    addTocart: async function() {
      const name =
        this.product.name +
        ' - ' +
        this.product.brands[0].name +
        ' - ' +
        (this.product.color ? this.product.color + ' - T: ' : 'T: ') +
        this.currentStock.attribute;
      const productData = [
        {
          productId: this.product._id,
          attributeId: this.currentStock._id,
          name: name,
          price: this.product.price,
          wholesalePrice: this.product.wholesalePrice,
          shopPrice: this.price,
          shopWholesalePrice: this.wholesalePrice,
          quantity: this.quantity,
          image: this.product.images[0].thumbnailUrl,
          extended1: this.product.extended1,
          extended2: this.product.extended2
        }
      ];

      await this.$store.dispatch('cart/addProduct', { products: productData });
    },
    loadCategoryProds: async function(payload) {
      await this.$store.dispatch(
        'products/loadProductsByCategory',
        payload || {}
      );
      this.$router.push({ name: 'Home', query: { reload: false } });
    }
  },
  components: {
    ProductGallery,
    NumberInput,
    CategorySelector
  }
};
</script>

<style lang="css" scoped></style>
