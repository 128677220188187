<template>
  <div
    class="container mx-auto max-w-lg w-full p-2"
    v-if="isVisilble"
    style="position: relative"
  >
    <div class="ribbon up" v-if="showNewTag">
      <div class="content">
        <div class="flex flex-col justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 justify-self-center"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
            />
          </svg>

          <span class="text-sm">Nuevo</span>
        </div>
      </div>
    </div>
    <div class="ribbon down" v-if="hasDto">
      <div class="content" style="--color: #239547">
        <div class="flex flex-col justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
            />
          </svg>

          <span class="text-sm">Oferta</span>
        </div>
      </div>
    </div>
    <div
      class="card flex flex-col justify-center px-4 pt-4 bg-white rounded-lg shadow-2xl"
    >
      <div class="prod-title">
        <a
          class="text-xl cursor-pointer uppercase text-gray-900 font-bold"
          @click="goToProductDetails"
        >
          {{ product.name }}
        </a>
        <p class="uppercase text-sm text-gray-500">
          {{ localProduct.brands[0].name }}
          {{ localProduct.color ? ` - ${localProduct.color}` : "" }}
        </p>
      </div>
      <div class="prod-img">
        <vue-picture-swipe
          :items="[
            {
              src: imageObj.largeUrl,
              thumbnail: imageObj.url,
              w: 1000,
              h: 1000,
            },
          ]"
          :options="options"
        >
        </vue-picture-swipe>
      </div>
      <div class="mt-1 text-sm text-gray-500 text-justify" v-if="showDescription">
        {{ localProduct.description }}
      </div>
      <div class="prod-info grid gap-3 mt-1">
        <div class="flex justify-center" v-if="showStock">
          <div class="w-full">
            <table class="border-collapse w-full">
              <thead>
                <tr>
                  <th class="w-5 max-w-xs text-gray-800 text-center">Talla</th>
                  <th scope="col" v-for="stock in stocks" :key="stock._id">
                    <span class="tallas">{{ stock.attribute }}</span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="hasStock">
                <tr>
                  <th class="w-5 max-w-sm text-gray-800 text-center">Stock</th>
                  <td
                    class="text-gray-800 text-center"
                    v-for="stock in stocks"
                    :key="stock._id"
                  >
                    <span class="tallas">{{ stock.stock }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="flex items-center justify-center space-x-2">
          <div class="rounded-lg py-1 px-1">
            <span v-if="showWholesalePrice" class="font-bold text-gray-600 text-2xl">{{
              wholesalePrice | numeralFormat("$ 0,0.00")
            }}</span>
            <span v-else class="font-bold text-gray-600 text-2xl">{{
              price | numeralFormat("$ 0,0.00")
            }}</span>
          </div>

          <div v-if="showWholesalePrice">
            <p
              class="flex flex-col gap-0 items-center justify-center text-gray-400 text-md font-semibold"
            >
              <span>{{ price | numeralFormat("$ 0,0.00") }}</span>
              <span class="text-xs">Menudeo</span>
            </p>
          </div>
        </div>
        <div class="flex space-around">
          <button
            v-if="showDetailsButton"
            @click="goToProductDetails"
            type="button"
            class="flex-1 border border-gray-200 bg-gray-200 text-gray-700 rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-gray-300 focus:outline-none focus:shadow-outline"
          >
            <div class="inline-flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
              <span>Detalle</span>
            </div>
          </button>
          <button
            @click="addToCart"
            type="button"
            class="flex-1 border border-gray-600 bg-gray-600 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-gray-500 focus:outline-none focus:shadow-outline"
          >
            <div class="inline-flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              <span>Agregar</span>
            </div>
          </button>
        </div>
      </div>
      <div class="flex justify-center">
        <a class="text-sm text-gray-400" :href="imageObj.largeUrl">Descargar imagen</a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import VuePictureSwipe from "vue-picture-swipe";

const DAYS_AS_NEW = 5;

export default {
  name: "ProductItem",
  props: ["product", "isMatrix", "isPresale"],
  data: function () {
    return {
      options: {
        fullscreenEl: false,
        zoomEl: true,
        arrowEl: false,
        counterEl: false,
        preloaderEl: false,
        tapToClose: true,
        tapToToggleControls: true,
        shareEl: false,
        shareButtons: [
          {
            id: "download",
            label: "Descargar Imagen",
            url: "{{raw_image_url}}",
            download: true,
          },
        ],
      },
    };
  },
  computed: {
    imageObj: function () {
      if (this.localProduct.images.length > 0) {
        return this.localProduct.images[0];
      } else {
        return {
          thumbnailUrl: this.localProduct.image,
          url: this.localProduct.image,
          largeUrl: this.localProduct.image,
        };
      }
    },
    wholesalePrice: function () {
      if (!this.isMatrix) {
        return this.price - this.wholesaleDto;
      } else {
        return this.localProduct.wholesalePrice;
      }
    },
    wholesaleDto: function () {
      return this.$store.getters["application/getWholeSaleDto"];
    },
    price: function () {
      const shopId = this.shopId;

      if (!this.isMatrix) {
        if (this.localProduct.isLocal) {
          return this.localProduct.shops.find((s) => s.shopId === this.shopId).price;
        } else {
          const increment = this.$store.getters["prices/getIncrement"](
            this.localProduct.brands[0]._id
          );

          return this.localProduct.price + increment;
        }
      } else {
        return this.localProduct.price;
      }
    },
    localProduct: function () {
      return this.product;
    },
    stocks: function () {
      return this.product.stocks.filter(
        (s) => (s.stock > 0 && !this.isPresale) || this.isPresale
      );
    },
    isVisilble: function () {
      if (this.hasStock || this.isPresale) {
        return true;
      }
      return false;
    },
    hasStock: function () {
      return !!this.product.stocks.find((s) => s.stock > 0);
    },
    hasDto: function () {
      return this.localProduct.hasDto;
    },
    showNewTag: function () {
      if (this.localProduct.isNewProduct) {
        return true;
      } else {
        const date = moment(this.localProduct.createdOn).startOf("day");

        const current = moment().startOf("day");

        const days = moment.duration(current.diff(date)).asDays();

        return days <= DAYS_AS_NEW && !this.hasDto;
      }
    },
    showDetailsButton: function () {
      return this.$store.getters["application/showDetailsButton"] && !this.isPresale;
    },
    showDescription: function () {
      return this.$store.getters["application/showDescription"];
    },
    showStock: function () {
      return this.$store.getters["application/showStock"];
    },
    showWholesalePrice: function () {
      if (this.isWholesaleEnabled) {
        if (this.$store.getters["application/showWholesalePrice"]) {
          if (this.localProduct.price === this.localProduct.wholesalePrice) {
            return false;
          } else {
            return true;
          }
        }
      }
      return false;
    },
    isWholesaleEnabled: function () {
      return this.$store.getters["application/isWholesaleEnabled"];
    },
    shopId: function () {
      return this.$store.getters["application/getId"];
    },
  },
  methods: {
    addToCart: function () {
      this.$emit(
        "addToCart",
        this.product._id,
        this.price,
        this.wholesalePrice,
        this.hasStock
      );
    },
    goToProductDetails: function () {
      this.$emit("showProductDetails", this.product._id);
    },
  },
  components: {
    VuePictureSwipe,
  },
};
</script>

<style>
.price {
  color: #dc143c;
  float: right;
}
.price-wholesale {
  float: right;
}
.tallas {
  font-size: 14px;
}
.header-link {
  font-size: 16px;
  color: #343a40;
}
.gallery-thumbnail {
  margin: 0 !important;
}
.prod-title {
  margin-bottom: 2px;
}
</style>
<style lang="scss">
.ribbon {
  $default-right: 33px;
  $default-top: 4px;
  $default-color: #2ca7d8;
  $default-width: 43px;
  $default-height: 63px;

  --color: #d90429;

  position: absolute;
  right: var(--right, $default-right);
  top: var(--top, $default-top);

  filter: drop-shadow(2px 3px 2px rgba(black, 0.5));

  > .content {
    color: white;
    font-size: 1.25rem;
    text-align: center;
    font-weight: 400;
    background: var(--color, $default-color)
      linear-gradient(45deg, rgba(black, 0) 0%, rgba(white, 0.25) 100%);
    padding: 8px 2px 4px;

    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);

    width: var(--width, $default-width);
    min-height: var(--height, $default-height);

    transition: clip-path 1s, padding 1s, background 1s;
  }

  &.slant-up > .content {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 12px), 50% calc(100% - 6px), 0 100%);
  }

  &.slant-down > .content {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 6px), 0 calc(100% - 12px));
  }

  &.down > .content {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), 50% 100%, 0 calc(100% - 8px));
  }

  &.up > .content {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 8px), 0 100%);
  }

  &.check > .content {
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 20px),
      40% 100%,
      0 calc(100% - 12px)
    );
  }
}
</style>
