<template>
  <div class="ui segment" :class="{ loading: isLoading }">
    <h2 class="ui dividing header">{{ header }}</h2>

    <sui-segment basic floated="right">
      <sui-dropdown
        placeholder="Elementos por página"
        selection
        collapsing
        :options="pageQuantity"
        v-model="localRecordsPerPage"
      />
    </sui-segment>
    <sui-segment basic floated="left">
      <sui-dropdown
        v-if="enableKindDropdown"
        class="custom-dropdown"
        :placeholder="kindPlaceholder"
        selection
        :options="kindOptions"
        v-model="kindOption"
      />
      <div class="ui action left icon input">
        <i class="search icon"></i>
        <input
          type="text"
          :placeholder="header"
          v-model="searchTerm"
          @keyup.enter="search"
        />
        <div class="ui teal button" @click="search">Buscar</div>
      </div>
      <button
        class="ui primary button"
        type="button"
        style="margin-left: 10px;"
        @click="clear"
      >
        Limpiar
      </button>
    </sui-segment>
    <div class=""></div>
    <table class="border-collapse w-full">
      <thead>
        <tr>
          <th
            class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
            v-for="column in columns"
            :key="column.property"
          >
            {{ column.header }}
          </th>
          <th
            class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
            colspan="4"
          >
            <div class="ui center aligned">
              <button
                type="button"
                class="ui mini  positive button"
                v-if="!hideAdd"
                @click="add"
              >
                <i class="plus icon"></i>
                {{ addButtonLabel }}
              </button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in pageContent"
          :key="item._id"
          class="bg-gray-50 lg:bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
          :class="getContextClass(item)"
        >
          <td
            v-for="column in columns"
            :key="column.property"
            :class="[column.clazz, getCellContextClass(column, item)]"
            class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static"
          >
            <span
              class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase"
              >{{ column.header }}</span
            >
            <div v-if="column.type === 'image'">
              <sui-image :src="cellValue(item, column)" size="tiny" />
            </div>
            <div v-if="column.type === 'date'">
              {{ cellValue(item, column) | moment('DD/MM/YYYY hh:mm a') }}
            </div>
            <div v-if="column.type === 'money'">
              {{ cellValue(item, column) | numeralFormat('$0,0.00') }}
            </div>
            <div v-if="column.type === 'status'">
              <div
                class="ui horizontal label"
                :class="getStatusClass(item, column)"
              >
                {{ getStatusString(item, column) }}
              </div>
            </div>
            <div v-if="!column.type">
              {{ cellValue(item, column) }}
            </div>
          </td>
          <td
            class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static"
          >
            <button
              v-if="!hideEdit"
              @click="openEdit(item._id)"
              class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <span>Ver</span>
            </button>
            <button
              v-if="!hideDelete"
              @click="
                deleteItem({ id: item._id, desc: getPropVal(item, deleteDesc) })
              "
              class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 ml-2 rounded inline-flex items-center"
            >
              <span>Eliminar</span>
            </button>

            <a
              :class="extra.cssClass"
              v-for="(extra, index) in extraButtons"
              :key="'extra-button-' + index"
              @click="extra.callback(item._id)"
              ><i :class="extra.icon"></i
            ></a>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th :colspan="columns.length + 1">
            <div class="flex justify-between items-center pt-4">
              <div>
                <p class="text-lg text-gray-700">
                  Total

                  <span class="font-medium">97</span>
                  elementos
                </p>
              </div>
              <div class="">
                <paginate
                  v-model="currentPage"
                  :page-count="totalPages"
                  :click-handler="onPageChange"
                  :prev-text="'<'"
                  :prev-link-class="'item'"
                  :next-text="'>'"
                  :next-link-class="'item'"
                  :container-class="'ui right floated pagination menu'"
                  :page-link-class="'item'"
                  :no-li-surround="true"
                >
                </paginate>
              </div>
            </div>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'Catalog',
  props: [
    'store',
    'header',
    'addButtonLabel',
    'columns',
    'editOn',
    'hideEdit',
    'addOn',
    'hideAdd',
    'deleteOn',
    'deleteDesc',
    'defaultRecordsPerPage',
    'enableKindDropdown',
    'kindOptions',
    'kindPlaceholder',
    'hideDelete',
    'showExtraButton',
    'extraButtonClass',
    'extraButtonIcon',
    'extraButtons',
    'extraButtonCallback',
    'rowContextFunction',
    'cellContextFunction'
  ],
  data: function() {
    return {
      localRecordsPerPage: this.defaultRecordsPerPage
        ? this.defaultRecordsPerPage
        : 10,
      isLoading: false,
      searchTerm: '',
      currentPage: 1,
      pageQuantity: [
        {
          text: '5 por página',
          value: 5
        },
        {
          text: '10 por página',
          value: 10
        },
        {
          text: '30 por página',
          value: 30
        },
        {
          text: '50 por página',
          value: 50
        }
      ],
      kindOption: 'ALL'
    };
  },
  mounted: function() {
    this.localRecordsPerPage = this.recordsPerPage;
  },
  created: function() {
    if (this.pageContent.length === 0) {
      this.$store.dispatch(
        this.store + '/updateRecordsPerPage',
        this.defaultRecordsPerPage
      );
      this.loadPage();
    } else {
      this.currentPage = this.statetPage;
    }
  },
  watch: {
    localRecordsPerPage: function(val) {
      if (this.recordsPerPage !== val) {
        this.$store.dispatch(this.store + '/updateRecordsPerPage', val);
        this.loadPage(1);
      }
    },
    kindOption: function() {
      this.loadPage(1);
    }
  },
  computed: {
    recordsPerPage: function() {
      return this.$store.getters[this.store + '/getRecordsPerPage'];
    },
    pageContent: function() {
      return this.$store.getters[this.store + '/getPageContent'];
    },
    totalPages: function() {
      return this.$store.getters[this.store + '/getTotalPages'] || 0;
    },
    totalElemnts: function() {
      return this.$store.getters[this.store + '/getTotalElemnts'];
    },
    statetPage: function() {
      return this.$store.getters[this.store + '/getCurrentPage'];
    }
  },
  methods: {
    getStatusClass: function(item, column) {
      if (column.labels) {
        const status = this.cellValue(item, column);
        const label = column.labels.find(l => l.value === status);
        return label.class;
      } else {
        return '';
      }
    },
    getStatusString: function(item, column) {
      if (column.labels) {
        const status = this.cellValue(item, column);
        const label = column.labels.find(l => l.value === status);
        return label.display;
      } else {
        return this.cellValue(item, column);
      }
    },
    cellValue: function(item, column) {
      if (column.concat) {
        const p1 = _.get(item, column.property);
        const p2 = _.get(item, column.concat);
        if (column.type === 'money' || column.type === 'number') {
          return p1 + p2;
        } else {
          return p1 + ' ' + p2;
        }
      } else if (column.array) {
        const field = _.get(item, column.property);
        return _.get(field[0], column.elementProperty);
      }
      return _.get(item, column.property);
    },

    getPropVal: function(item, prop) {
      return _.get(item, prop);
    },
    onPageChange: function(page) {
      this.loadPage(page);
    },
    search: function() {
      if (this.searchTerm) {
        this.loadPage(1);
      }
    },
    clear: function() {
      if (this.searchTerm) {
        this.searchTerm = '';
        this.loadPage(1);
      }
    },
    loadPage: async function(page) {
      this.isLoading = true;
      const pageNumber = page ? page : this.statetPage;
      await this.$store.dispatch(this.store + '/loadPage', {
        size: this.recordsPerPage,
        page: pageNumber,
        query: this.searchTerm,
        kind: this.kindOption
      });
      this.isLoading = false;

      this.currentPage = this.statetPage;
    },
    openEdit: function(id) {
      if (this.isFunction(this.editOn)) {
        this.editOn(id);
      } else {
        this.$router.push(this.editOn + '/' + id);
      }
    },
    add: function() {
      if (this.isFunction(this.addOn)) {
        this.addOn();
      } else {
        this.$router.push(this.addOn);
      }
    },
    deleteItem: function(id) {
      this.deleteOn(id);
    },
    isFunction: function(functionToCheck) {
      return (
        functionToCheck &&
        {}.toString.call(functionToCheck) === '[object Function]'
      );
    },
    getContextClass: function(item) {
      if (this.rowContextFunction) {
        return this.rowContextFunction(item);
      } else {
        return '';
      }
    },
    getCellContextClass: function(column, item) {
      if (column.setContextClass && this.cellContextFunction) {
        return this.cellContextFunction(item);
      } else {
        return '';
      }
    }
  }
};
</script>

<style lang="css" scoped>
.custom-dropdown {
  margin-right: 10px;
}
</style>
