<template>
  <div>
    <LoginForm :message="message" @onLogin="login" />
  </div>
</template>

<script>
import LoginForm from '../app/LoginForm';

export default {
  name: 'LoginHolder',
  data: function() {
    return {
      message: ''
    };
  },
  methods: {
    login: async function(payload) {
      this.$emit('loading', true);
      try {
        const response = await this.$store.dispatch(
          'auth/loginCustomer',
          payload
        );

        this.message = '';
        this.$emit('next', true);
      } catch (e) {
        this.message = e.message;
      } finally {
        this.$emit('loading', false);
      }
    }
  },
  components: {
    LoginForm
  }
};
</script>

<style lang="css" scoped></style>
