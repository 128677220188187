<template>
  <div class="ui left aligned segment">
    <div v-if="component === null" class="">
      <div class="custom">
        <div class="custom-buttons">
          <h3>Si ya tienes una cuenta con nosotros puedes</h3>
        </div>
        <div
          class="ui primary labeled icon button "
          @click="buyerMethod('login')"
        >
          Ingresar
          <i class="key icon"></i>
        </div>
      </div>

      <div class="ui horizontal divider">
        o si prefieres
      </div>
      <div class="custom">
        <div class="custom-buttons">
          <h3>Puedes continuar tu compra como invitado</h3>
        </div>
        <div class="ui teal labeled icon button" @click="buyerMethod">
          Continuar como invitado
          <i class="user icon"></i>
        </div>
      </div>
    </div>
    <div v-if="component != null">
      <keep-alive>
        <component
          :is="currentComponent"
          @next="next"
          @loading="loading"
        ></component>
      </keep-alive>
      <br />
      <button
        class="ui basic tiny button"
        type="button"
        name="button"
        @click="reset"
      >
        Atrás
      </button>
    </div>
  </div>
</template>

<script>
import LoginHolder from './LoginHolder';
import CustomerForm from '../customer/CustomerForm';

export default {
  name: 'Divider',
  data: function() {
    return { component: null };
  },
  computed: {
    currentComponent: function() {
      return this.component;
    }
  },
  created: function() {
    const isAuthenticated = this.$store.getters['auth/isAuthenticated'];

    if (isAuthenticated) this.next();
  },
  methods: {
    buyerMethod: function(type) {
      if (type === 'login') {
        this.component = LoginHolder;
      } else {
        this.component = CustomerForm;
      }
    },
    reset: function() {
      this.component = null;
    },
    loading: function(value) {
      this.$emit('loading', value);
    },
    next: function(value) {
      this.$emit('next');
    }
  }
};
</script>

<style lang="css">
.custom {
  min-height: 20%;
  margin-top: 5%;
  text-align: center;
}
.custom-buttons {
  margin-bottom: 20px;
}
</style>
