<template lang="html">
  <div class="min-h-screen p-6 flex flex-col justify-center sm:py-12">
    <div class="py-3 sm:max-w-xl sm:mx-auto">
      <div class="bg-white min-w-1xl flex flex-col rounded-xl shadow-xl">
        <div class="px-12 py-5">
          <h2 class="text-gray-800 text-3xl text-center font-semibold">
            Acceso a la preventa
          </h2>
        </div>
        <div class="bg-white w-full flex flex-col items-center">
          <div class="flex flex-col items-center py-6 space-y-3">
            <span class="text-lg text-gray-800"
              >Introduce el código de acceso</span
            >
          </div>
          <div class="w-3/4 flex flex-col">
            <input
              v-model.trim="code"
              @keyup.enter="validate"
              type="text"
              placeholder="ABCD1"
              class="p-4 text-gray-500 border-2 text-center rounded-lg resize-none text-xl font-bold uppercase"
            />
            <button
              @click="validate"
              class="py-3 my-8 text-lg bg-gradient-to-r from-purple-500 to-indigo-600 rounded-xl text-white"
            >
              Ingresar
            </button>
          </div>
        </div>
        <div class="h-20 flex items-center justify-center">
          <a href="#" class="text-gray-600">Solicita tu código a tu asesor</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PresaleLogin',
  data: function() {
    return { code: '' };
  },
  methods: {
    validate: async function() {
      if (this.code.trim()) {
        try {
          const resp = await this.$store.dispatch('auth/validateAccessCode', {
            code: this.code
          });
          this.$router.push({ name: 'Presale' }).catch(() => {});
        } catch (e) {
          console.log('Error');
        }
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
