import axios from 'axios';

const state = {
  brands: []
};

const getters = {
  //getBrands: state => state.brands,
  getBrands: state => {
    return state.brands.map(b => {
      return { text: b.name, value: b._id };
    });
  }
};

const actions = {
  loadBrands: ({ commit, getters }) => {
    axios.get(`/api/v1/brands`).then(response => {
      commit('LOAD_BRANDS_SUCCESS', response.data);
    });
  }
};

const mutations = {
  ['LOAD_BRANDS_SUCCESS']: (state, payload) => {
    state.brands = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
