import axios from 'axios';
import moment from 'moment';

import { UPDATE_CART, REMOVE_FROM_CART } from '../mutation-types';

const state = {
  status: '',
  cart: {
    _id: '',
    state: '',
    modifiedOn: '',
    isPresale: false,
    presale: '',
    products: []
  },
  lastUpdate: ''
};

const getters = {
  getStatus: state => state.status,
  getCart: state => state.cart,
  getTimeRemaining: (state, getters, rootState, rootGetters) => {
    const lastUpdate = state.lastUpdate;

    const cartTimeout = rootGetters['application/getCartTimeout'];

    if (lastUpdate) {
      const end = moment(lastUpdate).add(cartTimeout, 'minutes');

      const duration = moment.duration(end.diff(new Date()));
      const seconds = duration.asSeconds();

      return seconds;
    } else {
      return 0;
    }
  },
  total: (state, getters, rootState, rootGetters) => {
    const isWholesaleEnabled = rootGetters['application/isWholesaleEnabled'];
    const itemsForWholesale = rootGetters['application/getWholeSaleQty'];

    const isMatrix = rootGetters['application/isMatrix'];

    if (isWholesaleEnabled && getters['totalItems'] >= itemsForWholesale) {
      return state.cart.products.reduce(
        (total, product) =>
          total +
          product.quantity *
          (isMatrix ? product.wholesalePrice : product.shopWholesalePrice),
        0
      );
    } else {
      return state.cart.products.reduce(
        (total, product) =>
          total +
          product.quantity * (isMatrix ? product.price : product.shopPrice),
        0
      );
    }
  },
  totalItems: state => {
    return state.cart.products.reduce(
      (total, product) => total + product.quantity,
      0
    );
  },
  totalRealPieces: state => {
    return state.cart.products.reduce(
      (total, product) => total + product.realQty,
      0
    );
  },
  getId: state => {
    if (state.cart._id) return state.cart._id;
    else if (localStorage.getItem('cartId')) {
      return localStorage.getItem('cartId');
    } else {
      return '';
    }
  }
};

const actions = {
  load: ({ commit, getters }) => {
    if (getters.getId && getters.getStatus === '') {
      console.log('Loading cart: ' + getters.getId);
      axios.get(`/api/v1/carts/${getters.getId}`).then(response => {
        const cart = response.data;

        commit(UPDATE_CART, response.data);
      });
    }
  },
  addProduct({ commit, getters, dispatch }, payload) {
    return new Promise(
      (resolve, reject) => {
        if (getters.getCart._id) {
          axios
            .put(`/api/v1/carts/${getters.getCart._id}`, payload)
            .then(response => {
              commit(UPDATE_CART, response.data.cart);

              const data = response.data;

              if (data.failed && data.failed.length > 0) {
                for (let i = 0; i < data.failed.length; i++) {
                  commit(
                    'WAR_MSG',
                    {
                      title: 'Agotado!',
                      content: `Se agotó ${data.failed[i].name}`
                    },
                    { root: true }
                  );
                }
              }

              for (let i = 0; i < payload.products.length; i++) {
                const added = data.cart.products.find(
                  p => p._id === payload.products[i].attributeId
                );

                if (added) {
                  commit(
                    'OK_MSG',
                    { title: 'Correcto!', content: `Se agregó ${added.name}` },
                    { root: true }
                  );
                }
              }

              resolve(response.data);
            });
        } else {
          axios.post(`/api/v1/carts`, payload).then(response => {
            commit(UPDATE_CART, response.data.cart);

            const data = response.data;

            if (data.failed && data.failed.length > 0) {
              for (let i = 0; i < data.failed.length; i++) {
                commit(
                  'WAR_MSG',
                  {
                    title: 'Agotado!',
                    content: `Se agotó ${data.failed[i].name}`
                  },
                  { root: true }
                );
              }
            }

            for (let i = 0; i < payload.products.length; i++) {
              const added = data.cart.products.find(
                p => p._id === payload.products[i].attributeId
              );

              if (added) {
                commit(
                  'OK_MSG',
                  { title: 'Correcto!', content: `Se agregó ${added.name}` },
                  { root: true }
                );
              }
            }

            resolve(response.data);
          });
        }

        dispatch('products/updateStocks', payload, { root: true });

        // Let the calling function know that http is done. You may send some data back
      },
      error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      }
    );
  },
  updateQuantity: ({ commit, getters, dispatch }, payload) => {
    return new Promise(
      (resolve, reject) => {
        axios
          .put(`/api/v1/carts/${getters.getCart._id}/update`, payload)
          .then(response => {
            if (response.data.status === 'OK') {
              commit(UPDATE_CART, response.data.cart);
              resolve(response.data.cart);
              commit(
                'OK_MSG',
                { title: 'Correcto!', content: 'Actualización correcta' },
                { root: true }
              );
              dispatch('products/productById', payload.productId, {
                root: true
              });
            } else {
              commit(UPDATE_CART, response.data.cart);
              commit(
                'WAR_MSG',
                {
                  title: 'Error!',
                  content: 'No se pudo completar la operación'
                },
                { root: true }
              );
              reject('Error updating quantity');
            }
          });

        // Let the calling function know that http is done. You may send some data back
      },
      error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      }
    );
  },
  checkout: ({ commit, getters, dispatch, rootGetters }, params) => {
    const customer = rootGetters['auth/getCustomer'];
    const targetAddress = rootGetters['auth/getTargetAddress'];

    const addressId =
      customer.type === 'guest' ? customer.addresses[0]._id : targetAddress;

    return new Promise(
      (resolve, reject) => {
        axios
          .post(`/api/v1/carts/${getters.getCart._id}/checkout`, {
            customerId: customer._id,
            addressId: addressId,
            shippingId: params.shippingId,
            secured: params.secured,
            payment_method: params.payment_method
          })
          .then(response => {
            console.log(response.data);
            commit('CHECKOUT_SUCCESS', response.data);
            resolve(response.data);
            commit(
              'OK_MSG',
              { title: 'Correcto!', content: 'Pedido ingresado correctamente' },
              { root: true }
            );
          });

        // Let the calling function know that http is done. You may send some data back
      },
      error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      }
    );
  }
};

const mutations = {
  [UPDATE_CART]: (state, payload) => {
    if (payload.state === 'active') {
      state.cart = payload;
      state.lastUpdate = payload.modifiedOn;
      state.status = 'updated';
      localStorage.setItem('cartId', payload._id);
    } else {
      localStorage.removeItem('cartId');
    }
  },
  ['CHECKOUT_SUCCESS']: (state, payload) => {
    state.status = '';
    state.lastUpdate = '';
    state.cart = {
      _id: '',
      state: '',
      modifiedon: '',
      products: []
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
