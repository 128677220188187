import OrderList from '@/pages/orders/OrderList';
import Order from '@/pages/orders/Order';

export default [
  {
    path: '/orders',
    name: 'OrderList',
    meta: { title: 'Lista Pedidos' },
    component: OrderList
  },
  {
    path: '/orders/:id',
    name: 'Order',
    meta: { title: 'Pedido' },
    component: Order
  }
];
