<template>
  <div class="ui main container">
    <LoginForm
      :message="message"
      :isLoading="loading"
      :showRegisterButton="true"
      @onLogin="login"
      @onRegister="signup"
    />
    <div class="ui"></div>
  </div>
</template>

<script>
import LoginForm from '../components/app/LoginForm';

export default {
  name: 'Login',
  data: function() {
    return { message: '', loading: false };
  },
  created: function() {
    const token = this.$store.getters['auth/getAccessToken'];

    if (token) {
      this.$router.push({ name: 'Home' });
    }
  },
  methods: {
    login: async function(payload) {
      this.loading = true;
      try {
        const response = await this.$store.dispatch(
          'auth/loginCustomer',
          payload
        );

        this.$router.push({ name: 'Home' });
      } catch (e) {
        this.message = e.message;
      } finally {
        this.loading = false;
      }
    },
    signup: function() {
      this.$router.push({ name: 'Signup' });
    }
  },
  components: { LoginForm }
};
</script>

<style lang="css" scoped></style>
