<template lang="html">
  <div class="px-2 pt-1 pb-6">
    <div class=" flex items-center px-6 rounded-lg transition">
      <img class="w-9" :src="category.image" alt="" />
      <div>
        <span class="ml-3 text-lg uppercase font-bold">{{
          category.label
        }}</span>
        <span class="ml-3 text-sm text-gray-600 block">{{
          category.description
        }}</span>
      </div>
      <div class="ml-3">
        <button
          v-if="!category.main"
          type="button"
          @click="loadCategory(category.parentId)"
          class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-gray-300 focus:outline-none focus:shadow-outline"
        >
          Volver
        </button>
      </div>
    </div>
    <div
      class="grid grid-cols-2 mx-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-1 gap-y-1 text-sm uppercase"
    >
      <template v-for="(row, index) in rows">
        <template v-for="(item, idx) in row">
          <div
            class="group cursor-pointer flex items-center gap-5 px-6 border rounded-md px-4 py-2 m-2 transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
            :class="getClasses(item.color)"
            :key="'row-' + index + '-col-' + idx"
            @click="loadCategory(item.id, item.brandId)"
          >
            <img class="w-9" :src="item.image" alt="" />
            <div>
              <span class="text-xs font-bold">{{ item.label }}</span>
            </div>
            <div>
              <i
                class="fa fa-chevron-right opacity-0 group-hover:opacity-100 transform -translate-x-1 group-hover:translate-x-0 block transition"
              ></i>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
const ITEMS_PER_ROW = 4;
const COLORS = [
  'green',
  'red',
  'yellow',
  'gray',
  'pink',
  'purple',
  'blue',
  'indigo'
];

export default {
  name: 'CategoryDetails',
  props: ['category'],
  computed: {
    rows: function() {
      const rows = [];
      let row = [];

      if (this.category && this.category.children) {
        this.category.children.forEach((item, i) => {
          if (row.length < ITEMS_PER_ROW) {
            row.push(item);
          } else {
            rows.push(row);
            row = [];
            row.push(item);
          }
        });

        if (row.length > 0) rows.push(row);

        return rows;
      } else if (this.category.isFinal) {
        this.category.brands.forEach((item, i) => {
          if (row.length < ITEMS_PER_ROW) {
            row.push({
              id: this.category.id,
              label: item.name,
              brandId: item._id
            });
          } else {
            rows.push(row);
            row = [];
            row.push({
              id: this.category.id,
              label: item.name,
              brandId: item._id
            });
          }
        });

        if (row.length > 0) rows.push(row);

        return rows;
      } else {
        return rows;
      }
    }
  },
  methods: {
    loadCategory: function(categoryId, brandId) {
      const payload = {
        category: categoryId | null,
        brand: brandId
      };
      this.$emit('onLoadCategory', payload);
    },
    getClasses: function(baseColor) {
      const color = COLORS.includes(baseColor) ? baseColor : 'indigo';

      if (color) {
        return [
          'bg-' + color + '-100',
          'border-' + color + '-500',
          'text-' + color + '-500',
          'hover:bg-' + color + '-600',
          'hover:text-white'
        ];
      } else {
        return [
          'bg-gray-100',
          'border-gray-500',
          'text-gray-500',
          'hover:bg-gray-600',
          'hover:text-white'
        ];
      }
    }
  }
};
</script>

<style lang="css" scoped>
.flex {
  display: flex;
  align-items: center;
  padding: 10px !important;
}
.flex-img {
  margin-right: 10px !important;
}
.flex-item {
  font-size: 13px !important;
}
.justify-items {
  justify-content: center;
}
</style>
