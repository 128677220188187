<template>
  <div class="ui main container">
    <div class="ui segment" :class="{ loading: isLoading }">
      <h2 class="ui big header">Regístrate con nosotros!</h2>
      <div class="ui grid">
        <div
          class="six wide computer column seven wide tablet column sixteen wide mobile column"
        >
          <SignupForm :showUsernameError="showError" @onSave="save" />
        </div>
        <div class="ten wide computer only nine wide tablet column">
          <div class="ui placeholder segment">
            <div class="ui blue icon large header">
              <i class="tags icon"></i>
              Encuentra los mejores precios y la mejor selección de ropa.
            </div>
            <br />
            <div class="inline">
              <div class="ui teal massive  label">
                Registrate ahora!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignupForm from '../components/customer/SignupForm';
export default {
  name: 'Registration',
  data: function() {
    return { isLoading: false, showError: false };
  },
  methods: {
    save: async function(payload) {
      this.isLoading = true;
      try {
        const customer = await this.$store.dispatch(
          'auth/createCustomer',
          payload
        );

        await this.$store.dispatch('auth/loginCustomer', {
          username: customer.username,
          password: payload.password
        });

        this.$router.push({ name: 'Home' });
      } catch (e) {
        console.log('Error creating and login user');
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    }
  },
  components: {
    SignupForm
  }
};
</script>

<style lang="css" scoped></style>
