<template>
  <div class="ui segment">
    <h3 class="text-xl font-bold">Dirección de entrega</h3>
    <AddressList
      v-if="
        !isRegistering &&
          customer.type !== 'guest' &&
          customer.addresses.length > 0
      "
      :addresses="addresses"
      @onSelected="addressSelected"
    />
    <div v-else class="">
      <h3 class="text-medium" v-if="!isRegistering">
        No tienes direcciones guardadas. Registra una aquí
      </h3>
    </div>
    <div v-if="isRegistering" class="ui basic segment">
      <div class="ui grid">
        <div class="five wide computer sixteen wide mobile column">
          <AddressForm
            @onSave="saveAddress"
            @onCancel="hideForm"
            :address="tmpAddress"
          />
        </div>
      </div>
    </div>

    <div v-if="!isRegistering" class="">
      <br />
      <button class="ui basic tiny button" type="button" name="button">
        Atrás
      </button>
      <button
        class="ui basic tiny button"
        type="button"
        name="button"
        @click="showForm"
      >
        Registrar Dirección
      </button>
      <button
        class="ui primary tiny button"
        type="button"
        name="button"
        @click="next"
        :class="{ disabled: !selected }"
      >
        Continuar
      </button>
    </div>
  </div>
</template>

<script>
import AddressList from './AddressList';
import AddressForm from './AddressForm';

export default {
  name: 'ShippingAddress',
  computed: {
    customer: function() {
      return this.$store.getters['auth/getCustomer'];
    },
    addresses: function() {
      return this.customer.addresses.filter(a => !a.deleted);
    }
  },
  data: function() {
    return {
      isRegistering: false,
      selected: false,
      tmpAddress: {
        street: '',
        neigh: '',
        postalCode: '',
        city: '',
        state: '',
        country: 'México'
      }
    };
  },
  created: function() {
    if (this.customer.type === 'guest') {
      this.isRegistering = true;
    }
  },
  methods: {
    saveAddress: async function(address) {
      this.$emit('loading', true);
      const result = await this.$store.dispatch('auth/addAddress', address);
      this.$emit('loading', false);
      if (this.customer.type === 'guest') {
        this.next();
      } else {
        this.isRegistering = false;
      }
    },
    showForm: function() {
      this.isRegistering = true;
    },
    hideForm: function() {
      this.isRegistering = false;
    },
    addressSelected: function(addressId) {
      console.log('Set target address, ' + addressId);
      this.selected = true;
      this.$store.dispatch('auth/setTargetAddress', addressId);
    },
    next: function() {
      this.$emit('next', true);
    }
  },
  components: {
    AddressList,
    AddressForm
  }
};
</script>

<style></style>
