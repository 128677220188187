import Vue from 'vue';
import Vuex from 'vuex';

import products from './modules/products';
import cart from './modules/cart';
import auth from './modules/auth';
import shipping from './modules/shipping';
import brands from './modules/brands';
import categories from './modules/categories';
import orders from './modules/orders';
import application from './modules/application';
import prices from './modules/prices';
import reviews from './modules/reviews';
import paymentMethod from './modules/payment_methods';
import invoice from './modules/invoice';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showLoader: false
  },
  mutations: {
    ['SET_LOADER']: (state, loading) => {
      state.showLoader = loading;
    },
    ['OK_MSG']: (state, payload) => { },
    ['INFO_MSG']: (state, payload) => { },
    ['WAR_MSG']: (state, payload) => { },
    ['ERROR_MSG']: (state, payload) => { }
  },
  modules: {
    products,
    cart,
    auth,
    shipping,
    orders,
    application,
    categories,
    brands,
    prices,
    reviews,
    paymentMethod,
    invoice
  },
  strict: true
});
