<template>
  <div class="">
    <MaintenanceSplash />
  </div>
</template>

<script>
import MaintenanceSplash from '../components/app/MaintenanceSplash';

export default {
  name: 'Maintencance',
  created: function() {
    const isInMaintenance = this.$store.getters['application/isInMaintenance'];
    if (!isInMaintenance) this.$router.push({ name: 'Home' });
  },
  components: {
    MaintenanceSplash
  }
};
</script>

<style lang="css" scoped></style>
