import Profile from '../pages/customer/Profile';

export default [
  {
    path: '/customer/profile',
    name: 'Profile',
    meta: { title: 'Perfil' },
    component: Profile
  }
];
