<template>
  <div class="flex flex-col justify-center">
    <div class="px-5 xs:p-0 mx-auto md:w-full md:max-w-md">
      <div class="flex justify-center">
        <img class="ui small image" :src="logo" />
      </div>
      <div v-if="message" class="ui warning message">
        <div class="header">
          Error iniciando sesión
        </div>
        <p>{{ message }}</p>
      </div>
      <div class="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
        <div class="px-5 py-7">
          <label class="font-semibold text-sm text-gray-600 pb-1 block"
            >Teléfono o Correo electrónico</label
          >
          <input
            type="text"
            name="email"
            placeholder="Teléfono o correo"
            autocomplete="nope"
            v-model.trim="$v.email.$model"
            class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
          />
          <label class="font-semibold text-sm text-gray-600 pb-1 block"
            >Contraseña</label
          >
          <input
            type="password"
            name="password"
            placeholder="Contraseña"
            autocomplete="new-password"
            v-model.trim="$v.password.$model"
            @keyup.enter="login()"
            class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
          />
          <button
            type="button"
            @click="login"
            class="transition duration-200 bg-gray-700 hover:bg-gray-900 focus:bg-gray-900 focus:shadow-sm focus:ring-4 focus:ring-gray-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
          >
            <span class="inline-block mr-2">Login</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-4 h-4 inline-block"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>
        </div>

        <div class="py-5" v-if="showRegisterButton">
          <div class="grid grid-cols-2 gap-1">
            <div class="text-center sm:text-left whitespace-nowrap">
              <button
                class="transition duration-200 mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-gray-500 hover:bg-gray-300 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ring-inset"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="w-4 h-4 inline-block align-text-top"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                  />
                </svg>
                <span class="inline-block ml-1">Olvidé mi contraseña</span>
              </button>
            </div>
            <div class="text-center sm:text-right whitespace-nowrap">
              <button
                @click="signup"
                class="transition duration-200 mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-gray-500 hover:bg-gray-300 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ring-inset"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="w-4 h-4 inline-block align-text-bottom	"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
                <span class="inline-block ml-1">Regístrate</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="py-5" v-if="showRegisterButton">
        <div class="grid grid-cols-2 gap-1">
          <div class="text-center sm:text-left whitespace-nowrap">
            <button
              @click="goToHome"
              class="transition duration-200 mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ring-inset"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-4 h-4 inline-block align-text-top"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
              <span class="inline-block ml-1">Volver a la tienda</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'LoginForm',
  props: ['message', 'isLoading', 'showRegisterButton'],
  data: function() {
    return {
      email: '',
      password: ''
    };
  },
  computed: {
    logo: function() {
      return this.$store.getters['application/getLogo'];
    }
  },
  validations: {
    email: {
      required
    },
    password: {
      required
    }
  },
  methods: {
    login: function() {
      if (!this.$v.$invalid) {
        if (this.email.trim() === '' || this.password.trim() === '') {
          console.log('Feel empty fields');
        } else {
          this.$emit('onLogin', {
            username: this.email.toLowerCase(),
            password: this.password
          });
        }
      }
    },
    signup: function() {
      this.$emit('onRegister');
    },
    goToHome: function() {
      this.$router.push({ name: 'Home' });
    }
  }
};
</script>

<style scoped="true" lang="css">
.column {
  max-width: 450px;
}
.header {
  display: flex;
  justify-content: center;
}
</style>
