<template>
  <div class="">
    <div class="ui segment">
      <form class="ui form">
        <h4 class="ui dividing header">Información Personal</h4>
        <div class="field required" :class="{ error: $v.customer.name.$error }">
          <label>Nombre(s)</label>
          <div class="three fields">
            <div class="field">
              <input
                type="text"
                name="shipping[first-name]"
                placeholder="Nombre(s)"
                v-model.trim="$v.customer.name.$model"
              />
            </div>
          </div>
        </div>
        <div
          class="field required"
          :class="{ error: $v.customer.lastName.$error }"
        >
          <label>Apellidos</label>
          <div class="three fields">
            <div class="field">
              <input
                type="text"
                name="shipping[first-name]"
                placeholder="Apellidos"
                v-model.trim="$v.customer.lastName.$model"
              />
            </div>
          </div>
        </div>
        <div
          class="field required"
          :class="{ error: $v.customer.phone.$error }"
        >
          <label>Teléfono celular</label>
          <div class="three fields">
            <div class="field">
              <input
                type="text"
                name="shipping[first-name]"
                placeholder="10 digitos"
                v-model.trim="$v.customer.phone.$model"
              />
            </div>
          </div>
        </div>
        <div
          class="field required"
          :class="{ error: $v.customer.rfc.rfc.$error }"
        >
          <label>RFC</label>
          <div class="three fields">
            <div class="field">
              <input
                type="text"
                name="shipping[first-name]"
                placeholder="RFC"
                v-model.trim="$v.customer.rfc.rfc.$model"
              />
            </div>
          </div>
        </div>
        <h4 class="ui dividing header">
          Si desea crear una cuenta llene lo siguiente
        </h4>
        <div class="field" :class="{ error: showError }">
          <label>Correo electrónico o celular (Opcional)</label>
          <div class="three fields">
            <div class="field">
              <input
                type="text"
                placeholder="correo o celular"
                v-model="customer.username"
              />
            </div>
          </div>
        </div>
        <div
          v-if="customer.username"
          class=" required field"
          :class="{ error: $v.customer.password.$error }"
        >
          <label>Contraseña</label>
          <div class="three fields">
            <div class="field">
              <input
                type="password"
                placeholder="Contraseña"
                v-model="customer.password"
                v-model.trim="$v.customer.password.$model"
              />
            </div>
          </div>
        </div>
        <button
          class="ui primary tiny button"
          type="button"
          name="button"
          @click="next"
          :class="{ disabled: $v.$invalid }"
        >
          Continuar
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'CustomerForm',
  data: function() {
    return {
      showError: false,
      customer: {
        name: '',
        lastName: '',
        phone: '',
        username: '',
        password: ''
      }
    };
  },
  validations: function() {
    if (this.customer.username) {
      return {
        customer: {
          name: { required },
          lastName: { required },
          phone: { required, minLength: minLength(10) },
          password: { required, minLength: minLength(8) },
          rfc: { rfc: {} }
        }
      };
    } else {
      return {
        customer: {
          name: { required },
          lastName: { required },
          phone: { required, minLength: minLength(10) },
          rfc: { rfc: {} }
        }
      };
    }
  },
  methods: {
    next: async function() {
      //save customer
      try {
        this.$emit('loading', true);
        this.customer.username = this.customer.username.toLowerCase();
        const customer = await this.$store.dispatch(
          'auth/createCustomer',
          this.customer
        );
        this.$emit('loading', false);
        this.$emit('next', true);
      } catch (e) {
        console.log('Error creating user');
        this.$emit('loading', false);
        this.showError = true;
      }
    }
  }
};
</script>

<style></style>
