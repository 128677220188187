<template>
  <div id="app" class="body-color">
    <Header2 :menu="menu" @onLoadProducts="loadProductsInCat" v-if="!isInMaintenance" />

    <router-view></router-view>
    <FloatingButton v-if="isMatrix" :phone="'523481518284'"></FloatingButton>
    <div class="empty"></div>
  </div>
</template>

<script>
import toastr from "toastr";

import {
  ERROR_MSG,
  ADD_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  REMOVE_PRODUCT_SUCCESS,
} from "./store/mutation-types";

import Header2 from "./components/app/Header2";
import FloatingButton from "./components/app/FloatingButton";
import CategoryDropdown from "./components/app/CategoryDropdown";
import MetricsService from "@/services/metrics";

export default {
  name: "app",
  data() {
    return {
      cartItems: this.$store.state.cart,
    };
  },
  created: async function () {
    // When created we need to initialize the Metrics service
    MetricsService.init(this.$gtag);

    this.$store.subscribe((mutation) => {
      if (mutation.payload) {
        switch (mutation.type) {
          case "OK_MSG":
            toastr.success(mutation.payload.content, mutation.payload.title);
            break;
          case "INFO_MSG":
            toastr.info(mutation.payload.content, mutation.payload.title);
            break;
          case "WAR_MSG":
            toastr.warning(mutation.payload.content, mutation.payload.title);
            break;
          case ERROR_MSG:
            toastr.error(mutation.payload.content, mutation.payload.title, {
              timeOut: 10000,
            });
            break;
          case ADD_PRODUCT_SUCCESS:
            toastr.success("Producto guardado.", "Correcto!");
            break;
          case UPDATE_PRODUCT_SUCCESS:
            toastr.success("Actualización completa.", "Correcto!");
            break;
          case REMOVE_PRODUCT_SUCCESS:
            toastr.warning("Producto elminado.", "Eliminado!");
            break;
        }
      }
    });

    await this.$store.dispatch("categories/loadCategories");
    await this.$store.dispatch("brands/loadBrands");
    await this.$store.dispatch("application/loadAppSettings");
    await this.$store.dispatch("prices/loadPrices");
    await this.$store.dispatch("products/loadSizes");

    const maintenance = this.$store.getters["application/isInMaintenance"];

    if (maintenance) {
      this.$router.push({ name: "Maintenance" });
    } else {
      const { category, brand } = this.$route.query;

      if (category || brand) {
        const payload = {
          category: parseInt(category) || null,
          brand: brand,
        };

        await this.loadProductsInCat(payload);
      } else {
        this.$store.dispatch("cart/load");
        this.$store
          .dispatch("auth/loadCustomer")
          .then(() => {})
          .catch((err) => {
            //this.$router.push({ name: 'Login' }).catch(() => {});
          });
      }
    }
  },
  computed: {
    isMatrix() {
      return this.$store.getters["application/isMatrix"];
    },
    showLoader() {
      return this.$store.state.showLoader;
    },
    isInMaintenance: function () {
      return this.$store.getters["application/isInMaintenance"];
    },
    categories: function () {
      return this.$store.getters["categories/getTree"];
    },
    menu: function () {
      return this.categories.map((cat) => {
        if (cat.children && cat.children.length > 0) {
          return {
            title: cat.label,
            element: "a",
            dropdown: cat.label,
            content: CategoryDropdown,
            data: cat,
            attributes: {
              class: ["item"],
            },
          };
        } else {
          return {
            title: cat.label,
            element: "a",
            data: cat,
            attributes: {
              class: ["item"],
            },
            listeners: {
              click: () => {
                this.loadProductsInCat({ category: cat.id, brand: null });
              },
            },
          };
        }
      });
    },
  },
  methods: {
    loadProductsInCat: async function (payload) {
      this.scrollToTop();
      this.$store.dispatch("products/loadProductsByCategory", payload);
      this.setQuery(payload);
    },
    scrollToTop() {
      window.scrollTo(50, 40);
    },
    setQuery: function (query) {
      let obj = {};

      if (query) {
        obj = Object.assign({}, this.$route.query);

        Object.keys(query).forEach((key) => {
          let value = query[key];
          if (value) {
            obj[key] = value;
          } else {
            delete obj[key];
          }
        });
      }

      if (this.$route.name === "Home") {
        this.$router
          .replace({
            ...this.$router.currentRoute,
            query: obj,
          })
          .catch(() => {});
      } else {
        this.$router.push({ name: "Home", query: obj }).catch(() => {});
      }
    },
  },
  components: {
    Header2,
    FloatingButton,
  },
};
</script>

<style>
body {
  height: calc(100vh - 150px);
}

.empty {
  height: 80px;
}
</style>
