<template>
  <div class="ui breadcrumb">
    <i class="home icon"></i>

    <a href="javascript:void(0)" class="section" @click="loadInicio">Inicio</a>
    <template v-for="(section, index) in locations">
      <i :key="'divider-' + index" class="right chevron icon divider"></i>
      <div
        :key="'section-' + section.name"
        v-if="section.search"
        class="active section"
      >
        Buscando:
        <a href="javascript:void(0)">{{ section.search }}</a>
      </div>
      <div
        :key="'section-' + section.name"
        v-if="section.attribute"
        class="section"
      >
        Talla: {{ section.attribute }}
        <a href="javascript:void(0)">{{ section.search }}</a>
      </div>
      <a
        v-else
        :key="'category' + section.name"
        href="javascript:void(0)"
        class="section"
        @click="loadCategory(section.categoryId, section.brandId)"
        >{{ section.name }}</a
      >
    </template>
  </div>
</template>

<script>
export default {
  name: 'CategoryLocation',
  computed: {
    locations: function() {
      return this.$store.getters['products/getLocation'];
    }
  },
  methods: {
    loadCategory: function(categoryId, brandId) {
      const payload = {
        category: categoryId | null,
        brand: brandId
      };

      this.$emit('onLoadCategory', payload);
    },
    loadInicio: function() {
      this.$emit('onLoadCategory');
    }
  }
};
</script>

<style lang="css" scoped></style>
