<template>
  <div class="ui segment">
    <h4 class="ui dividing header">Direcciones</h4>

    <div class="ui divided unstackable items">
      <AddressItem
        v-for="address in addresses"
        :key="address._id"
        :address="address"
        :editable="editable"
        :selected="selected"
        @onSelected="selectedAddress"
        @onEdit="edit"
        @onRemove="remove"
      />
    </div>

    <div v-if="addresses.length === 0">
      No hay niguna dirección registrada
    </div>
  </div>
</template>

<script>
import AddressItem from './AddressItem';

export default {
  name: 'AddressList',
  props: ['addresses', 'editable'],
  data: function() {
    return {
      selected: ''
    };
  },
  created: function() {
    if (!this.editable) this.selectedAddress(this.addresses[0]._id);
  },
  methods: {
    selectedAddress: function(value) {
      this.selected = value;
      this.$emit('onSelected', value);
    },
    edit: function(addressId) {
      this.$emit('onEdit', addressId);
    },
    remove: function(addressId) {
      this.$emit('onRemove', addressId);
    }
  },
  components: {
    AddressItem
  }
};
</script>

<style lang="css" scoped></style>
