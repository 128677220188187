<template>
  <div class="ui container">
    <VueSlickCarousel v-bind="settings">
      <div v-for="(banner, index) in banners" :key="'banner-' + index">
        <img class="ui image" :src="banner" alt="" />
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  name: 'Banner',
  props: ['banners'],
  data: function() {
    return {
      settings: {
        arrows: true,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 10000,
        cssEase: 'linear',
        adaptiveHeight: true
      }
    };
  },
  components: { VueSlickCarousel }
};
</script>

<style lang="css" scoped></style>
