<template>
  <div class="">
    <div class="mx-4 p-4">
      <div class="flex items-center">
        <template v-for="(step, index) in steps">
          <div
            :key="'step-' + index"
            class="flex items-center"
            :class="[index !== steps.length - 1 ? 'w-full' : '']"
          >
            <div
              class="relative flex flex-col items-center"
              :class="step_txt[getStepStatus(index, step)]"
            >
              <div
                class="rounded-full transition duration-500 ease-in-out border-2 h-12 w-12 flex items-center justify-center py-3"
                :class="[
                  step_bg[getStepStatus(index, step)],
                  step_border[getStepStatus(index, step)]
                ]"
              >
                <i
                  :class="[
                    getStepStatus(index, step) === 'completed'
                      ? 'fa fa-check'
                      : step.icon
                  ]"
                  aria-hidden="true"
                ></i>
              </div>
              <div
                class="absolute top-0 text-center mt-14 w-32 text-xs font-medium uppercase "
                :class="step_label[getStepStatus(index, step)]"
              >
                {{ step.title }}
              </div>
            </div>
            <div
              v-if="index < steps.length - 1"
              class="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300 "
              :class="[
                getStepStatus(index, step) === 'completed'
                  ? 'border-green-600'
                  : ''
              ]"
            ></div>
          </div>
        </template>
      </div>
    </div>

    <div :class="['animated-content', getLoading]">
      <transition
        :enter-active-class="enterAnimation"
        :leave-active-class="leaveAnimation"
        mode="out-in"
      >
        <!--If keep alive-->
        <keep-alive v-if="keepAliveData">
          <component
            :is="steps[currentStep.index].component"
            @next="nextStep"
            @loading="isLoading"
          ></component>
        </keep-alive>
        <!--If not show component and destroy it in each step change-->
        <component v-else :is="steps[currentStep.index].component"></component>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stepper2',
  props: {
    steps: {
      type: Array,
      default: function() {
        return [
          {
            icon: 'mail',
            name: 'first',
            title: 'Sample title 1',
            subtitle: 'Subtitle sample'
          },
          {
            icon: 'report_problem',
            name: 'second',
            title: 'Sample title 2',
            subtitle: 'Subtitle sample'
          }
        ];
      }
    },
    keepAlive: {
      type: Boolean,
      default: true
    },
    reset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentStep: {},
      previousStep: {},
      nextButton: {},
      canContinue: false,
      finalStep: false,
      loading: false,
      stepsStatus: [],
      keepAliveData: this.keepAlive,
      step_txt: {
        active: 'text-green-600',
        completed: 'text-white',
        disabled: 'text-gray-400'
      },
      step_label: {
        active: 'text-green-600',
        completed: 'text-green-600',
        disabled: ''
      },
      step_bg: {
        active: '',
        completed: 'bg-green-600',
        disabled: ''
      },
      step_border: {
        active: 'border-green-600',
        completed: 'border-green-600',
        disabled: 'border-gray-300'
      }
    };
  },
  computed: {
    enterAnimation() {
      if (this.currentStep.index < this.previousStep.index) {
        return 'animated quick fadeInLeft';
      } else {
        return 'animated quick fadeInRight';
      }
    },
    leaveAnimation() {
      if (this.currentStep.index > this.previousStep.index) {
        return 'animated quick fadeOutLeft';
      } else {
        return 'animated quick fadeOutRight';
      }
    },
    getLoading() {
      if (this.loading) {
        return 'loading';
      } else {
        return '';
      }
    }
  },
  methods: {
    getStepStatus(index, step) {
      if (this.currentStep.index === index) {
        return 'active';
      } else {
        return this.stepsStatus[index];
      }
    },
    activateStep(index, back = false) {
      if (this.steps[index]) {
        this.previousStep = this.currentStep;
        this.currentStep = {
          name: this.steps[index].name,
          index: index
        };
        if (index + 1 === this.steps.length) {
          this.finalStep = true;
        } else {
          this.finalStep = false;
        }
      }
    },
    nextStep() {
      this.stepsStatus[this.currentStep.index] = 'completed';
      let currentIndex = this.currentStep.index + 1;
      this.activateStep(currentIndex);
    },
    isLoading: function(value) {
      this.loading = value;
    },
    init() {
      this.activateStep(0);
      this.stepsStatus = this.steps.map((step, index) => {
        return 'disabled';
      });
    }
  },
  created: function() {
    this.init();
  }
};
</script>

<style lang="css" scoped>
.animated-content {
  overflow: hidden;
  margin: 1.5rem 0;
}
</style>
