<template>
  <div class="item">
    <div class="ui tiny image">
      <img :src="product.image" />
    </div>
    <div class="content">
      <a class="header">{{ product.name }}</a>
      <div class="meta">
        <span class="stay"
          >{{ `Cantidad: ${product.quantity} x `
          }}{{ price | numeralFormat('$0,0.00') }}</span
        >
        <span class="price">{{
          (product.quantity * price) | numeralFormat('$0,0.00')
        }}</span>
      </div>
      <div v-if="showButtons" class="extra">
        <div
          class="ui left floated primary tiny button"
          @click="updateQuantity"
        >
          <i class="pencil icon"></i>
          Modificar
        </div>
        <div class="ui left floated red tiny button" @click="removeProduct">
          <i class="trash icon"></i>
          Quitar
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['product', 'show-buttons', 'isWholesale', 'isMatrix'],
  computed: {
    price: function() {
      if (!this.isMatrix) {
        return this.isWholesale
          ? this.product.shopWholesalePrice
          : this.product.shopPrice;
      } else {
        return this.isWholesale
          ? this.product.wholesalePrice
          : this.product.price;
      }
    }
  },
  methods: {
    updateQuantity: function() {
      this.$emit('updateQuantity', this.product);
    },
    removeProduct: function() {
      this.$emit('removeProduct', this.product);
    }
  }
};
</script>

<style></style>
