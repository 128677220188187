// @ts-nocheck
import Vue from 'vue';
import SuiVue from 'semantic-ui-vue';
import VueNumerals from 'vue-numerals';
import Paginate from 'vuejs-paginate';
import VueMoment from 'vue-moment';
import VueNumberInput from '@chenfengyuan/vue-number-input';
import infiniteScroll from 'vue-infinite-scroll';
import VueStripeMenu from 'vue-stripe-menu';
import Vuelidate from 'vuelidate';
import VueSplash from 'vue-splash';
import Popover from 'vue-js-popover';
import VueFbCustomerChat from 'vue-fb-customer-chat';
import axios from 'axios';

import moment from 'moment';

require('moment/locale/es');

import App from './App';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.prototype.$http = axios;
const token = store.getters['auth/getAccessToken'];
const code = store.getters['auth/getAccessCode'];

if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] =
    'Bearer ' + token;
}

if (code) {
  Vue.prototype.$http.defaults.headers.common['X-CODE'] = code;
}

Vue.use(SuiVue);
Vue.use(VueNumerals);
Vue.component('paginate', Paginate);
Vue.use(VueNumberInput);
Vue.use(VueMoment, { moment });
Vue.use(infiniteScroll);

Vue.use(VueStripeMenu);
Vue.use(Vuelidate);
Vue.use(VueSplash);
Vue.use(Popover);
Vue.use(VueFbCustomerChat, {
  page_id: '572338559595767', //  change 'null' to your Facebook Page ID,
  theme_color: '#333333', // theme color in HEX
  locale: 'es_LA' // default 'en_US'
});

new Vue({
  router: router,
  store: store,
  render: h => h(App)
}).$mount('#app');
