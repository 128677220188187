<template lang="html">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-wrap w-full mb-8">
      <div class="w-full mb-6 lg:mb-0">
        <h1
          class="sm:text-4xl text-5xl font-medium title-font mb-2 text-gray-900"
        >
          Reseñas
          <button
            class="ui right floated red button"
            type="button"
            name="button"
            v-if="isAuthenticated"
            @click="toggleDialog"
          >
            Escribe tu reseña
          </button>
        </h1>
        <div class="h-1 w-20 bg-indigo-500 rounded"></div>
      </div>
    </div>
    <div class="flex flex-wrap -m-4">
      <div
        class="p-4 lg:w-1/3 sm:1/3"
        v-for="(review, index) in reviews"
        :key="'review-' + index"
      >
        <div class="flex rounded-lg h-full bg-gray-600 px-8 pt-8 flex-col">
          <div class="flex items-center mb-1">
            <div
              class="w-28 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                :class="[
                  i <= review.rate ? 'text-yellow-400' : 'text-gray-400'
                ]"
                viewBox="0 0 20 20"
                fill="currentColor"
                v-for="i in 5"
                :key="'start-' + i"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                />
              </svg>
            </div>
            <h2 class="text-white text-lg title-font font-bold">
              {{ review.title }}
            </h2>
          </div>
          <div class="text-gray-300 italic">
            {{ review.category }}
          </div>
          <div class="flex-grow mt-1">
            <p class="leading-relaxed text-lg text-white">
              {{ review.review }}
            </p>
          </div>
          <div class="flex items-center my-4">
            <div class="flex-1">
              <div class="flex items-center">
                <div
                  class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <span class="text-white text-sm title-font font-medium">
                  {{ review.author ? review.author.name : 'Anónimo' }}
                </span>
              </div>
            </div>
            <div class="">
              <span class=" text-sm text-gray-300">{{
                review.createdOn | moment('from')
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sui-modal v-model="open" size="tiny">
      <sui-modal-header>Agregar Reseña</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <form class="ui big form">
            <div
              class="field required"
              :class="{ error: $v.review.title.$error }"
            >
              <label>Titulo</label>
              <div class="ui input">
                <input
                  type="text"
                  placeholder="Recomendado, excelente servicio, etc"
                  v-model.trim="$v.review.title.$model"
                />
              </div>
            </div>
            <div
              class="field required"
              :class="{ error: $v.review.category.$error }"
            >
              <label>Categoría</label>
              <div class="ui input">
                <input
                  type="text"
                  placeholder="¿Que compraste?"
                  v-model.trim="$v.review.category.$model"
                />
              </div>
            </div>

            <div
              class="field required"
              :class="{ error: $v.review.review.$error }"
            >
              <label>Tu reseña</label>
              <textarea
                rows="4"
                placeholder="Escribe tu reseña"
                v-model.trim="$v.review.review.$model"
              ></textarea>
            </div>
            <div
              class="field required"
              :class="{ error: $v.review.rate.$error }"
            >
              <label>Califica nuestro servicio</label>
              <span>
                <sui-rating
                  icon="star"
                  :rating="review.rate"
                  :max-rating="5"
                  @rate="handleRate"
                />
              </span>
            </div>
          </form>
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click="toggleDialog">
          Cancelar
        </sui-button>
        <sui-button primary @click="save" :class="{ disabled: $v.$invalid }">
          Guardar
        </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'ReviewPage',
  data: function() {
    return {
      open: false,
      review: {
        title: '',
        rate: 1,
        review: '',
        category: '',
        author: ''
      },
      coolors: [{ background: '#E7E6F7', foreground: '#f0f0f0' }]
    };
  },
  validations: function() {
    return {
      review: {
        title: { required },
        category: { required },
        review: { required },
        rate: { required }
      }
    };
  },
  created: async function() {
    await this.$store.dispatch('reviews/load');
  },
  computed: {
    reviews: function() {
      return this.$store.getters['reviews/getReviews'];
    },
    customerId: function() {
      return this.$store.getters['auth/getCustomerId'];
    },
    isAuthenticated: function() {
      return this.$store.getters['auth/isAuthenticated'];
    }
  },
  methods: {
    reset: function() {
      this.review = {
        title: '',
        rate: 1,
        review: '',
        category: '',
        author: ''
      };
      this.$v.review.$reset();
    },
    toggleDialog: function() {
      this.open = !this.open;
      this.reset();
    },
    handleRate(evt, props) {
      this.review.rate = props.rating;
    },
    save: async function() {
      this.review.author = this.customerId;

      const resp = await this.$store.dispatch('reviews/save', this.review);
      await this.$store.dispatch('reviews/load');
      this.toggleDialog();
    }
  }
};
</script>

<style lang="css" scoped></style>
