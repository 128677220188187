<template>
  <div class="md:flex-1 p-4 ui segment" v-if="images.length > 0">
    <div class="h-125 md:h-155 rounded-lg bg-gray-100 mb-4">
      <div class="rounded-lg bg-gray-100 mb-4 flex items-center justify-center">
        <img
          v-if="images"
          :src="image.url"
          class="h-120 md:h-150 object-cover object-center cursor-pointer"
          @click="open"
        />
      </div>
    </div>

    <div class="flex -mx-2 mb-4">
      <div class="flex-initial px-2 w-36" v-for="(image, index) in images" v-bind:key="'gallery-' + index">
        <button
          
          class="focus:outline-none rounded-sm w-32 md:h-32 ring-1 ring-gray-300 bg-gray-100 flex items-center justify-center"
          :class="{
            'ring-2 ring-indigo-300 ring-inset': index === selectedImage
          }"
          @click="selectImage(index)"
        >
          <img class="w-28 sm:w-28 p-1" :src="image.thumbnailUrl" />
        </button>
      </div>
    </div>
    <CoolLightBox
      :items="items"
      :index="index"
      @close="index = null"
      :closeOnClickOutsideMobile="true"
    />
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
  name: 'ProductGallery',
  props: ['images'],
  data: function() {
    return { selectedImage: 0, index: null };
  },
  computed: {
    image: function() {
      return this.images[this.selectedImage];
    },
    items: function() {
      return this.images.map(img => {
        return { src: img.largeUrl, thumb: img.thumbnailUrl };
      });
    }
  },
  methods: {
    selectImage: function(x) {
      this.selectedImage = x;
    },
    open: function() {
      this.index = this.selectedImage;
    }
  },
  components: {
    CoolLightBox
  }
};
</script>

<style lang="css" scoped></style>
