<template lang="html">
  <div class="">
    <button
      ref="popoverBtn"
      class="ui gray labeled icon button custom-height"
      v-popover:filter
    >
      <i class="filter icon"></i>
      Filtros
    </button>

    <popover name="filter" event="click" :pointer="false" :width="375">
      <div class="m-4">
        <h4 class="text-lg font-bold">Filtros</h4>
        <br />
        <form class="w-full max-w-lg" v-on:submit="e => e.preventDefault()">
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-first-name"
              >
                Categoria
              </label>
              <sui-dropdown
                fluid
                :options="categoryOpts"
                placeholder="Categoria"
                selection
                direction="downward"
                v-model="currentCat"
              />
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Subcategoria
              </label>
              <sui-dropdown
                fluid
                selection
                placeholder="Subcategoria"
                direction="downward"
                :options="subcategoryOpts"
                v-model="currentSub"
              />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-first-name"
              >
                Marca
              </label>
              <sui-dropdown
                fluid
                :options="brands"
                placeholder="Marca"
                selection
                v-model="currentBrand"
                direction="downward"
              />
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Talla
              </label>
              <sui-dropdown
                fluid
                :options="sizesOpts"
                placeholder="Talla"
                search
                selection
                v-model="currentSize"
                direction="downward"
              />
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-2">
            <div class="w-1/3 md:w-1/3 px-3 mb-6 md:mb-0">
              <button
                class="ui  red button custom-height"
                type="button"
                name="button"
                @click="clear"
              >
                Borrar filtros
              </button>
            </div>
            <div class="w-1/3 md:w-1/3 px-3 mb-6 md:mb-0"></div>
            <div class="w-1/3 md:w-1/3 px-3 mb-6 md:mb-0">
              <button
                class="ui teal labeled icon button custom-height"
                type="button"
                @click="apply"
              >
                <i class="search icon"></i>
                Buscar
              </button>
            </div>
          </div>
        </form>
      </div>
    </popover>
  </div>
</template>

<script>
export default {
  name: 'SearchFilter',
  data() {
    return {
      currentCat: null,
      currentSub: null,
      currentBrand: null,
      currentSize: null,

      menuHeader: {
        icon: '',
        content: ''
      },
      searchInMenu: {
        icon: 'search',
        iconPosition: 'left'
      }
    };
  },
  created: async function() {
    await this.$store.dispatch('brands/loadBrands');
  },
  watch: {
    currentCat: function(newval, old) {
      if (newval === 'ALL') {
        this.currentSub = null;
        this.currentBrand = null;
      }
    }
  },
  computed: {
    brands: function() {
      if (this.subBrandOpts.length > 0) {
        return this.subBrandOpts;
      } else {
        const brands = this.$store.getters['brands/getBrands'];
        return [{ key: 'ALL', value: 'ALL', text: 'Todas' }, ...brands];
      }
    },
    categoryTree: function() {
      return this.$store.getters['categories/getTree'];
    },
    categoryOpts: function() {
      const opts = this.categoryTree.map((item, i) => {
        return { key: item.id, value: item.id, text: item.label };
      });
      return [{ key: 'ALL', value: 'ALL', text: 'Todas' }, ...opts];
    },
    categoryChildren: function() {
      if (this.currentCat && this.currentCat !== 'ALL') {
        const category = this.categoryTree.find(c => c.id === this.currentCat);
        return category.children;
      } else {
        return [];
      }
    },
    subcategoryOpts: function() {
      if (
        this.currentCat &&
        this.currentCat !== 'ALL' &&
        this.categoryChildren
      ) {
        const subs = this.categoryChildren.map(s => {
          return { key: s.id, value: s.id, text: s.label };
        });
        return [{ key: 'ALL', value: 'ALL', text: 'Todas' }, ...subs];
      } else {
        return [];
      }
    },
    subcategoryBrands: function() {
      if (this.currentSub && this.currentSub !== 'ALL') {
        const sub = this.categoryChildren.find(s => s.id == this.currentSub);
        if (sub) return sub.brands;
      }
      return [];
    },
    subBrandOpts: function() {
      if (this.currentSub && this.currentSub !== 'ALL') {
        const brands = this.subcategoryBrands.map(b => {
          return { key: b._id, value: b._id, text: b.name };
        });
        return [{ key: 'ALL', value: 'ALL', text: 'Todas' }, ...brands];
      } else {
        return [];
      }
    },
    sizes: function() {
      return this.$store.getters['products/getSizes'];
    },
    sizesOpts: function() {
      const sizes = this.sizes.map(s => {
        return { key: s._id, value: s.name, text: s.name };
      });

      return [{ key: 'ALL', value: 'ALL', text: 'Todas' }, ...sizes];
    }
  },

  methods: {
    apply: function() {
      const payload = {
        category: this.currentCat
          ? this.currentSub && this.currentSub !== 'ALL'
            ? this.currentSub
            : this.currentCat
          : this.currentCat,

        brand:
          this.currentBrand && this.currentBrand !== 'ALL'
            ? this.currentBrand
            : null,
        attribute:
          this.currentSize && this.currentSize !== 'ALL'
            ? this.currentSize
            : null
      };
      this.$emit('onFilterChange', payload);
      this.$refs.popoverBtn.click();
    },
    clear: function() {
      this.currentCat = null;
      this.currentSub = null;
      this.currentBrand = null;
      this.currentSize = null;
      this.$emit('onFilterChange');
    }
  }
};
</script>

<style lang="css">
.custom-height {
  height: 37px;
}
[data-popover='filter'] {
  font-size: 12px;
  line-height: 1.5;
  top: 55px !important;
  left: 0 !important;
}
</style>
