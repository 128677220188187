<template>
  <div class="">
    <table class="ui very basic unstackable table">
      <tbody>
        <tr>
          <th class="right aligned">Total del pedido</th>
          <td class="right aligned">
            {{ totalProducts | numeralFormat("$0,0.00") }}
          </td>
        </tr>
        <tr>
          <th class="right aligned">Costo de envío</th>
          <td class="right aligned">
            {{ shipping.price | numeralFormat("$0,0.00") }}
          </td>
        </tr>
        <tr v-if="extraShipping > 0">
          <th class="right aligned">Excedente envío</th>
          <td class="right aligned">
            {{ extraShipping | numeralFormat("$0,0.00") }}
          </td>
        </tr>
        <tr v-if="isSecured">
          <th class="right aligned">Costo del seguro</th>
          <td class="right aligned">
            {{ insuranceCost | numeralFormat("$0,0.00") }}
          </td>
        </tr>
        <tr>
          <th class="right aligned">Cargo por embalaje</th>
          <td class="right aligned">
            {{ 10 | numeralFormat("$0,0.00") }}
          </td>
        </tr>
        <tr>
          <th class="right aligned" v-if="paymentMethod === 'MP'">SubTotal</th>
          <th class="right aligned" v-else>Total</th>
          <td class="right aligned">
            <b>{{ total | numeralFormat("$0,0.00") }}</b>
          </td>
        </tr>
        <tr v-if="paymentMethod === 'MP'">
          <th class="right aligned">Comisión</th>
          <td class="right aligned">
            {{ fee | numeralFormat("$0,0.00") }}
          </td>
        </tr>
        <tr v-if="paymentMethod === 'MP'">
          <th class="right aligned">Total</th>
          <td class="right aligned">
            <b>{{ (total + fee) | numeralFormat("$0,0.00") }}</b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
const MERCADO_PAGO = "MP";
const IVA = 1.16;

export default {
  name: "Summary",
  props: ["shipping", "isSecured", "paymentMethod"],
  computed: {
    totalProducts: function () {
      return this.$store.getters["cart/total"];
    },
    totalRealPieces: function () {
      return this.$store.getters["cart/totalRealPieces"];
    },
    paymentFee: function () {
      return this.$store.getters["application/paymentFee"];
    },
    paymentBase: function () {
      return this.$store.getters["application/paymentBase"];
    },

    extraShipping: function () {
      if (!this.shipping.hasPiecesFee) {
        return 0;
      }

      if (!(this.totalRealPieces > this.shipping.maxPieces)) {
        return 0;
      }

      const extraPieces = this.totalRealPieces - this.shipping.maxPieces;

      return extraPieces * this.shipping.piecePrice;
    },
    total: function () {
      return (
        this.totalProducts +
        this.shipping.price +
        this.extraShipping +
        this.insuranceCost +
        10
      );
    },
    insuranceCost: function () {
      if (this.isSecured) {
        const insurance = Math.ceil(
          this.totalProducts * (this.shipping.percentage / 100)
        );
        return Math.ceil(insurance * IVA); //Insurance has IVA
      } else {
        return 0;
      }
    },
    fee: function () {
      if (this.paymentMethod === MERCADO_PAGO) {
        const totalPlusFee = this.total / (1 - this.paymentFee / 100);
        let fee = totalPlusFee - this.total;
        fee = fee = (fee + this.paymentBase) * IVA; // Adding IVA
        return Math.round((fee + Number.EPSILON) * 100) / 100;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style></style>
