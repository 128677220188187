import axios from 'axios';

const state = {
  shippings: []
};

const getters = {
  getShippings: state => state.shippings
};

const actions = {
  loadShippings: ({ commit, getters }) => {
    console.log('Loading shippings');
    axios.get(`/api/v1/shippings`).then(response => {
      commit('LOAD_SHIPPINGS_SUCCESS', response.data.docs);
    });
  }
};

const mutations = {
  ['LOAD_SHIPPINGS_SUCCESS']: (state, payload) => {
    state.shippings = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
