<template>
  <tr class="mx-16 border-b border-gray-200 stripped">
    <td class="hidden md:table-cell w-28 h-24 pl-2">
      <div class="">
        <a href="#">
          <img :src="product.image" class="w-20 rounded" alt="Thumbnail" />
        </a>
      </div>
    </td>
    <td>
      <div class="flex flex-col my-4 md:my-0 pl-2">
        <p class="w-full mb-1 text-sm md:text-xl text-indigo-900">
          {{ product.name }}
        </p>
        <div class="flex" v-if="showButtons">
          <small class="mr-5"
            ><a href="#" class="text-sm text-gray-500" @click="updateQuantity"
              >Modificar</a
            ></small
          >
          <small
            ><a href="#" class="text-sm text-gray-500" @click="removeProduct"
              >Quitar</a
            ></small
          >
        </div>
      </div>
    </td>
    <td>
      <div class="flex justify-center">
        <div class="flex flex-row w-12 h-8">
          <input
            type="number"
            :value="product.quantity"
            readonly
            class="w-full font-semibold text-center text-gray-700 bg-gray-200 outline-none focus:outline-none hover:text-black focus:text-black"
          />
        </div>
      </div>
    </td>
    <td class="hidden text-center md:table-cell">
      <div class="text-sm lg:text-base font-medium">
        {{ price | numeralFormat('$ 0,0.00') }}
      </div>
    </td>
    <td class="text-right pr-2">
      <div class="text-sm lg:text-base font-medium">
        {{ (product.quantity * price) | numeralFormat('$ 0,0.00') }}
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: ['product', 'show-buttons', 'isWholesale', 'isMatrix'],
  computed: {
    price: function() {
      if (!this.isMatrix) {
        return this.isWholesale
          ? this.product.shopWholesalePrice
          : this.product.shopPrice;
      } else {
        return this.isWholesale
          ? this.product.wholesalePrice
          : this.product.price;
      }
    }
  },
  methods: {
    updateQuantity: function() {
      this.$emit('updateQuantity', this.product);
    },
    removeProduct: function() {
      this.$emit('removeProduct', this.product);
    }
  }
};
</script>

<style>
tr.stripped:nth-child(even) {
  background-color: #f9fafb;
}
</style>
