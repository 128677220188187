import axios from 'axios';

const state = {
  order: {},
  page: { docs: [], totalPages: 0, page: 1 },
  recordsPerPage: 10
};

const getters = {
  getOrder: state => state.order,
  getPageContent: state => state.page.docs,
  getTotalPages: state => state.page.totalPages,
  getTotalElemnts: state => state.page.totalDocs,
  getCurrentPage: state => state.page.page,
  getRecordsPerPage: state => state.recordsPerPage
};
const actions = {
  updateRecordsPerPage: async ({ commit }, recordsPerPage) => {
    commit('UPDATE_RECORDS_PER_PAGE', recordsPerPage);
  },
  loadPage: async ({ commit, rootGetters }, params) => {
    const customer = rootGetters['auth/getCustomerId'];

    return new Promise(
      (resolve, reject) => {
        axios
          .get(`/api/v1/customers/${customer}/orders`, { params: params })
          .then(response => {
            commit('FETCH_ORDER_PAGE_SUCCESS', response.data);
            resolve();
          });
      },
      error => {
        reject(error);
      }
    );
  },
  getOrderById: ({ commit }, orderId) => {
    return new Promise(
      (resolve, reject) => {
        axios.get(`/api/v1/orders/${orderId}`).then(response => {
          commit('FETCH_ORDER_SUCCESS', response.data);
          resolve(response.data);
        });
      },
      error => {
        reject(error);
      }
    );
  },
  getPdf: ({ commit }, orderId) => {
    return new Promise(
      (resolve, reject) => {
        axios({
          method: 'get',
          url: `/api/v1/orders/${orderId}/pdf`,
          responseType: 'arraybuffer',
          data: { test: 123 }
        }).then(response => {
          resolve(response.data);
        });
      },
      error => {
        reject(error);
      }
    );
  },
  generatePreference: ({ commit }, orderId) => {
    return new Promise(
      (resolve, reject) => {
        axios.get(`/api/v1/orders/${orderId}/payment`).then(response => {
          if (response.data.status === 'OK') {
            resolve(response.data);
          } else {
            reject();
          }
        });
      },
      error => {
        reject(error);
      }
    );
  }
};
const mutations = {
  ['FETCH_ORDER_PAGE_SUCCESS']: (state, payload) => {
    state.page = payload;
  },
  ['FETCH_ORDER_SUCCESS']: (state, payload) => {
    state.order = payload;
  },
  ['UPDATE_RECORDS_PER_PAGE']: (state, payload) => {
    state.recordsPerPage = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
