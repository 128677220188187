<template>
  <div class="min item">
    <div class="left center aligned floated space">
      <sui-checkbox
        v-if="!editable"
        radio
        :value="address._id"
        v-model="value"
      />
    </div>
    <div class="content">
      <div class="ui header">
        <h5>{{ `${address.street}` }}</h5>
      </div>
      <div class="meta">
        <span class="detail">{{
          `${address.neigh}, ${address.city}, ${address.state}`
        }}</span>
        <span class="stack">{{ `${address.postalCode}` }}</span>
      </div>
    </div>
    <div v-if="editable" class="footer">
      <div class="middle aligned">
        <a class="ui icon primary mini button" @click="edit"
          ><i class="edit icon"></i
        ></a>
        <a class="ui icon red mini button" @click="remove"
          ><i class="trash icon"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddressItem',
  props: ['address', 'selected', 'editable'],
  data: function() {
    return {
      value: this.selected
    };
  },
  watch: {
    selected: function(val) {
      this.value = this.selected;
    },
    value: function(val) {
      if (val !== this.selected) this.$emit('onSelected', val);
    }
  },
  methods: {
    edit: function() {
      this.$emit('onEdit', this.address._id);
    },
    remove: function() {
      this.$emit('onRemove', this.address._id);
    }
  }
};
</script>

<style lang="css" scoped>
.space {
  padding-top: 8px;
  margin-right: 10px;
}
</style>
