<template>
  <li class="sub-list" :class="{ subActive: show }">
    <a href="#" @click="show = !show">{{ sub.label }} </a>
    <ul class="sub-items">
      <li>
        <a href="javascript:void(0)" @click="loadCategory(sub.id)"
          >Todo de {{ sub.label }}</a
        >
      </li>
      <li v-for="brand in sort(sub.brands)" :key="brand._id">
        <a href="javascript:void(0)" @click="loadCategory(sub.id, brand._id)">{{
          brand.name
        }}</a>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'SubCollapse',
  props: ['sub', 'categoryId', 'categoryLabel'],
  data() {
    return {
      show: false
    };
  },
  methods: {
    sort(array) {
      const sorted = array.concat();

      return _.orderBy(sorted, 'name', 'asc');
    },
    loadCategory: function(categoryId, brandId) {
      const payload = {
        category: categoryId | null,
        brand: brandId
      };

      this.$emit('onLoadProducts', payload);
    }
  }
};
</script>

<style lang="scss" scoped></style>
