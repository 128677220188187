import Cart from '@/pages/Cart2';
import Checkout from '../pages/Checkout';

export default [
  {
    path: '/cart',
    name: 'Cart',
    meta: { title: 'Carrito' },
    component: Cart
  },
  {
    path: '/cart/checkout',
    name: 'Checkout',
    meta: { title: 'Finalizar pedido' },
    component: Checkout
  }
];
