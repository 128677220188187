<template>
  <div class="ui main container">
    <h3 class="text-2xl">
      Mi Cuenta - {{ `${tmpCustomer.name} ${tmpCustomer.lastName}` }}
    </h3>
    <br />

    <div>
      <sui-tab active-index="0">
        <sui-tab-pane icon="user" title="Información Personal">
          <div class="ui stackable grid">
            <div class="six wide column">
              <form class="ui form" :class="{ loading: isFormLoading }">
                <h4 class="ui dividing header">Información Personal</h4>
                <div
                  class="field required"
                  :class="{ error: $v.tmpCustomer.name.$error }"
                >
                  <label>Nombre(s)</label>
                  <div class="fields">
                    <div class="twelve wide field">
                      <input
                        type="text"
                        name="shipping[first-name]"
                        placeholder="Nombre(s)"
                        v-model.trim="$v.tmpCustomer.name.$model"
                        :disabled="!isEditting"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="field required"
                  :class="{ error: $v.tmpCustomer.lastName.$error }"
                >
                  <label>Apellidos</label>
                  <div class="fields">
                    <div class="twelve wide field">
                      <input
                        type="text"
                        name="shipping[first-name]"
                        placeholder="Apellidos"
                        v-model.trim="$v.tmpCustomer.lastName.$model"
                        :disabled="!isEditting"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="field required"
                  :class="{ error: $v.tmpCustomer.phone.$error }"
                >
                  <label>Teléfono celular</label>
                  <div class="fields">
                    <div class="twelve wide field">
                      <input
                        type="text"
                        name="shipping[first-name]"
                        placeholder="10 digitos"
                        v-model.trim="$v.tmpCustomer.phone.$model"
                        :disabled="!isEditting"
                      />
                    </div>
                  </div>
                </div>
                <div class="field required">
                  <label>Correo electrónico o teléfono celular</label>
                  <div class="fields">
                    <div class="twelve wide field">
                      <input
                        type="text"
                        placeholder="Correo o teléfono"
                        v-model.trim="$v.tmpCustomer.username.$model"
                        :disabled="!isEditting"
                      />
                    </div>
                  </div>
                </div>
                <div class="field required">
                  <label>RFC</label>
                  <div class="fields">
                    <div class="twelve wide field">
                      <input
                        type="text"
                        placeholder="RFC"
                        v-model.trim="$v.tmpCustomer.rfc.rfc.$model"
                        :disabled="!isEditting"
                      />
                    </div>
                  </div>
                </div>
                <button
                  class="ui primary button"
                  type="button"
                  @click="setEditmode"
                  v-if="!isEditting"
                >
                  Editar
                </button>
                <button
                  class="ui red button"
                  type="button"
                  @click="cancelEdit"
                  v-if="isEditting"
                >
                  Cancelar
                </button>
                <button
                  class="ui teal button"
                  type="button"
                  @click="saveChanges"
                  v-if="isEditting"
                  :class="{ disabled: $v.$invalid }"
                >
                  Guardar cambios
                </button>
              </form>
            </div>
            <div class="two wide column"></div>
            <div class="six wide column">
              <h4 class="ui dividing header">Cambiar contraseña</h4>
              <form class="ui form" :class="{ loading: isUpdatingPsw }">
                <div class="ten wide field required">
                  <label>Contraseña actual</label>
                  <input
                    type="password"
                    placeholder="Contraseña actual"
                    v-model="password.current"
                  />
                </div>
                <div class="ten wide field required">
                  <label>Nueva contraseña</label>
                  <input
                    type="password"
                    placeholder="Nueva contraseña"
                    v-model="password.value"
                  />
                </div>
                <div class="ten wide field required">
                  <label>Repetir contraseña</label>
                  <input
                    type="password"
                    placeholder="Repetir contraseña"
                    v-model="password.repeted"
                  />
                </div>
                <button
                  class="ui teal button"
                  :class="{ disabled: !isPasswordValid }"
                  type="button"
                  @click="updatePassword"
                >
                  Actualizar contraseña
                </button>
              </form>
            </div>
          </div>
        </sui-tab-pane>
        <sui-tab-pane
          icon="map"
          :label="addresses.length.toString()"
          title="Direcciones"
        >
          <div class="">
            <button
              class="ui small icon  basic button"
              type="button"
              @click="openAdressModal"
            >
              <i class="plus icon"></i>
              Regitrar nueva dirección
            </button>
          </div>
          <AddressList
            :addresses="addresses"
            :editable="true"
            @onEdit="editAddress"
            @onRemove="removeAddress"
          />
        </sui-tab-pane>
      </sui-tab>
    </div>

    <sui-modal v-model="addressModal" size="mini">
      <sui-modal-header>{{ addressModalTitle }}</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <AddressForm
            :address="tmpAddress"
            @onSave="onSaveAddress"
            @onCancel="closeAddressModal"
          />
        </sui-modal-description>
      </sui-modal-content>
    </sui-modal>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

import AddressList from '../../components/customer/AddressList';
import AddressForm from '../../components/customer/AddressForm';

export default {
  name: 'Profile',
  data: function() {
    return {
      isFormLoading: false,
      isEditting: false,
      isUpdatingPsw: false,
      addressModal: false,
      addressModalTitle: '',
      tmpAddress: {},
      tmpCustomer: {
        _id: '',
        name: '',
        lastName: '',
        phone: '',
        username: '',
        rfc: { rfc: '', fileUrl: '' },
        addresses: []
      },
      password: {
        current: '',
        value: '',
        repeted: ''
      }
    };
  },
  validations: {
    tmpCustomer: {
      name: {
        required
      },
      lastName: {
        required
      },
      phone: {
        required,
        minLength: minLength(10)
      },
      rfc: {
        rfc: {}
      },
      username: {
        required
      }
    }
  },
  computed: {
    customer: function() {
      return this.$store.getters['auth/getCustomer'];
    },
    addresses: function() {
      return this.tmpCustomer.addresses.filter(a => !a.deleted);
    },
    isPasswordValid: function() {
      if (this.password.current !== '') {
        if (
          this.password.value !== '' &&
          this.password.repeted !== '' &&
          this.password.value === this.password.repeted
        ) {
          return true;
        }
        return false;
      }
      return false;
    }
  },
  watch: {
    customer: function(obj) {
      this.tmpCustomer = _.cloneDeep(obj);
    }
  },
  created: function() {
    this.tmpCustomer = _.cloneDeep(this.customer);
  },
  methods: {
    setEditmode: function() {
      this.isEditting = true;
    },
    cancelEdit: function() {
      this.tmpCustomer = _.cloneDeep(this.customer);
      this.isEditting = false;
    },
    saveChanges: async function() {
      this.isFormLoading = true;
      try {
        const resp = await this.$store.dispatch(
          'auth/updateCustomer',
          this.tmpCustomer
        );
      } catch (err) {
        console.log('Error updating customer data');
      }

      this.isFormLoading = false;
      this.isEditting = false;
    },
    updatePassword: async function() {
      this.isUpdatingPsw = true;

      const payload = {
        _id: this.tmpCustomer._id,
        current: this.password.current,
        value: this.password.value
      };

      try {
        const resp = await this.$store.dispatch('auth/updatePassword', payload);
      } catch (err) {
        console.log('Error updating password');
      }
      this.isUpdatingPsw = false;
      this.password = {
        current: '',
        value: '',
        repeted: ''
      };
    },
    editAddress: function(addressId) {
      this.tmpAddress = _.cloneDeep(this.getAddress(addressId));
      this.addressModalTitle = 'Editar dirección';
      this.addressModal = true;
    },
    removeAddress: async function(addressId) {
      let address = this.getAddress(addressId);
      address.deleted = true;
      await this.$store.dispatch('auth/updateAddress', address);
    },
    getAddress: function(addressId) {
      return this.tmpCustomer.addresses.find(a => a._id === addressId);
    },
    onSaveAddress: async function(address) {
      if (address._id) {
        this.updateAddress(address);
      } else {
        await this.$store.dispatch('auth/addAddress', address);
        this.addressModal = false;
      }
    },
    updateAddress: async function(address) {
      await this.$store.dispatch('auth/updateAddress', address);
      this.addressModal = false;
    },
    openAdressModal: function() {
      this.tmpAddress = {
        street: '',
        neigh: '',
        postalCode: '',
        city: '',
        state: '',
        country: 'México'
      };
      this.addressModalTitle = 'Nueva dirección';
      this.addressModal = true;
    },
    closeAddressModal: function() {
      this.addressModal = false;
    }
  },
  components: {
    AddressList,
    AddressForm
  }
};
</script>

<style lang="css" scoped></style>
