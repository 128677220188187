import Vue from 'vue';
import Router from 'vue-router';
import VueGTag from 'vue-gtag';

import store from '../store';

import HomeRoutes from './home';
import CartRoutes from './cart';
import ReviewRoutes from './reviews';
import OrderRoutes from './order';
import CustomerRoutes from './customer';
import ProductDetails from '../pages/product/ProductDetails2';
import Presale from '../pages/Presale';
import PresaleLogin from '../pages/presales/PresaleLogin';
import Invoice from '../pages/invoices/Invoice';

Vue.use(Router);

Vue.use(
  VueGTag,
  {
    config: { id: process.env.VUE_APP_GTAG_ID },
    appName: process.env.VUE_APP_GTAG_APP_NAME,
    pageTrackerScreenviewEnabled: true
  },
  router
);

const routes = [
  ...OrderRoutes,
  ...HomeRoutes,
  ...CartRoutes,
  ...CustomerRoutes,
  ...ReviewRoutes,
  {
    path: '/products/:productId/details',
    name: 'ProductDetails',
    meta: { title: 'Producto' },
    component: ProductDetails
  },
  {
    path: '/preventa',
    name: 'Presale',
    meta: { title: 'Preventa', auth: 'CODE' },
    component: Presale
  },
  {
    path: '/preventa/auth',
    name: 'PresaleLogin',
    meta: { title: 'Validar' },
    component: PresaleLogin
  },
  {
    path: '/invoice',
    name: 'Invoice',
    meta: { title: 'Facturación' },
    component: Invoice
  }
];

const router = new Router({
  mode: 'history',
  routes: routes
});

router.beforeEach((to, from, next) => {
  const isInMaintenance = store.getters['application/isInMaintenance'];
  document.title = to.meta.title + ' - ' + store.getters['application/getName'];

  const name = to.name;
  const code = store.getters['auth/getAccessCode'];
  console.log(code);

  if (isInMaintenance && to.name !== 'Maintenance') {
    next({ name: 'Maintenance' });
  } else if (name === 'Presale') {
    if (code) {
      next();
    } else {
      next({ name: 'PresaleLogin' });
    }
  } else {
    next();
  }
});

export default router;
