<template>
  <div class="ui main container">
    <h2 class="text-2xl font-bold">Pedido #{{ order.reference }}</h2>
    <br />

    <div class="ui three unstackable top attached mini steps">
      <div class="step completed">
        <i class="check icon"></i>
        <div class="content">
          <div class="title">Pedido creado</div>
          <div class="description"></div>
        </div>
      </div>
      <div class="step" :class="getStepStatus(2)">
        <i class="money icon"></i>
        <div class="content">
          <div class="title">Pago confirmado</div>
          <div class="description"></div>
        </div>
      </div>
      <div class="step" :class="getStepStatus(3)">
        <i class="truck icon"></i>
        <div class="content">
          <div class="title">Pedido enviado</div>
          <div class="description"></div>
        </div>
      </div>
    </div>

    <div class="ui inverted blue segment" v-if="showPaymentButton">
      <div class="text-xl">
        Puedes pagar tu pedido ahora o puedes continuar comprando y pagar más
        tarde

        <button
          @click="tryPayment"
          class="bg-white text-gray-800 ml-5 font-bold rounded border-b-2 border-gray-500 hover:border-gray-800 hover:bg-gray-800 hover:text-white shadow-md py-2 px-6 inline-flex items-center"
        >
          <span class="mr-2">Proceder al Pago</span>
          <svg
            aria-hidden="true"
            data-prefix="far"
            data-icon="credit-card"
            class="w-8"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="currentColor"
              d="M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z"
            />
          </svg>
        </button>
      </div>
    </div>

    <div
      v-if="order.has_payment_attempts && order.status === 'WAITING_PAYMENT'"
      class="ui inverted green segment"
    >
      <p class="font-bold">
        Este pedido cuenta con un PAGO EN PROCESO. Si tu forma de pago fue pago
        en efectivo en OXXO, 7Eleven o algúna forma de depósito es posible que
        tu pago se refleje en las próximas horas. <br />
        Si tienes alguna duda, favor de comunicarse con un asesor
      </p>
    </div>

    <div
      v-if="!isMatrix && order.payment_method === 'TB'"
      class="ui blue inverted segment"
      v-html="message"
    ></div>

    <div
      v-if="
        isMatrix &&
          order.payment_method === 'TB' &&
          order.status === 'WAITING_PAYMENT'
      "
      class="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3 mt-3"
      role="alert"
    >
      <p class="font-bold text-lg">Información de Pago</p>
      <p class="" v-html="paymentMethod.checkout_msg"></p>
    </div>

    <Payment :preferenceId="preferenceId" />
    <OrderDetails :order="order" />
  </div>
</template>

<script>
import OrderDetails from '../../components/order/OrderDetails';
import Payment from '../../components/payments/Payment';

const MERCADO_PAGO = 'MP';

export default {
  name: 'Order',
  data: function() {
    return {
      preferenceId: '',
      paymentMethod: {
        name: '',
        checkout_msg: 'Sample'
      }
    };
  },
  computed: {
    isMatrix: function() {
      return this.$store.getters['application/isMatrix'];
    },
    order: function() {
      return this.$store.getters['orders/getOrder'];
    },
    message: function() {
      return this.$store.getters['application/getOrdersMessage'];
    },
    showPaymentButton: function() {
      return (
        this.order.payment_method === MERCADO_PAGO &&
        !this.order.has_payment_attempts &&
        this.order.status === 'WAITING_PAYMENT'
      );
    }
  },
  created: async function() {
    await this.$store.dispatch('orders/getOrderById', this.$route.params['id']);

    this.paymentMethod = await this.$store.dispatch(
      'paymentMethod/getByKey',
      this.order.payment_method
    );
  },
  methods: {
    tryPayment: async function() {
      try {
        const response = await this.$store.dispatch(
          'orders/generatePreference',
          this.order._id
        );
        if (response.preferenceId) {
          this.preferenceId = response.preferenceId;
        } else {
          console.log('No preference was provided');
        }
      } catch (e) {
        console.log('Error generating preference');
      }
    },
    getStepStatus: function(step) {
      if (step === 2) {
        return this.order.status === 'PAYMENT_ACCEPTED' ||
          this.order.status === 'SHIPPED'
          ? 'completed'
          : 'disabled';
      } else if (step === 3) {
        return this.order.status === 'SHIPPED' ? 'completed' : 'disabled';
      }
    }
  },
  components: {
    OrderDetails,
    Payment
  }
};
</script>

<style lang="css" scoped></style>
