import axios from 'axios';

const state = {
  isAuthenticated: false,
  accesToken: null,
  targetAddress: null,
  accessCode: null,
  presale: null,
  customer: {
    _id: '',
    type: 'guest',
    rfc: { rfc: '', fileUrl: '' },
    addresses: []
  }
};

const getters = {
  getCustomerId: state => {
    if (state.customer._id) return state.customer._id;
    else if (localStorage.getItem('customerId')) {
      return localStorage.getItem('customerId');
    } else {
      return '';
    }
  },
  getAccessToken: state => {
    if (state.accesToken) return state.accesToken;
    else if (localStorage.getItem('m32token')) {
      return localStorage.getItem('m32token');
    } else {
      return '';
    }
  },
  getAccessCode: state => {
    if (state.accessCode) return state.accessCode;
    else if (localStorage.getItem('accessCode')) {
      return localStorage.getItem('accessCode');
    } else {
      return '';
    }
  },
  getPresale: state => {
    return state.presale;
  },
  getTargetAddress: state => state.targetAddress,
  getCustomer: state => state.customer,
  isAuthenticated: state => state.isAuthenticated
};

const actions = {
  loadCustomer: ({ commit, getters }) => {
    return new Promise(
      (resolve, reject) => {
        const token = getters.getAccessToken;
        if (token) {
          axios.post('/api/v1/auth/refresh', { token: token }).then(response => {
            commit('CUSTOMER_LOGIN_SUCCESS', response.data);
            resolve();
          });
        } else {
          reject();
        }
      },
      error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      }
    );
  },
  findCustomerByPhone: ({ commit, getters }, phone) => {
    return new Promise(
      (resolve, reject) => {
        axios.get('/api/v1/customers/find', { params: { phone: phone } }).then(response => {
          const data = response.data;
          if (data.status === 'OK') {
            commit('CUSTOMER_CREATED_SUCCESS', data.customer);
            resolve(data.customer);
          } else {

            resolve(null);
          }
        });

      },
      error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      }
    );
  },
  createCustomer: ({ commit, getters }, payload) => {
    return new Promise(
      (resolve, reject) => {
        axios.post('/api/v1/customers', payload).then(
          response => {
            if (response.data.status === 'OK') {
              commit('CUSTOMER_CREATED_SUCCESS', response.data.customer);
              resolve(response.data.customer);
            } else {
              commit(
                'ERROR_MSG',
                {
                  title: 'Error!',
                  content:
                    'Ya existe un usuario con este correo/celular, elige uno diferente'
                },
                { root: true }
              );
              reject();
            }
          },
          error => {
            reject(error);
          }
        );
      },
      error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      }
    );
  },
  updateCustomer: ({ commit, getters }, payload) => {
    return new Promise(
      (resolve, reject) => {
        axios.put(`/api/v1/customers/${payload._id}`, payload).then(response => {
          commit('CUSTOMER_UPDATED_SUCCESS', response.data);
          commit(
            'OK_MSG',
            { title: 'Correcto!', content: 'Información actualizada' },
            { root: true }
          );
          resolve(response.data);
        });
      },
      error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      }
    );
  },
  updatePassword: ({ commit, getters }, payload) => {
    return new Promise(
      (resolve, reject) => {
        axios
          .put(`/api/v1/customers/${payload._id}/password-update`, payload)
          .then(response => {
            if (response.data.status === 'OK') {
              commit(
                'OK_MSG',
                { title: 'Correcto!', content: 'Contraseña actualizada' },
                { root: true }
              );
            } else {
              commit(
                'ERROR_MSG',
                {
                  title: 'Error!',
                  content: 'La contraseña actual es incorrecta'
                },
                { root: true }
              );
            }
            resolve(response.data);
          });
      },
      error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      }
    );
  },

  addAddress: ({ commit, getters }, payload) => {
    return new Promise(
      (resolve, reject) => {
        axios
          .post(`/api/v1/customers/${getters.getCustomerId}/address`, payload)
          .then(response => {
            commit('ADDRESS_CREATED_SUCCESS', response.data);
            resolve(response.data);
          });
      },
      error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      }
    );
  },
  updateAddress: ({ commit, getters }, payload) => {
    return new Promise(
      (resolve, reject) => {
        axios
          .put(
            `/api/v1/customers/${getters.getCustomerId}/address/${payload._id}`,
            payload
          )
          .then(response => {
            resolve(response.data);
            commit('ADDRESS_UPDATED_SUCCESS', response.data);
          });
      },
      error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      }
    );
  },
  setTargetAddress: ({ commit, getters }, payload) => {
    commit('SET_TARGET_ADDRESS', payload);
  },
  loginCustomer: ({ commit, getters }, payload) => {
    return new Promise(
      (resolve, reject) => {
        axios.post(`/api/v1/auth/login`, payload).then(response => {
          const data = response.data;
          if (data.status === 'OK') {
            commit('CUSTOMER_LOGIN_SUCCESS', data);

            commit(
              'INFO_MSG',
              {
                title: 'Hola!',
                content: `Bienvenido ${data.customer.name}!`
              },
              { root: true }
            );
            resolve({
              status: true
            });
          } else {
            commit('CUSTOMER_LOGIN_FAILED', {});
            reject({
              status: false,
              message: 'Usuario y/o contraseña incorrectos'
            });
          }
        });
      },
      error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      }
    );
  },
  logout: ({ commit, getters }) => {
    commit('CUSTOMER_LOGOUT');
    commit(
      'INFO_MSG',
      {
        title: 'Sesión finalizada!',
        content: `Vuelve pronto`
      },
      { root: true }
    );
  },
  validateAccessCode: ({ commit, getters }, payload) => {
    return new Promise(
      (resolve, reject) => {
        axios.post(`/api/v1/auth/code`, payload).then(response => {
          const data = response.data;
          if (data.status === 'OK') {
            commit('CODE_LOGIN_SUCCESS', data);

            commit(
              'INFO_MSG',
              {
                title: 'Hola!',
                content: `Acceso correcto!`
              },
              { root: true }
            );
            resolve({
              status: true
            });
          } else {
            commit(
              'WAR_MSG',
              {
                title: 'Código inválido!',
                content: `Parece que tu código no es valido`
              },
              { root: true }
            );
            reject({
              status: false,
              message: 'Código incorrecto'
            });
          }
        });
      },
      error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      }
    );
  },
  refreshCode: ({ commit, getters }) => {
    return new Promise(
      (resolve, reject) => {
        const code = getters.getAccessCode;
        if (code) {
          axios
            .post('/api/v1/auth/code/refresh', { code: code })
            .then(response => {
              if (response.data.status === 'OK') {
                commit('CODE_LOGIN_SUCCESS', response.data);
                resolve();
              } else {
                commit('CODE_CLEAR', response.data);
                reject('Codigo es invalido');
              }
            });
        } else {
          reject();
        }
      },
      error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      }
    );
  }
};

const mutations = {
  ['CUSTOMER_CREATED_SUCCESS']: (state, payload) => {
    state.customer = payload;
    localStorage.setItem('customerId', payload._id);
  },
  ['CUSTOMER_UPDATED_SUCCESS']: (state, payload) => {
    state.customer = payload;
  },
  ['ADDRESS_CREATED_SUCCESS']: (state, payload) => {
    state.customer = payload;
  },
  ['ADDRESS_UPDATED_SUCCESS']: (state, payload) => {
    state.customer = payload;
  },
  ['CUSTOMER_LOGIN_SUCCESS']: (state, payload) => {
    state.customer = payload.customer;
    state.accesToken = payload.access_token;
    state.isAuthenticated = true;
    localStorage.setItem('customerId', payload.customer._id);
    localStorage.setItem('m32token', payload.access_token);
  },
  ['CUSTOMER_LOGIN_FAILED']: (state, payload) => {
    state.customer = {
      _id: '',
      type: 'guest',
      addresses: []
    };
    state.accesToken = null;
    state.isAuthenticated = false;
    localStorage.removeItem('customerId');
    localStorage.removeItem('m32token');
  },
  ['SET_TARGET_ADDRESS']: (state, payload) => {
    state.targetAddress = payload;
  },
  ['CUSTOMER_LOGOUT']: (state, payload) => {
    state.customer = {
      _id: '',
      type: 'guest',
      addresses: []
    };
    state.accesToken = null;
    state.isAuthenticated = false;
    localStorage.removeItem('customerId');
    localStorage.removeItem('m32token');
  },

  ['CODE_LOGIN_SUCCESS']: (state, payload) => {
    state.accessCode = payload.code;
    state.presale = payload.presale;
    localStorage.setItem('accessCode', payload.code);
    axios.defaults.headers.common['X-CODE'] = payload.code;
  },
  ['CODE_CLEAR']: (state, payload) => {
    state.accessCode = null;
    state.presale = null;
    localStorage.removeItem('accessCode');
    axios.defaults.headers.common['X-CODE'] = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
