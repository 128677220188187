<template>
  <div class="ui main container">
    <Catalog
      store="orders"
      header="Mis pedidos"
      addButtonLabel=""
      :columns="columns"
      editOn="/orders"
      addOn=""
      :hideAdd="true"
      :hideDelete="true"
      :defaultRecordsPerPage="10"
    />
  </div>
</template>

<script>
import Catalog from "../../components/Catalog";

export default {
  name: "OrderList",
  data: function () {
    return {};
  },
  computed: {
    isMatrix: function () {
      return this.$store.getters["application/isMatrix"];
    },
    columns: function () {
      if (this.isMatrix) {
        return [
          {
            property: "reference",
            header: "# Pedido",
          },
          {
            property: "createdOn",
            header: "Fecha",
            type: "date",
          },

          {
            property: "status",
            header: "Estatus",
            type: "status",
            labels: [
              {
                display: "Esperando Pago",
                value: "WAITING_PAYMENT",
                class: "",
              },
              {
                display: "Pago aceptado",
                value: "PAYMENT_ACCEPTED",
                class: "blue",
              },
              {
                display: "Reservado",
                value: "RESERVED",
                class: "purple",
              },
              {
                display: "Enviado",
                value: "SHIPPED",
                class: "green",
              },
              {
                display: "Cancelado",
                value: "CANCELLED",
                class: "red",
              },
              {
                display: "Unido",
                value: "JOINED",
                class: "orange",
              },
            ],
          },
          {
            property: "total",
            header: "Total",
            type: "money",
          },
        ];
      } else {
        return [
          {
            property: "reference",
            header: "# Pedido",
          },
          {
            property: "createdOn",
            header: "Fecha",
            type: "date",
          },
          {
            property: "customer.name",
            concat: "customer.lastName",
            header: "Cliente",
          },
          {
            property: "status",
            header: "Estatus",
            type: "status",
            labels: [
              {
                display: "Esperando Pago",
                value: "WAITING_PAYMENT",
                class: "",
              },
              {
                display: "Pago aceptado",
                value: "PAYMENT_ACCEPTED",
                class: "blue",
              },
              {
                display: "Reservado",
                value: "RESERVED",
                class: "purple",
              },
              {
                display: "Enviado",
                value: "SHIPPED",
                class: "green",
              },
              {
                display: "Cancelado",
                value: "CANCELLED",
                class: "red",
              },
              {
                display: "Unido",
                value: "JOINED",
                class: "orange",
              },
              {
                display: "Preventa",
                value: "PRESALE",
                class: "purple",
              },
            ],
          },
          {
            property: "shopTotal",
            header: "Total",
            type: "money",
          },
        ];
      }
    },
  },
  components: {
    Catalog,
  },
};
</script>

<style lang="css" scoped></style>
