<template>
  <form class="ui form">
    <div class="field required" :class="{ error: $v.customer.name.$error }">
      <label>Nombre(s)</label>
      <input
        type="text"
        placeholder="Nombre(s)"
        v-model.trim="$v.customer.name.$model"
      />
    </div>
    <div class="field required" :class="{ error: $v.customer.lastName.$error }">
      <label>Apellidos</label>
      <input
        type="text"
        placeholder="Apellidos"
        v-model.trim="$v.customer.lastName.$model"
      />
    </div>
    <div class="field required" :class="{ error: $v.customer.phone.$error }">
      <label>Teléfono celular (10 dígitos)</label>
      <input
        type="text"
        placeholder="10 digitos"
        v-model.trim="$v.customer.phone.$model"
      />
    </div>
    <div class="field required" :class="{ error: $v.customer.phone.$error }">
      <label>RFC</label>
      <input
        type="text"
        placeholder="RFC"
        v-model.trim="$v.customer.rfc.rfc.$model"
      />
    </div>

    <div
      class="required field"
      :class="{ error: $v.customer.username.$error || showUsernameError }"
    >
      <label>Correo electrónico o Teléfono celular (10 dígitos)</label>
      <input
        type="text"
        placeholder="Correo o teléfono (10 dígitos)"
        v-model.trim="$v.customer.username.$model"
      />
    </div>
    <div class="required field" :class="{ error: $v.customer.password.$error }">
      <label>Contraseña</label>
      <input
        type="password"
        placeholder="Contraseña"
        v-model.trim="$v.customer.password.$model"
      />
    </div>
    <button
      class="ui black button"
      type="button"
      name="button"
      @click="save"
      :class="{ disabled: $v.$invalid }"
    >
      Registrarme
    </button>
  </form>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'SignupForm',
  props: ['showUsernameError'],
  data: function() {
    return {
      customer: {
        name: '',
        lastName: '',
        phone: '',
        username: '',
        password: '',
        rfc: { rfc: '', fileUrl: '' }
      }
    };
  },
  validations: {
    customer: {
      name: { required },
      lastName: { required },
      phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      username: { required },
      password: { required, minLength: minLength(4) },
      rfc: {
        rfc: {}
      }
    }
  },
  methods: {
    save: function() {
      this.customer.username = this.customer.username.toLowerCase();
      this.$emit('onSave', this.customer);
    }
  }
};
</script>

<style></style>
