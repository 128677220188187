<template>
  <vue-splash
    :show="true"
    :logo="logo"
    :custom-text="information"
    color="#00bfa5"
    :size="300"
    :fixed="true"
  />
</template>

<script>
export default {
  name: 'MaintenanceSplash',
  computed: {
    logo: function() {
      return this.$store.getters['application/getLogo'];
    },
    information: function() {
      return this.$store.getters['application/getMaintenanceInfo'];
    }
  }
};
</script>

<style lang="css" scoped></style>
