import axios from 'axios';

const state = {
  categories: [],
  category: {
    _id: 0,
    name: '',
    description: '',
    parentId: 0,
    subs: []
  },
  currentCategoryId: 0,
  menuCats: []
};

const getters = {
  getCategories: state => state.categories,
  getCategory: state => state.category,
  getMenuCats: state => state.menuCats,
  getCategoryNode: (state, getters, rootState, rootGetters) => {
    const categoryId = state.currentCategoryId;

    const categories = getters['getTree'];

    if (categoryId === 0) {
      return {
        label: 'Inicio',
        description: '',
        children: categories,
        parentId: 0,
        main: true
      };
    } else {
      let category = categories.find(c => c.id === categoryId);

      if (!category || !category.children) {
        const tmpCategory = state.categories.find(c => c._id === categoryId);
        category = {
          id: tmpCategory._id,
          label: tmpCategory.name,
          image: tmpCategory.image,
          brands: tmpCategory.brands,
          parentId: tmpCategory.parentId,
          isFinal: true
        };
      }
      return category;
    }
  },
  getLocation: (state, getters, rootState, rootGetters) => params => {
    /*
     params: category, brand attribute, searchTerm
    [
      { name: category.label, categoryId: category.id },
      { name: sub.label, categoryId: sub.id },
      { name: brand.name, categoryId: sub.id, brandId: brand._id }
    ],
    */
    let locations = [];
    let localBrands = false;

    if (params.category) {
      const category = state.categories.find(c => c._id === params.category);

      if (category) {
        if (category.parentId === 0) {
          locations.push({ name: category.name, categoryId: category._id });
        } else {
          const parent = state.categories.find(
            c => c._id === category.parentId
          );
          if (parent) {
            localBrands = true;
            locations.push({ name: parent.name, categoryId: parent._id });
            locations.push({ name: category.name, categoryId: category._id });
          }
        }

        if (params.brand) {
          if (localBrands) {
            const brand = category.brands.find(b => b._id === params.brand);
            if (brand) {
              locations.push({
                name: brand.name,
                categoryId: category._id,
                brandId: brand._id
              });
            }
          } else {
            const brand = rootGetters['brands/getBrands'].find(
              b => b.value === params.brand
            );
            if (brand) {
              locations.push({
                name: brand.text,
                categoryId: category._id,
                brandId: brand.value
              });
            }
          }
        }
      }
    } else if (params.brand) {
      const brand = rootGetters['brands/getBrands'].find(
        b => b.value === params.brand
      );
      if (brand) {
        locations.push({
          name: brand.text,
          brandId: brand.value
        });
      }
    }

    if (params.attribute) {
      locations.push({
        attribute: params.attribute
      });
    }

    if (params.searchTerm) {
      locations.push({
        search: params.searchTerm
      });
    }

    return locations;
  },

  getTree: state => {
    let tree = [],
      mappedArr = {};

    // Build a hash table and map items to objects

    state.categories.forEach(function (item) {
      let id = item._id;
      if (!mappedArr.hasOwnProperty(id)) {
        // in case of duplicates
        let category = {};
        category.id = id;
        category.label = item.name;
        category.description = item.description;
        category.isDefaultExpanded = false;
        category.brands = item.brands;
        category.color = item.color;
        category.image = item.image;
        category.parentId = item.parentId;

        // include children only to parent ctegories
        if (state.categories.find(c => c.parentId === id))
          category.children = [];

        if (item.parentId !== 0) category.parentId = item.parentId;

        mappedArr[id] = category; // the extracted id as key, and the item as value
      }
    });

    // Loop over hash table
    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        let mappedElem = mappedArr[id];

        // If the element is not at the root level, add it to its parent array of children. Note this will continue till we have only root level elements left
        if (mappedElem.parentId) {
          var parentId = mappedElem.parentId;

          if (mappedArr[parentId])
            mappedArr[parentId].children.push(mappedElem);
        }

        // If the element is at the root level, directly push to the tree
        else {
          mappedElem.isDefaultExpanded = true;
          tree.push(mappedElem);
        }
      }
    }

    return tree;
  }
};

const actions = {
  loadCategories: ({ commit, getters }) => {
    return new Promise(
      (resolve, reject) => {
        axios.get(`/api/v1/categories`).then(response => {
          commit('LOAD_CATEGORIES_SUCCESS', response.data);
          resolve(response.data);
        });
      },
      error => {
        reject(error);
      }
    );
  },
  loadCategorys: ({ commit }, categoryId) => {
    return new Promise(
      (resolve, reject) => {
        axios.get(`/api/v1/categories/${categoryId}/subs`).then(response => {
          commit('LOAD_CATEGORY_SUCCESS', response.data);
          resolve(response.data);
        });
      },
      error => {
        reject(error);
      }
    );
  },
  loadMenuCats: ({ commit }, categoryId) => {
    return new Promise(
      (resolve, reject) => {
        axios.get(`/api/v1/categories/menu`).then(response => {
          commit('LOAD_MENU_CATEGORIES_SUCCESS', response.data);
          resolve(response.data);
        });
      },
      error => {
        reject(error);
      }
    );
  },
  updateCurrentCategory: ({ commit }, categoryId) => {
    commit('UPDATE_CURRENT_CATEGORY', categoryId);
  }
};

const mutations = {
  ['LOAD_CATEGORIES_SUCCESS']: (state, payload) => {
    state.categories = payload;
  },
  ['LOAD_MENU_CATEGORIES_SUCCESS']: (state, payload) => {
    state.menuCats = payload;
  },
  ['LOAD_CATEGORY_SUCCESS']: (state, payload) => {
    state.category = payload;
  },
  ['UPDATE_CURRENT_CATEGORY']: (state, payload) => {
    state.currentCategoryId = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
