import Home from '../pages/Home';
import Login from '../pages/Login';
import SuLogin from '../pages/SuLogin';
import Test from '../pages/Test';
import Registration from '../pages/Registration';
import Maintenance from '../pages/Maintenance';

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: route => ({ reload: route.query.reload }),
    meta: { title: 'Home' }
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: 'Login' },
    component: Login
  },
  {
    path: '/sulogin',
    name: 'SuLogin',
    meta: { title: 'Su Login' },
    component: SuLogin
  },
  {
    path: '/signup',
    name: 'Signup',
    meta: { title: 'Registro' },
    component: Registration
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: { title: 'Mantenimiento' }
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  }
];
