<template>
  <div class="mobile-content mobile-header">
    <a href="#" class="mobile-title uppercase" @click="goHome">{{ appName }}</a>
    <br />
    <ul class="menu">
      <li class="menu-item" v-if="isAuthenticated">
        <a href="#" @click="goProfile">Mi Cuenta</a>
      </li>
      <li class="menu-item" v-if="isAuthenticated">
        <a href="#" @click="goOrders">Mis Pedidos</a>
      </li>
      <template v-for="item in menu">
        <Collapse
          :key="item.label"
          :category="item.data"
          @onLoadProducts="loadProducts"
        ></Collapse>
      </template>
      <li class="menu-item">
        <a href="#" @click="goToPresales">Preventa</a>
      </li>
      <li class="menu-item">
        <a href="#" @click="goToReviews">Reseñas</a>
      </li>
      <li class="menu-item">
        <a href="#" @click="goToInvoices">Facturación</a>
      </li>
      <li class="menu-item" v-if="!isAuthenticated">
        <a href="#" @click="login">Iniciar sesión</a>
      </li>
      <li class="menu-item" v-if="!isAuthenticated">
        <a href="#" @click="signup">Regístrate</a>
      </li>
      <li class="menu-item" v-if="isAuthenticated">
        <a href="#" @click="logout">Cerrar Sesion</a>
      </li>
    </ul>
  </div>
</template>

<script>
import Collapse from "./Collapse";

export default {
  name: "MobileDropdown",
  props: ["menu"],
  data: function () {
    return {};
  },
  computed: {
    isAuthenticated: function () {
      return this.$store.getters["auth/isAuthenticated"];
    },

    logo: function () {
      return this.$store.getters["application/getLogo"];
    },
    appName: function () {
      return this.$store.getters["application/getName"];
    },
  },
  methods: {
    loadProducts: function (payload) {
      this.$emit("onLoadProducts", payload);
    },
    login: function () {
      this.closeMenu();
      this.$router.push("login").catch(() => {});
    },
    goToReviews: function () {
      this.closeMenu();
      this.$router.push({ name: "ReviewPage" }).catch(() => {});
    },
    goToInvoices: function () {
      this.closeMenu();
      this.$router.push({ name: "Invoice" }).catch(() => {});
    },
    goToPresales: function () {
      this.closeMenu();
      this.$router.push({ name: "Presale" }).catch(() => {});
    },
    logout: function () {
      this.closeMenu();
      this.$store.dispatch("auth/logout").catch(() => {});
      this.goHome();
    },
    signup: function () {
      this.closeMenu();
      this.$router.push("signup").catch(() => {});
    },
    goHome: function () {
      this.closeMenu();
      this.$router.push("/").catch(() => {});
    },
    goProfile: function () {
      this.closeMenu();
      this.$router.push("/customer/profile").catch(() => {});
    },
    goOrders: function () {
      this.closeMenu();
      this.$router.push("/orders").catch(() => {});
    },
    closeMenu: function () {
      this.$emit("onClose");
    },
  },
  components: {
    Collapse,
  },
};
</script>

<style lang="css" scoped></style>
