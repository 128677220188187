<template>
  <div class="ui top fixed borderless menu">
    <div class="ui container" v-if="menu.length > 0">
      <vsm-menu ref="myMenu" :menu="menu" handler="hover" :screen-offset="50">
        <li slot="before-nav" class="logo vsm-section vsm-mob-full">
          <router-link to="/" class="header logo">
            <img v-if="logo" class="ui small image" :src="logo" />
            <span v-else class="text-xl font-bold">{{ appName }}</span>
          </router-link>
        </li>

        <template #default="data">
          <component
            :is="data.item.content"
            :category="data.item.data"
            :key="data.item.title"
            @onLoadProducts="loadProducts"
          />
        </template>

        <template slot="after-nav">
          <div class="cart-icon right menu">
            <a class="ui item vsm-mob-hide" @click="presales">
              <i class="black tag icon"></i>
              <div class="">Preventa</div>
            </a>
            <a class="ui item vsm-mob-hide" @click="reviews">
              <i class="green star icon"></i>
              <div class="">Reseñas</div>
            </a>
            <a class="ui item vsm-mob-hide" @click="invoices">
              <i class="newspaper icon"></i>
              <div class="">Facturación</div>
            </a>
            <a class="ui item vsm-mob-hide" v-if="isAuthenticated" @click="goProfile">
              <i class="user icon"></i>
              <div class="">Mi Cuenta</div>
            </a>

            <a class="ui item vsm-mob-hide" v-if="isAuthenticated" @click="goOrders">
              <i class="dolly icon"></i>
              <div class="">Mis pedidos</div>
            </a>

            <span class="timer">
              <BaseTimer :limit="timeRemaining" :maxLimit="maxLimit" />
            </span>
            <a class="ui item" @click="goCart">
              <i class="cart icon"></i>
              <div class="ui teal label">{{ totalItems }}</div>
            </a>

            <div class="ui item vsm-mob-hide" v-if="!isAuthenticated" @click="login">
              <div class="ui basic primary button">Iniciar sesión</div>
            </div>

            <a class="ui item vsm-mob-hide" v-if="isAuthenticated" @click="logout">
              <i class="close icon"></i>
              <div class="">Cerrar sesión</div>
            </a>
          </div>
          <vsm-mob class="mobile-menu" v-model="mobileMenu">
            <MobileDropdown
              :menu="menu"
              @onLoadProducts="loadProducts"
              @onClose="closeMenu"
            />
          </vsm-mob>
        </template>
      </vsm-menu>
    </div>
  </div>
</template>

<script>
import "vue-stripe-menu/dist/vue-stripe-menu.css";
import CategoryDropdown from "./CategoryDropdown";
import MobileDropdown from "./MobileDropdown";
import BaseTimer from "../cart/BaseTimer";

export default {
  name: "Header2",
  props: ["menu"],
  data() {
    return {
      mobileMenu: false,
    };
  },
  computed: {
    totalItems: function () {
      return this.$store.getters["cart/totalItems"];
    },
    isAuthenticated: function () {
      return this.$store.getters["auth/isAuthenticated"];
    },
    categories: function () {
      return this.$store.getters["categories/getMenuCats"];
    },
    logo: function () {
      return this.$store.getters["application/getLogo"];
    },
    appName: function () {
      return this.$store.getters["application/getName"];
    },
    timeRemaining: function () {
      return this.$store.getters["cart/getTimeRemaining"];
    },
    maxLimit: function () {
      return this.$store.getters["application/getCartTimeout"] * 60;
    },
  },
  methods: {
    reviews: function () {
      this.$router.push({ name: "ReviewPage" }).catch(() => {});
    },
    invoices: function () {
      this.$router.push({ name: "Invoice" }).catch(() => {});
    },
    presales: function () {
      this.$router.push({ name: "Presale" }).catch(() => {});
    },
    login: function () {
      this.$router.push({ name: "Login" }).catch(() => {});
    },
    logout: function () {
      this.$store.dispatch("auth/logout").catch(() => {});
      this.goHome();
    },
    signup: function () {
      this.$router.push({ name: "Signup" }).catch(() => {});
    },
    goHome: function () {
      this.$router.push({ name: "Home" }).catch(() => {});
    },
    goProfile: function () {
      this.$router.push("/customer/profile").catch(() => {});
    },
    goOrders: function () {
      this.$router.push("/orders").catch(() => {});
    },
    goCart: function () {
      this.$router.push({ name: "Cart" }).catch(() => {});
    },
    loadProducts: function (payload) {
      this.$emit("onLoadProducts", payload);
      this.closeMenu();
    },
    closeMenu: function () {
      this.mobileMenu = !this.mobileMenu;
    },
  },
  components: {
    CategoryDropdown,
    MobileDropdown,
    BaseTimer,
  },
};
</script>

<style lang="scss">
:root {
  --primary: #333;
  --secondary: #777;
  --link: #f9f9f9f9;
  --hover-link: #fff;
}

$header-background: #001219;
$root-links-bg: #14213d;
$root-links-color: #edf2f4;
$root-links-hover: #3d5a80;
$root-links-hover-color: #ced4da;

$category-active-bg: #495057;
$category-active-color: #ced4da;
$category-active-hover-bg: #e9ecef;
$category-active-hover-color: #495057;

$brands-bg: #dee2e6;
$brands-color: #343a40;
$brands-hover-bg: #adb5bd;

.logo {
  margin-right: 15px;
}

.vsm-menu {
  position: relative;
  width: 100%;
  nav {
    margin: 0 10px;
  }
  ul {
    margin: 0 auto;
  }
}

.vsm-mob-line {
  background: #000;
}

.vsm-root {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 53px;
}

.vsm-section_menu {
  flex: 1 1 auto;
  > * {
    padding: 0 25px;
    font-weight: 500;
    font-family: inherit;
  }
}

.vsm-link {
  font-size: 15px;
}

.cart-icon {
  float: right;
}
.mobile-menu {
  max-height: 450px;
  overflow-y: auto;
}

.mobile-content {
  background-color: gray;
  max-height: 550px;
  overflow-y: auto;
}

.scroll-section {
}

.mobile-header {
  background: $header-background;
  padding-top: 10px;
}

.mobile-title {
  padding-left: 20px;
  color: #fff;
  margin-bottom: 20px;
}

ul.menu {
  padding: 0;
  list-style: none;
  width: 100%;
  margin: 20px auto;

  box-shadow: 0px 0px 25px #00000070;
  clear: both;
  display: table;
  margin-bottom: 100px;

  .menu-item {
    font-size: 19px;
    //border-top: 1px solid #324252;
    border-bottom: 0px solid #324252;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    vertical-align: sub;
    background: $root-links-bg;
    clear: both;

    a {
      text-decoration: none;
      color: $root-links-color;
      padding: 8px 0px 8px 45px;
      display: block;
      height: 100%;
      box-sizing: border-box;
      &:hover {
        background-color: $root-links-hover;
        transition: 300ms all;
        color: $root-links-hover-color;
      }
    }
  }

  .list {
    font-size: 19px;
    border-bottom: 0px solid #324252;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    vertical-align: sub;
    background: $root-links-bg;
    clear: both;

    a {
      text-decoration: none;
      color: $root-links-color;
      padding: 8px 0px 8px 45px;
      display: block;
      height: 100%;
      box-sizing: border-box;
      &:hover {
        background-color: $root-links-hover;
        transition: 300ms all;
        color: $root-links-hover-color;
      }
    }
    .items {
      height: 10px;
      overflow: hidden;
      a {
        padding: 3px 45px 3px;
        &:hover {
          background-color: $category-active-hover-bg;
          color: $category-active-hover-color;
          transition: 300ms all;
        }
      }
    }
    &:last-child {
      //border-bottom: none;
    }
  }
  .active {
    > .items {
      display: block;
      background: $category-active-bg;
      padding: 0px;
      height: auto;
      color: #fff;
      transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: all 200ms;
      clear: both;
      float: left;
      width: 100%;
      li {
        padding: 0px;
        border-bottom: 0px solid #324252;
        list-style: none;
      }
      li:last-child {
        border-color: transparent;
        padding-bottom: 0px;
      }
      .active {
        > .items {
          background-color: #cedce8;
        }
      }
    }
    > a {
      color: $category-active-color;
      text-transform: uppercase;
      font-weight: bold;
    }
    .list {
      background: #f0f0f0;
      a {
        padding: 8px 0px 8px 45px;
      }
    }
  }

  .sub-list {
    font-size: 16px;
    border-color: transparent;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    vertical-align: sub;
    background: $category-active-bg;
    clear: both;

    a {
      text-decoration: none;
      color: $category-active-color;
      padding: 8px 0px 8px 45px;
      display: block;
      height: 100%;
      box-sizing: border-box;
      &:hover {
        background-color: $category-active-hover-bg !important;
        transition: 300ms all;
        color: #066fca;
      }
    }
    .sub-items {
      height: 10px;
      overflow: hidden;
      a {
        padding: 3px 45px 3px;
        &:hover {
          background: #6fabbc;
          color: #fff;
          transition: 300ms all;
        }
      }
    }
    &:last-child {
      //border-bottom: none;
    }
  }
  .subActive {
    > .sub-items {
      display: block;
      background: $brands-bg;
      padding: 0px;
      height: auto;
      //
      transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: all 200ms;
      clear: both;
      float: left;
      width: 100%;

      li {
        color: red !important;
        padding: 0px;
        border-bottom: 0px;
        list-style: none;

        a {
          color: $brands-color !important;
          &:hover {
            background: $brands-hover-bg !important;
            transition: 300ms all;
          }
        }
      }
      li:last-child {
        border-color: transparent;
        padding-bottom: 0px;
      }
      .active {
        > .items {
          background: $brands-bg;
        }
      }
    }
    > a {
      background: $brands-bg;
      color: $brands-color;
      text-transform: uppercase;
      font-weight: bold;
    }
    .list {
      background: $brands-bg;
      a {
        padding: 8px 0px 8px 45px;
        color: $brands-color;
      }
    }
  }
}

.timer {
  //  margin-top: -10px;
  margin-right: 10px;
}

@media only screen and (max-width: 800px) {
  .vsm-mob-hide {
    display: none !important;
  }
}
</style>
