import axios from 'axios';

const state = {
  reviews: []
};

const getters = {
  getReviews: state => state.reviews
};

const actions = {
  load: ({ commit, getters }) => {
    axios.get(`/api/v1/reviews`).then(response => {
      commit('LOAD_REVIEWS_SUCCESS', response.data.docs);
    });
  },
  save: ({ commit, getters }, payload) => {
    return new Promise(
      (resolve, reject) => {
        axios.post(`/api/v1/reviews`, payload).then(response => {
          commit(
            'OK_MSG',
            { title: 'Correcto!', content: 'Tu reseña ha sido registrada' },
            { root: true }
          );
          resolve(response.data);
        });
      },
      error => {
        reject(error);
      }
    );
  }
};

const mutations = {
  ['LOAD_REVIEWS_SUCCESS']: (state, payload) => {
    state.reviews = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
