<template>
  <div class="ui main container">
    <h2 class="text-xl font-bold uppercase">Finalizar pedido</h2>
    <Stepper :steps="steps"></Stepper>
  </div>
</template>

<script>
import Stepper from '../components/Stepper2';
import Divider from '../components/checkout/Divider';
import CustomerForm from '../components/customer/CustomerForm';
import ShippingAddress from '../components/customer/ShippingAddress';
import OrderConfirmationForm from '../components/cart/OrderConfirmationForm';

export default {
  name: 'Checkout',
  data: function() {
    return {
      steps: [
        {
          icon: 'fa fa-user',
          name: 'customer',
          title: 'Inf. Personal',
          subtitle: '',
          component: Divider
        },
        {
          icon: 'fa fa-truck',
          name: 'shipping',
          title: 'Dirección',
          subtitle: '',
          component: ShippingAddress
        },
        {
          icon: 'fa fa-cubes',
          name: 'confirmation',
          title: 'Confirmar',
          subtitle: '',
          component: OrderConfirmationForm
        },
        {
          icon: 'fa fa-credit-card',
          name: 'payment',
          title: 'Pago',
          subtitle: '',
          component: OrderConfirmationForm
        }
      ]
    };
  },
  components: {
    Stepper
  }
};
</script>

<style></style>
