<template>
  <div class="">
    <div class="flex my-6 items-center">
      <span class="px-4 py-2 bold whitespace-no-wrap">Paquetería</span>
      <div class="flex-initial w-56">
        <sui-dropdown
          placeholder="Seleccione paqueteria"
          fluid
          selection
          :options="shippings"
          v-model="current"
        />
      </div>
      <div v-if="shipping" class="flex-initial w-36">
        <span
          class="focus:outline-none font-bold text-black-800 text-lg py-2.5 px-5 rounded-md"
        >
          {{ shipping.price | numeralFormat("$ 0,0.00") }}
        </span>
      </div>
    </div>

    <div v-if="shipping" class="">
      <span class="stay">{{ shipping.description }}</span>

      <div class="my-5" v-if="shipping.secured">
        <sui-checkbox
          :label="`Asegura tu envio por el ${shipping.percentage}% adicional sobre el valor del pedido`"
          toggle
          v-model="secured"
        />
      </div>
    </div>
    <sui-modal v-model="showAlert" :closable="false" size="small">
      <sui-modal-header>Atención</sui-modal-header>
      <sui-modal-content scrolling>
        <sui-modal-description v-if="shipping">
          <img class="ui centered image" :src="shipping.imageUrl" />
        </sui-modal-description>
      </sui-modal-content>
      <sui-modal-actions>
        <button class="ui black button" @click="accept">Aceptar</button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
export default {
  name: "ShippingSelector",
  data: function () {
    return {
      current: null,
      secured: false,
      showAlert: false,
    };
  },
  computed: {
    shippings: function () {
      return this.$store.getters["shipping/getShippings"].map((s) => {
        return { text: s.name, value: s._id };
      });
    },
    shipping: function () {
      if (this.current !== null)
        return this.$store.getters["shipping/getShippings"].find(
          (s) => s._id === this.current
        );
      return undefined;
    },
  },
  watch: {
    current: function (val) {
      if (val !== null) {
        if (this.shipping.hasAlert) {
          this.showAlert = true;
        }
        this.$emit("onSelected", this.shipping);
      }
    },
    secured: function (val) {
      this.$emit("onSecured", val);
    },
  },
  created: async function () {
    await this.$store.dispatch("shipping/loadShippings");
  },
  methods: {
    accept: function () {
      this.showAlert = false;
    },
  },
};
</script>

<style lang="css" scoped></style>
