import axios from 'axios';
import {
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  PRODUCT_BY_ID,
  PRODUCT_BY_ID_SUCCESS,
  ADMIN_PRODUCT_BY_ID,
  ADMIN_PRODUCT_BY_ID_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  REMOVE_PRODUCT,
  REMOVE_PRODUCT_SUCCESS,
  ALL_PRODUCTS,
  ALL_PRODUCTS_SUCCESS,
  ALL_MANUFACTURERS,
  ALL_MANUFACTURERS_SUCCESS,
  ADD_TO_CART
} from '../mutation-types';

const state = () => ({
  filters: {},
  sizes: [],
  searchTerm: '',
  location: [],
  busy: false,
  products: [],
  totalPages: 1,
  totalDocs: 0,
  page: 1
});

const getters = {
  getProducts: state => state.products,
  getTotalPages: state => state.totalPages,
  getCurrentPage: state => state.page,
  getTotalElemnts: state => state.totalDocs,
  getFilters: state => state.filters,
  getSizes: state => state.sizes,
  getSearchTerm: state => state.searchTerm,
  getLocation: state => state.location,
  isBusy: state => state.busy
};

const actions = {
  fetchProducts: async ({ commit }) => {
    commit('SET_LOADER', true, { root: true });
    const response = await axios.get(`/products`);
    commit(ALL_PRODUCTS_SUCCESS, response.data);
    commit('SET_LOADER', false, { root: true });
  },
  loadProductsByCategory: async (
    { commit, getters, dispatch, rootGetters },
    payload
  ) => {
    commit('SET_LOADER', true, { root: true });

    let params = {
      size: 12,
      page: 1
    };

    let filters = {};

    if (payload) {
      Object.keys(payload).forEach(item => {
        if (payload[item]) {
          filters[item] = payload[item];
        }
      });
    }

    Object.assign(params, filters);

    commit('SET_BUSY', true);
    const response = await axios.get(`/api/v1/search2`, {
      params: params
    });

    commit(ALL_PRODUCTS_SUCCESS, response.data);

    const location = rootGetters['categories/getLocation'](filters);

    commit('SET_LOADER', false, { root: true });
    commit('SET_BUSY', false);
    commit('FILTER_UPDATE', filters);
    commit('SET_LOCATION', location);

    if (payload && payload.searchTerm) {
      commit('SET_SEARCH_TERM', payload.searchTerm);
    } else {
      commit('SET_SEARCH_TERM', '');
    }

    const categoryId = payload && payload.category ? payload.category : 0;
    dispatch('categories/updateCurrentCategory', categoryId, { root: true });
  },
  loadMore: async ({ commit, getters }) => {
    commit('SET_BUSY', true);
    return new Promise(
      (resolve, reject) => {
        if (getters.getCurrentPage + 1 <= getters.getTotalPages) {
          let params = {
            size: 12,
            page: getters.getCurrentPage + 1
          };

          if (getters.getSearchTerm) params.searchTerm = getters.getSearchTerm;

          const filters = getters.getFilters;

          Object.assign(params, filters);

          commit('SET_BUSY', true);
          axios.get(`/api/v1/search2`, { params: params }).then(response => {
            commit('MORE_PRODUCTS_SUCCESS', response.data);
            commit('SET_BUSY', false);
            resolve(response.data);
          });
        } else {
          // adding delay to avoid issues
          commit('SET_BUSY', true);
          setTimeout(() => {
            resolve({ status: 'NO_MORE' });
          }, 1500);
        }
      },
      error => {
        reject(error);
      }
    );
  },
  productById: ({ commit }, payload) => {
    commit(PRODUCT_BY_ID);
    return new Promise(
      (resolve, reject) => {
        axios.get(`/api/v1/products/${payload}`).then(response => {
          commit(PRODUCT_BY_ID_SUCCESS, response.data);
          resolve(response.data);
        });
      },
      error => {
        reject(error);
      }
    );
  },
  getStock: async ({ commit }, productId) => {
    return new Promise(
      (resolve, reject) => {
        axios.get(`/api/v1/products/${productId}/stock`).then(response => {
          console.log(productId, response.data);
          resolve(response.data);
        });
      },
      error => {
        reject(error);
      }
    );
  },
  updateStocks({ commit }, payload) {
    commit('UPDATE_STOCK', payload);
  },
  loadSizes: ({ commit }) => {
    return new Promise(
      (resolve, reject) => {
        axios.get(`/api/v1/products/attributes/sizes`).then(response => {
          commit('SIZES_LOADED', response.data);
          resolve();
        });
      },
      error => {
        reject(error);
      }
    );
  },
  presale: ({ commit }) => {
    return new Promise(
      (resolve, reject) => {
        axios.get(`/api/v1/presale`).then(response => {
          commit('ALL_PRODUCTS_SUCCESS', response.data);
          commit('SET_BUSY', false);
          resolve(response.data);
        });
      },
      error => {
        reject(error);
      }
    );
  }
};

const mutations = {
  [ALL_PRODUCTS](state) {
    state.showLoader = true;
  },
  [ALL_PRODUCTS_SUCCESS](state, payload) {
    state.products = [];
    state.products = payload.docs;
    state.page = payload.page;
    state.totalDocs = payload.totalDocs;
    state.totalPages = payload.totalPages;
  },
  ['MORE_PRODUCTS_SUCCESS'](state, payload) {
    for (let i = 0; i < payload.docs.length; i++) {
      state.products.push(payload.docs[i]);
    }

    state.page = payload.page;
    state.totalDocs = payload.totalDocs;
    state.totalPages = payload.totalPages;
  },
  ['SIZES_LOADED'](state, payload) {
    state.sizes = payload;
  },
  [PRODUCT_BY_ID](state) {
    state.showLoader = true;
  },
  [PRODUCT_BY_ID_SUCCESS](state, payload) {
    state.product = payload;
    state.products = state.products.map(p => {
      if (p._id === payload._id) {
        return payload;
      }
      return p;
    });
  },
  ['UPDATE_STOCK'](state, payload) {
    let product = state.products.find(
      p => p._id === payload.products[0].productId
    );
    if (product) {
      product.stocks = product.stocks.map(s => {
        const newStock = payload.products.find(p => p.attributeId === s._id);
        if (newStock) {
          s.stock = s.stock - newStock.quantity;
        }
        return s;
      });
    }
  },
  ['FILTER_UPDATE'](state, payload) {
    state.filters = payload;
  },
  ['SET_SEARCH_TERM'](state, payload) {
    state.searchTerm = payload;
  },
  ['SET_LOCATION'](state, location) {
    state.location = location;
  },
  ['SET_BUSY'](state, value) {
    state.busy = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
