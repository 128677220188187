<template>
  <div class="">
    <div class="flex my-6 items-center">
      <span class="px-4 py-2 bold whitespace-no-wrap ">Forma de Pago</span>
      <div class="flex-initial w-90">
        <sui-dropdown
          placeholder="Seleccione forma de pago"
          fluid
          selection
          :options="options"
          v-model="current"
        />
      </div>
    </div>

    <div class="" v-if="current">
      <span class="stay">{{ getDescription(current).description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentSelector',
  data: function() {
    return {
      current: undefined,
      descriptions: [
        {
          value: 'MP',
          description:
            'Paga en línea con tu tarjeta de crédito o deposito en Oxxo. El pago se refleja de inmediato. Se agrega una comisión del 4% por servicio'
        },
        {
          value: 'TB',
          description:
            'Una vez completado tu pedido, un asesor se comunicará para proporcionar los datos de pago y liberar tu pedido.'
        }
      ]
    };
  },
  watch: {
    current: function(val) {
      if (val !== null) this.$emit('onSelected', this.current);
    }
  },
  computed: {
    isOnlinePaymentEnabled: function() {
      return this.$store.getters['application/onlinePaymentsEnabled'];
    },
    options: function() {
      if (this.isOnlinePaymentEnabled) {
        return [
          {
            value: 'MP',
            text: 'Mercado Pago - En linea'
          },
          {
            value: 'TB',
            text: 'Transferencia Bancaria'
          }
        ];
      } else {
        return [
          {
            value: 'TB',
            text: 'Transferencia Bancaria'
          }
        ];
      }
    }
  },
  methods: {
    getDescription: function(id) {
      console.log(id);
      const desc = this.descriptions.find(o => o.value === id);
      console.log(desc);
      return desc;
    }
  }
};
</script>

<style lang="css" scoped></style>
