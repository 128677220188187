<template>
  <div class="ui main container">
    <div class="">
      <div class="sixteen wide center aligned column">
        <Banner v-if="banners.length > 0" :banners="banners" />
      </div>
      <div class="custom-marquee" v-if="messages.length > 0">
        <marquee-text class="" :repeat="5" :duration="15">
          <span
            v-for="(message, index) in messages"
            class="ui label ml-1"
            :class="message.color"
            :key="'message-' + index"
            >{{ message.message }}</span
          >
        </marquee-text>
      </div>

      <CategorySelector @onSelectionChanged="loadCategoryProds" />
      <CategoryDetails
        v-if="category"
        :category="category"
        @onLoadCategory="loadCategoryProds"
      />

      <div
        class="sixteen wide computer sixteen wide tablet column"
        v-infinite-scroll="loadMore"
        infinite-scroll-disabled="busy"
        infinite-scroll-distance="10"
      >
        <ProductList2 :isMatrix="isMatrix" :isPresale="false" />
        <div v-if="showLoader">
          <br />
          <sui-loader active centered inline>Cargando más productos</sui-loader>
          <br />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import MarqueeText from "vue-marquee-text-component";
import Banner from "../components/app/Banner";
import CategoryDetails from "../components/app/CategoryDetails";
import CategorySelector from "../components/app/CategorySelector";
import ProductList2 from "../components/product/ProductList2";

export default {
  name: "Home",
  data() {
    return {
      showLoader: true,
    };
  },
  computed: {
    banners: function () {
      return this.$store.getters["application/getBanners"];
    },
    busy: function () {
      return this.$store.getters["products/isBusy"];
    },
    isMatrix: function () {
      return this.$store.getters["application/isMatrix"];
    },
    category: function () {
      return this.$store.getters["categories/getCategoryNode"];
    },
    messages: function () {
      return this.$store.getters["application/getMessages"];
    },
  },
  created: function () {
    const { reload, category, brand, attribute } = this.$route.query;

    if (category || brand || attribute) {
    } else {
      if (reload || reload === undefined) {
        this.loadCategoryProds();
      } else {
        this.showLoader = false;
      }
    }
  },
  methods: {
    loadMore: async function () {
      this.showLoader = true;
      const resp = await this.$store.dispatch("products/loadMore");
      this.showLoader = false;
    },
    loadCategoryProds: async function (payload) {
      this.showLoader = true;
      await this.$store.dispatch("products/loadProductsByCategory", payload);
      this.showLoader = false;
      this.setQuery(payload);
    },
    setQuery: function (query) {
      let obj = {};

      if (query) {
        obj = Object.assign({}, this.$route.query);

        Object.keys(query).forEach((key) => {
          let value = query[key];
          if (value) {
            obj[key] = value;
          } else {
            delete obj[key];
          }
        });
      }

      if (this.$route.name === "Home") {
        this.$router
          .replace({
            ...this.$router.currentRoute,
            query: obj,
          })
          .catch(() => {});
      } else {
        this.$router.push({ name: "Home", query: obj }).catch(() => {});
      }
    },
  },
  components: {
    ProductList2,
    Banner,
    CategorySelector,
    CategoryDetails,
    MarqueeText,
  },
};
</script>

<style>
.main.container {
  margin-top: 4.7em !important;
}
.custom-marquee {
  margin-top: 24px;
}
.ml-1 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
</style>
