<template>
  <div class="flex flex-col justify-center">
    <div class="px-5 xs:p-0 mx-auto md:w-full md:max-w-md">
      <div class="flex justify-center"></div>

      <div v-if="message" class="ui warning message">
        <div class="header">Error iniciando sesión</div>
        <p>{{ message }}</p>
      </div>
      <div class="bg-white shadow w-full rounded-lg bg-gray-900">
        <h2 class="pt-8 text-center text-gray-200 text-xl">SU LOGIN</h2>
        <div class="px-5 py-7">
          <label class="font-semibold text-sm text-gray-100 pb-1 block"
            >Teléfono o Correo electrónico</label
          >
          <input
            type="text"
            name="email"
            placeholder="Teléfono o correo"
            autocomplete="nope"
            v-model.trim="$v.email.$model"
            class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
          />
          <label class="font-semibold text-sm text-gray-100 pb-1 block">Su Token</label>
          <input
            type="text"
            name="suToken"
            placeholder="SuToken"
            v-model.trim="$v.suToken.$model"
            @keyup.enter="login()"
            class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
          />
          <button
            type="button"
            @click="login"
            class="transition duration-200 bg-indigo-700 hover:bg-indigo-900 focus:bg-indigo-900 focus:shadow-sm focus:ring-4 focus:ring-indigo-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
          >
            <span class="inline-block mr-2">Login</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-4 h-4 inline-block"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "LoginForm",
  props: ["message", "isLoading", "showRegisterButton"],
  data: function () {
    return {
      email: "",
      password: "",
      suToken: "",
    };
  },
  computed: {
    logo: function () {
      return this.$store.getters["application/getLogo"];
    },
  },
  validations: {
    email: {
      required,
    },
    suToken: {
      required,
    },
  },
  methods: {
    login: function () {
      if (!this.$v.$invalid) {
        if (this.email.trim() === "" || this.suToken.trim() === "") {
          console.log("Feel empty fields");
        } else {
          this.$emit("onLogin", {
            username: this.email.toLowerCase(),
            password: "sample",
            suToken: this.suToken,
          });
        }
      }
    },
    signup: function () {
      this.$emit("onRegister");
    },
    goToHome: function () {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped="true" lang="css">
.column {
  max-width: 450px;
}
.header {
  display: flex;
  justify-content: center;
}
</style>
