<template>
  <div class="cat" :style="{ width: myWidth + 'px' }">
    <h3 class="ui header" v-if="category">
      <div class="content">
        <a
          class="category-link uppercase"
          href="javascript:void(0)"
          @click="loadCategory(category.id)"
          >{{ category.label }}</a
        >
      </div>

      <div class="sub header">{{ category.description }}</div>
    </h3>

    <div class="sub-content">
      <div
        class="ui column fluid ui equal width height grid"
        v-if="category && category.children"
      >
        <div class="column" v-for="(line, index) in lines" :key="index">
          <template v-for="sub in line">
            <h4
              class="ui header list-element show-icon"
              :key="'header' + sub.id"
            >
              <a
                class="subcategory-link"
                href="javascript:void(0)"
                @click="loadCategory(sub.id)"
                >{{ sub.label }}</a
              >
            </h4>
            <div class="ui list" :key="'brand-list-' + sub.id">
              <a
                class="item"
                v-for="brand in sort(sub.brands)"
                :key="'brand-' + brand._id"
                @click="loadCategory(sub.id, brand._id)"
                >{{ brand.name }}</a
              >
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const MAX = 10;

export default {
  name: 'CategoryDropdown',
  props: ['category'],
  computed: {
    myWidth: function() {
      if (this.category && this.category.children)
        return 85 * this.category.children.length || 1;
      return 200;
    },
    lines: function() {
      const categoryChildren = this.category.children.map(item =>
        Object.assign({}, item)
      );
      const sorted_lines = categoryChildren.sort(
        (a, b) => b.brands.length - a.brands.length
      );

      let lines = [];
      let counter = 0;
      let line = [];
      sorted_lines.forEach((item, i) => {
        let sub = { id: item.id, label: item.label, brands: [] };

        item.brands = this.sort(item.brands);
        item.brands.forEach((brand, i) => {
          sub.brands.push(brand);
          counter++;

          if (counter >= MAX) {
            line.push(sub);
            sub = { id: item.id, brands: [] };
            lines.push(line);
            line = [];
            counter = 0;
          }
        });

        if (sub.brands.length > 0) {
          line.push(sub);
        }
      });

      if (line.length > 0) {
        lines.push(line);
      }
      return lines;
    }
  },
  methods: {
    sort(array) {
      const sorted = array.concat();

      return _.orderBy(sorted, 'name', 'asc');
    },
    loadCategory: function(categoryId, brandId) {
      const payload = {
        category: categoryId | null,
        brand: brandId
      };

      this.$emit('onLoadProducts', payload);
    }
  }
};
</script>

<style lang="scss" scoped>
.cat {
  padding: 15px;
  //background-color: #2a394f;
  background: #fff;
}
.category-link {
  color: #023047;
}
.subcategory-link {
  color: #2b2d42;
}
.sub-content {
}
</style>
