import axios from 'axios';

const state = {
  app: {
    _id: '',
    name: '',
    banners: [],
    messages: [],
    logo: '',
    settings: {
      carts: {
        timeout: 20
      },
      orders: {
        products: {
          sortkey: 'price'
        },
        allowGuest: true,
        wholesaleQty: 10,
        allowWholesale: false,
        message: ''
      },
      products: {
        showWholesalePrice: false,
        showStock: false,
        showDetailsButton: true
      },
      payments: {
        public_key: ''
      },
      management: {
        maintenance: {
          enabled: false,
          information: ''
        }
      }
    }
  }
};

const getters = {
  getBanners: state => state.app.banners,
  getCartTimeout: state => state.app.settings.carts.timeout,
  getLogo: state => state.app.logo,
  getName: state => state.app.name,
  getId: state => state.app._id,
  isMatrix: state => state.app.shopType === 'matrix',
  onlinePaymentsEnabled: state => state.app.settings.payments.enabled,
  paymentFee: state => state.app.settings.payments.fee,
  paymentBase: state => state.app.settings.payments.base,
  public_key: state => state.app.settings.payments.public_key,

  showStock: state => state.app.settings.products.showStock,
  showDescription: state => state.app.settings.products.showDescription,
  showDetailsButton: state => state.app.settings.products.showDetailsButton,
  showWholesalePrice: state => state.app.settings.products.showWholesalePrice,

  isWholesaleEnabled: state => state.app.settings.orders.allowWholesale,
  getWholeSaleQty: state => state.app.settings.orders.wholesaleQty,
  getWholeSaleDto: state => state.app.settings.orders.wholesaleDto,
  getOrdersMessage: state => state.app.settings.orders.message,
  isInMaintenance: state => state.app.settings.management.maintenance.enabled,
  allowInvoicing: state => state.app.settings.management.allowInvoicing,
  getMaintenanceInfo: state =>
    state.app.settings.management.maintenance.information,
  getMessages: state => {
    return state.app.messages.filter(m => m.enabled);
  }
};

const actions = {
  loadAppSettings: ({ commit, getters }) => {
    return new Promise(
      (resolve, reject) => {
        axios.get(`/api/v1/app`).then(response => {
          commit('LOAD_APP_CONFIG', response.data);
          resolve(response.data);
        });
      },
      error => {
        reject(error);
      }
    );
  }
};

const mutations = {
  ['LOAD_APP_CONFIG']: (state, payload) => {
    state.app = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
