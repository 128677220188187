<template>
  <div class="ui main container">
    <h2 class="text-3xl font-bold">Facturación electrónica</h2>
    <div class="ui segment" :class="{ loading: loading }" v-if="allowInvoicing">
      <form class="ui form" v-on:submit="(e) => e.preventDefault()">
        <div class="ui fields">
          <div class="inline field">
            <label for="">Número de Pedido:</label>
            <input
              type="number"
              name=""
              v-model="reference"
              placeholder="10158"
              @keyup.enter="findOrder"
            />
          </div>
          <div class="inline field">
            <label for="">Teléfono usado en el Pedido:</label>
            <input
              type="number"
              name=""
              v-model="phone"
              placeholder="3221458970"
              @keyup.enter="findOrder"
            />
          </div>
          <div class="inline field">
            <button class="ui red button" type="button" name="button" @click="findOrder">
              Buscar
            </button>
          </div>
        </div>
      </form>
      <div class="ui error message" v-if="showError">
        <i class="close icon"></i>
        <div class="header">No pudimos encontrar tu pedido</div>
        <ul class="list">
          <li>
            Asegurate de ingresar el número de pedido correctamente, esta indicado en tu
            nota de compra
          </li>
          <li>
            Asi mismo, revisa que el número de teléfono en el pedido sea el mismo que
            estas ingresando aquí
          </li>
          <li>
            El pedido ya debió haber sido <b>Enviado</b>. Si tu pedido aún esta en proceso
            de envío no puedes facturarlo.
          </li>
          <li>Si continuas teniendo problemas comunicate con tu asesor</li>
        </ul>
      </div>
      <div class="ui info message" v-if="showSuccess">
        <i class="close icon"></i>
        <div class="header">Factura generada</div>
        <ul class="list">
          <li>
            La factura fue generada correctamente, se enviará al correo proporcionado
          </li>
          <li>
            Si necesitas que te reenvien la fatura, solo ingresa los datos nuevamente.
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <br />
      <h3 class="text-xl font-bold">
        Por el momento la facturación se encuentra pausada. <br />
        Favor de intentar mas tarde o cominicarse con su asesor. Gracias!
      </h3>
    </div>
    <div class="ui segment" :class="{ loading: loading }" v-if="orderLoaded">
      <h2 class="text-xl font-bold">Datos fiscales para Facturación</h2>

      <div class="ui message">
        <div class="header">Importante</div>
        Los siguientes datos deben ser ingresados de la misma manera que aparecen en su
        Constancia de Situación Fiscal, de lo contrario no se podrá generar su Factura.
        <ul class="list">
          <li>RFC</li>
          <li>Nombre o Razón Social</li>
          <li>Régimen Fiscal</li>
          <li>Código Postal</li>
        </ul>
        <br />
        <b>La factura será enviada al correo electronico que se indique.</b>
      </div>

      <h4 class="ui dividing header">Datos fiscales del Receptor</h4>
      <form class="ui form" v-on:submit="(e) => e.preventDefault()">
        <div class="ui fields">
          <div class="ui required field" :class="{ error: $v.receiver.rfc.$error }">
            <label for="">RFC:</label>
            <input
              class="ui input uppercase"
              type="text"
              name=""
              value=""
              placeholder="RFC"
              v-model.trim="$v.receiver.rfc.$model"
            />
          </div>
          <div
            class="ui required six wide field"
            :class="{ error: $v.receiver.fiscalReg.$error }"
          >
            <label for="">Régimen Fiscal:</label>
            <sui-dropdown
              placeholder="Régimen Fiscal"
              selection
              :options="regimenOpts"
              v-model="receiver.fiscalReg"
            />
          </div>
        </div>

        <div class="ui fields">
          <div
            class="ui seven wide required field"
            :class="{ error: $v.receiver.name.$error }"
          >
            <label for="">Nombre / Razón Social</label>
            <input
              class="uppercase"
              type="text"
              name=""
              value=""
              placeholder="Nombre / Razón Social"
              v-model.trim="$v.receiver.name.$model"
            />
          </div>
        </div>

        <div class="ui fields">
          <div
            class="ui required four wide field"
            :class="{ error: $v.receiver.email.$error }"
          >
            <label for="">Correo Electrónico:</label>
            <input
              class="ui input uppercase"
              type="text"
              name=""
              value=""
              placeholder="Correo electrónico"
              v-model.trim="$v.receiver.email.$model"
            />
          </div>
          <div class="ui required four wide field">
            <label for="">Uso de CFDI:</label>
            <sui-dropdown
              placeholder="Uso de CFDI"
              selection
              :options="cfdiOpts"
              v-model="receiver.usoCFDI"
            />
          </div>
          <div class="ui required four wide field">
            <label for="">Método de Pago:</label>
            <sui-dropdown
              placeholder="Método de Pago"
              selection
              :options="paymentMethodOpts"
              v-model="receiver.paymentMethod"
            />
          </div>
        </div>
        <h4 class="ui dividing header">Dirección Fiscal</h4>

        <div class="ui fields">
          <div class="ui field" :class="{ error: $v.receiver.street.$error }">
            <label for="">Calle</label>
            <input
              type="text"
              name=""
              value=""
              class="uppercase"
              placeholder="Calle"
              v-model.trim="$v.receiver.street.$model"
            />
          </div>
          <div class="ui two wide field" :class="{ error: $v.receiver.numExt.$error }">
            <label for="">Número Exterior</label>
            <input
              type="number"
              name=""
              value=""
              placeholder="Número Exterior"
              v-model.trim="$v.receiver.numExt.$model"
            />
          </div>
          <div class="ui two wide field" :class="{ error: $v.receiver.numInt.$error }">
            <label for="">Número Interior.</label>
            <input
              type="number"
              name=""
              value=""
              placeholder="Número Interior"
              v-model.trim="$v.receiver.numInt.$model"
            />
          </div>
          <div class="ui three wide field" :class="{ error: $v.receiver.neigh.$error }">
            <label for="">Colonia</label>
            <input
              type="text"
              name=""
              value=""
              class="uppercase"
              placeholder="Colonia"
              v-model.trim="$v.receiver.neigh.$model"
            />
          </div>
        </div>
        <div class="ui fields">
          <div
            class="ui required field"
            :class="{ error: $v.receiver.postalCode.$error }"
          >
            <label for="">Código Postal</label>
            <input
              type="number"
              name=""
              value=""
              placeholder="Código Postal"
              v-model.trim="$v.receiver.postalCode.$model"
            />
          </div>
          <div class="ui field" :class="{ error: $v.receiver.city.$error }">
            <label for="">Delegación/Municipio</label>
            <input
              type="text"
              name=""
              value=""
              class="uppercase"
              placeholder="Municipio"
              v-model.trim="$v.receiver.city.$model"
            />
          </div>
          <div class="ui field" :class="{ error: $v.receiver.state.$error }">
            <label for="">Estado</label>
            <input
              type="text"
              name=""
              value=""
              class="uppercase"
              placeholder="Estado"
              v-model.trim="$v.receiver.state.$model"
            />
          </div>
        </div>
        <div class="ui two wide field">
          <button
            class="ui blue fluid button"
            @click="generateInvoice"
            :class="{ disabled: $v.$invalid, disabled: loading }"
          >
            Solicitar Factura
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
  alphaNum,
} from "vuelidate/lib/validators";

export default {
  name: "Invoice",
  data: function () {
    return {
      reference: "",
      cfdiOpts: [
        { value: "G01", text: "G01 - Adquisición de mercancías." },
        { value: "G02", text: "G02 - Devoluciones, descuentos o bonificaciones." },
        { value: "G03", text: "G03 - Gastos en general" },
        { value: "I01", text: "I01 - Construcciones." },
        { value: "I02", text: "I02 - Mobiliario y equipo de oficina por inversiones." },
        { value: "I03", text: "I03 - Equipo de transporte." },
        { value: "I04", text: "I04 - Equipo de computo y accesorios." },
        { value: "I05", text: "I05 - Dados, troqueles, moldes, matrices y herramental." },
        { value: "I06", text: "I06 - Comunicaciones telefónicas." },
        { value: "S01", text: "S01 - Sin efectos fiscales." },
        { value: "CP01", text: "CP01 - Pagos" },
      ],
      regimenOpts: [
        { value: "601", text: "601 - General de Ley Personas Morales" },
        { value: "603", text: "603 - Personas Morales con Fines no Lucrativos" },
        {
          value: "605",
          text: "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios",
        },
        { value: "606", text: "606 - Arrendamiento" },
        { value: "607", text: "607 - Régimen de Enajenación o Adquisición de Bienes" },
        { value: "608", text: "608 - Demás ingresos" },
        { value: "609", text: "609 - Consolidación" },
        {
          value: "610",
          text:
            "610 - Residentes en el Extranjero sin Establecimiento Permanente en México",
        },
        { value: "611", text: "611 - Ingresos por Dividendos (socios y accionistas)" },
        {
          value: "612",
          text: "612 - Personas Físicas con Actividades Empresariales y Profesionales",
        },
        { value: "614", text: "614 - Ingresos por intereses" },
        { value: "615", text: "615 - Régimen de los ingresos por obtención de premios" },
        { value: "616", text: "616 - Sin obligaciones fiscales" },
        {
          value: "620",
          text:
            "620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
        },
        { value: "621", text: "621 - Incorporación Fiscal" },
        {
          value: "622",
          text: "622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
        },
        { value: "623", text: "623 - Opcional para Grupos de Sociedades" },
        { value: "624", text: "624 - Coordinados" },
        {
          value: "625",
          text:
            "625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
        },
        { value: "626", text: "626 - Régimen Simplificado de Confianza" },
        { value: "628", text: "628 - Hidrocarburos" },
        {
          value: "629",
          text:
            "629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
        },
        { value: "630", text: "630 - Enajenación de acciones en bolsa de valores" },
      ],
      paymentMethodOpts: [
        { value: "01", text: "Efectivo" },
        { value: "02", text: "Cheque Nominativo" },
        { value: "03", text: "Transferencia electrónica de Fondos" },
      ],
      regimenSelected: null,
      selectedCfdi: null,
      orderLoaded: false,
      orderSelected: null,
      loading: false,
      phone: "",
      showError: false,
      showSuccess: false,
      receiver: {
        name: "",
        rfc: "",
        street: "",
        numExt: "",
        numInt: "",
        neigh: "",
        email: "",
        fiscalReg: "",
        postalCode: "",
        usoCFDI: "",
        paymentMethod: "",
        city: "",
        state: "",
      },
    };
  },
  computed: {
    allowInvoicing: function () {
      return this.$store.getters["application/allowInvoicing"];
    },
  },
  validations: {
    receiver: {
      name: { required },
      rfc: {
        required,
        alphaNum,
        minLength: minLength(12),
        maxLength: maxLength(13),
      },
      fiscalReg: { required },
      street: { required },
      email: { required, email },
      numExt: { numeric },
      numInt: { numeric },
      neigh: { required },
      city: { required },
      state: { required },
      postalCode: { required, numeric },
    },
  },
  methods: {
    findOrder: async function () {
      this.loading = true;
      this.showError = false;
      this.orderLoaded = false;
      this.showSuccess = false;

      if (
        this.reference &&
        this.isNumeric(this.reference) &&
        this.phone &&
        this.isNumeric(this.phone)
      ) {
        const response = await this.$store.dispatch("invoice/findOrder", {
          reference: this.reference,
          phone: this.phone,
        });

        this.loading = false;

        if (response.status === "OK") {
          this.orderLoaded = true;
          this.orderSelected = response.order;
          this.showError = false;
        } else {
          this.showError = true;
          this.orderSelected = null;
        }
      } else {
        this.loading = false;
        this.showError = false;
        this.orderLoaded = false;
        this.showSuccess = false;
      }
    },
    generateInvoice: async function () {
      if (this.orderLoaded) {
        this.loading = true;
        try {
          const response = await this.$store.dispatch("invoice/send", {
            orderId: this.orderSelected.reference,
            receiver: this.receiver,
          });
          this.showSuccess = true;
          this.resetData();
        } catch (error) {
          console.log("Error", error);
        } finally {
          this.loading = false;
        }
      } else {
        console.log("Order not loaded");
      }
    },
    resetData: function () {
      this.receiver = {
        name: "",
        rfc: "",
        street: "",
        numExt: "",
        numInt: "",
        neigh: "",
        email: "",
        fiscalReg: "",
        postalCode: "",
        usoCFDI: "",
        paymentMethod: "",
        city: "",
        state: "",
      };
      this.orderLoaded = false;
      this.orderSelected = null;
      this.reference = "";
      this.phone = "";
    },
    isNumeric: function (value) {
      return /^-?\d+$/.test(value);
    },
  },
};
</script>

<style lang="css" scoped></style>
