<template>
  <li class="list" :class="{ active: show }">
    <a href="#" @click="show = !show">{{ category.label }} </a>
    <ul class="items">
      <li>
        <a href="javascript:void(0)" @click="loadCategory(category.id)"
          >Todo de {{ category.label }}</a
        >
      </li>
      <SubCollapse
        v-for="sub in category.children"
        :key="sub.id"
        :sub="sub"
        :categoryId="category.id"
        :categoryLabel="category.label"
        @onLoadProducts="loadProducts"
      />
    </ul>
  </li>
</template>

<script>
import SubCollapse from './SubCollapse';

export default {
  name: 'Collapse',
  props: ['category'],
  data() {
    return {
      show: false
    };
  },
  methods: {
    loadCategory: function(categoryId, brandId) {
      const payload = {
        category: categoryId | null,
        brand: brandId
      };

      const params = {};

      this.$emit('onLoadProducts', payload);
    },

    loadProducts: function(payload) {
      this.$emit('onLoadProducts', payload);
    }
  },
  components: {
    SubCollapse
  }
};
</script>

<style lang="scss" scoped></style>
