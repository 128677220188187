<template>
  <div class="">
    <div class="card py-3 flex flex-col mb-5">
      <div class="bg-white shadow-md rounded my-4">
        <table class="w-full text-sm lg:text-base" cellspacing="0">
          <thead>
            <tr class="bg-gray-300 text-gray-600 uppercase text-sm leading-normal">
              <th class="hidden md:table-cell"></th>
              <th class="py-3 px-3 text-left">Producto</th>
              <th class="lg:text-center text-left pl-2 lg:pl-0">
                <span class="lg:hidden" title="Cantidad">Cant</span>
                <span class="hidden lg:inline">Cantidad</span>
              </th>
              <th class="hidden text-center md:table-cell">Precio Unitario</th>
              <th class="pr-3 text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <ProductDetails
              v-for="product in cart.products"
              :key="product.attributeId"
              :product="product"
              :show-buttons="false"
              :isMatrix="isMatrix"
              :isWholesale="isWholesale"
            />
          </tbody>
        </table>
      </div>
    </div>

    <div class="card py-3 px-5 flex flex-col mb-5 bg-white shadow-lg pin-r pin-y">
      <h3 class="font-bold text-lg uppercase">Dirección de Entrega</h3>
      <div class="card">
        <div class="content">
          <div class="header">
            <h4>{{ `${customer.name} ${customer.lastName}` }}</h4>
          </div>
          <div class="description">
            {{ address.street }} <br />
            {{ address.neigh }}<br />
            {{ address.postalCode }}<br />
            {{ address.city }}, {{ address.state }}<br />
            {{ address.country }}
          </div>
        </div>
      </div>
    </div>

    <div class="card py-3 px-5 flex flex-col mb-5 bg-white shadow-lg pin-r pin-y">
      <h3 class="font-bold text-lg uppercase">Método de Envío</h3>
      <ShippingSelector @onSelected="onShippingSelected" @onSecured="secureShipping" />
    </div>
    <div class="card py-3 px-5 flex flex-col mb-5 bg-white shadow-lg pin-r pin-y">
      <h3 class="font-bold text-lg uppercase">Forma de Pago</h3>
      <PaymentSelector @onSelected="onPaymentSelected" />
    </div>

    <div class="card py-3 px-5 flex flex-col mb-5 bg-white shadow-lg pin-r pin-y">
      <h3 class="font-bold text-lg uppercase">Términos y Condiciones</h3>
      <span class="text-red-700 mt-3"
        >Favor de leer y aceptar los términos y condiciones ya que contienen información
        importante referente a su proceso de compra</span
      >
      <sui-checkbox
        class="my-3"
        :label="`Mostrar y aceptar los términos y condiciones.`"
        v-model="tacToggleValue"
        @click="showTac"
        toggle
      />
      <sui-modal v-model="showTacModal" :closable="false" size="small">
        <sui-modal-header>Términos y Condiciones</sui-modal-header>
        <sui-modal-content scrolling>
          <sui-modal-description>
            <img
              v-if="tac2"
              class="ui image"
              src="https://catalog-shop.s3.amazonaws.com/BAZAHU/694a9578-27d9-4481-b549-58b911309b14.JPG"
            />
            <img
              v-if="tac1"
              class="ui image"
              src="https://catalog-shop.s3.amazonaws.com/BAZAHU/bc7310dc-5c14-429c-989e-3211c6883a73.JPG"
            />
          </sui-modal-description>
        </sui-modal-content>
        <sui-modal-actions>
          <button class="ui gray button" @click="cancelTac">Cancelar</button>
          <button class="ui black button" @click="acceptTac">Acepto</button>
        </sui-modal-actions>
      </sui-modal>
    </div>

    <div class="card py-3 px-5 flex flex-col mb-5 bg-white shadow-lg pin-r pin-y">
      <Summary
        :shipping="shipping"
        :isSecured="isSecured"
        :paymentMethod="paymentMethod"
      />
      <div
        class="ui right uppercase floated black button"
        :class="{
          disabled: !isCompleted || !isTacAccepted || isLoading,
          loading: isLoading,
        }"
        @click="submitOrder"
      >
        CONFIRMAR PEDIDO Y PAGAR
      </div>
    </div>
  </div>
</template>

<script>
import VueNumericInput from "vue-numeric-input";
import ProductDetails from "../product/ProductDetails2";
import ShippingSelector from "../shipping/ShippingSelector";
import PaymentSelector from "../payments/PaymentSelector";
import Summary from "../checkout/Summary";
import MetricsService, { EventKey } from "@/services/metrics";

export default {
  name: "OrderConfirmationForm",
  data: function () {
    return {
      shipping: {
        price: 0,
      },
      paymentMethod: "",
      isSecured: false,
      isCompleted: false,
      orderSubmited: false,
      paymentSubmited: false,
      showTacModal: false,
      tacToggleValue: false,
      isTacAccepted: false,
      tac1: true,
      tac2: false,
      isLoading: false,
    };
  },
  computed: {
    cart: function () {
      return this.$store.getters["cart/getCart"];
    },
    customer: function () {
      return this.$store.getters["auth/getCustomer"];
    },
    address: function () {
      if (this.customer.type === "guest") {
        return this.customer.addresses[0];
      } else {
        const addressId = this.$store.getters["auth/getTargetAddress"];
        return this.customer.addresses.find((a) => a._id === addressId);
      }
    },
    isMatrix: function () {
      return this.$store.getters["application/isMatrix"];
    },
    itemsForWholesale: function () {
      return this.$store.getters["application/getWholeSaleQty"];
    },
    isWholesaleEnabled: function () {
      return this.$store.getters["application/isWholesaleEnabled"];
    },
    totalItems: function () {
      return this.$store.getters["cart/totalItems"];
    },
    isWholesale: function () {
      if (this.isWholesaleEnabled) {
        return this.totalItems >= this.itemsForWholesale;
      } else {
        return false;
      }
    },
  },
  methods: {
    showTac: function () {
      this.tac1 = true;
      this.tac2 = false;
      this.showTacModal = true;
    },
    acceptTac: function () {
      if (this.tac1) {
        this.tac1 = false;
        this.tac2 = true;
        this.tacToggleValue = false;
      } else if (this.tac2) {
        this.isTacAccepted = true;
        this.showTacModal = false;
        this.tacToggleValue = true;
      }
    },
    cancelTac: function () {
      this.tac1 = true;
      this.tac2 = false;
      this.isTacAccepted = false;
      this.showTacModal = false;
      this.tacToggleValue = false;
    },
    onShippingSelected: function (shipping) {
      this.shipping = shipping;
    },
    onPaymentSelected: function (paymentMethod) {
      this.paymentMethod = paymentMethod;
      this.isCompleted = true;
    },
    secureShipping: function (secured) {
      this.isSecured = secured;
    },
    submitOrder: async function () {
      if (this.isCompleted && this.isTacAccepted) {
        this.$emit("loading", true);
        this.isLoading = true;
        try {
          const resp = await this.$store.dispatch("cart/checkout", {
            shippingId: this.shipping._id,
            secured: this.isSecured,
            payment_method: this.paymentMethod,
          });

          /* Handle payment integration*/
          if (resp.status === "OK") {
            this.$emit("loading", false);
            this.$router.push({
              name: "Order",
              params: { id: resp.order._id, preferenceId: resp.preferenceId },
            });
            // metric new order
            MetricsService.emitEvent(
              { name: EventKey.ORDER_CREATED },
              {
                amount: resp.order.total,
                payment_method: this.payment_method,
              }
            );
          } else {
            console.log("Error creating order");
          }
        } catch (err) {
          console.log("Error checking out order", err);
        } finally {
          this.isLoading = false;
          this.$emit("loading", false);
        }
      } else {
        console.log("Show select shipping method");
      }
    },
  },
  components: {
    ProductDetails,
    ShippingSelector,
    PaymentSelector,
    Summary,
  },
};
</script>

<style></style>
