<template>
  <div v-if="order.customer" class="">
    <div class="ui horizontal equal width segments">
      <div class="ui purple segment half">
        <div class="">
          <h4 class="text-lg font-bold">Información del pedido</h4>
          <div class="ui list">
            <div class="item">
              <i class="hashtag icon"></i>
              <div class="content">{{ order.reference }}</div>
            </div>
            <div class="item">
              <i class="calendar alternate outline icon"></i>
              <div class="content">
                {{ order.createdOn | moment("DD/MM/YYYY hh:mm a") }}
              </div>
            </div>

            <br />
            <div class="large item">
              <div class="ui gray large blue label">
                <i class="flag icon"></i> {{ getOrderStatus.text }}
              </div>
            </div>
            <div class="item" v-if="order.trackingCode">
              <div class="ui blue large basic label">
                Num. de rastreo:
                <a class="detail">
                  {{ order.trackingCode }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ui green segment">
        <div class="">
          <h4 class="text-lg font-bold">Información de envío</h4>
          <div class="ui list">
            <div class="item">
              <i class="address card outline icon"></i>
              <div class="content">
                {{ `${order.customer.name} ${order.customer.lastName}` }}
              </div>
            </div>
            <div class="item">
              <i class="mobile alternate icon"></i>
              <div class="content">
                {{ `${order.customer.phone}` }}
              </div>
            </div>
            <div class="item">
              <i class="map marker alternate icon"></i>
              <div class="content">
                {{ `${address.street}, ${address.neigh}` }}

                <br />
                {{ `${address.city}, ${address.state} ${address.country}` }}
                <br />

                {{ `${address.postalCode}` }}
              </div>
            </div>
            <div class="item">
              <i class="truck alternate icon"></i>
              <div class="content">
                {{
                  `${order.shipping.details.name} - ${order.shipping.details.description}`
                }}
              </div>
            </div>
            <div v-if="order.shipping.insurance > 0" class="item">
              <i class="shield alternate icon"></i>
              <div class="content">Envio asegurado</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ui horizontal segments">
      <div class="ui green center aligned segment">
        <div class="ui blue large label">
          Subtotal:
          <a class="detail">
            {{ totalProducts | numeralFormat("$0,0.00") }}
          </a>
        </div>
      </div>
      <div class="ui green center aligned segment">
        <div class="ui grey large label">
          Envio:
          <a class="detail">{{
            (order.shipping.cost + order.shipping.insurance + order.shipping.extraFee)
              | numeralFormat("$0,0.00")
          }}</a>
        </div>
      </div>
      <div class="ui green center aligned segment">
        <div class="ui teal large label">
          Total:
          <a class="detail">
            {{ orderTotal | numeralFormat("$0,0.00") }}
          </a>
        </div>
      </div>
    </div>
    <h3 class="text-2xl font-bold">Productos</h3>
    <div class="ui olive segment">
      <div class="ui items">
        <ProductDetails
          v-for="product in order.products"
          :product="product"
          :show-buttons="false"
          :key="product._id"
          :isWholesale="isWholesale"
          :isMatrix="isMatrix"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueNumericInput from "vue-numeric-input";
import ProductDetails from "../product/ProductDetails";

export default {
  name: "OrderDetails",
  props: ["order"],
  data: function () {
    return {
      statusOptions: [
        {
          text: "Esperando pago",
          value: "WAITING_PAYMENT",
          label: { color: "orange", empty: true, circular: true },
        },
        {
          text: "Pago aceptado",
          value: "PAYMENT_ACCEPTED",
          label: { color: "blue", empty: true, circular: true },
        },
        {
          text: "Reservado",
          value: "RESERVED",
          label: { color: "purple", empty: true, circular: true },
        },
        {
          text: "Enviado",
          value: "SHIPPED",
          label: { color: "green", empty: true, circular: true },
        },
        {
          text: "Cancelado",
          value: "CANCELLED",
          label: { color: "red", empty: true, circular: true },
        },
        {
          text: "Unido",
          value: "JOINED",
          label: { color: "orange", empty: true, circular: true },
        },
      ],
    };
  },
  computed: {
    address: function () {
      return this.order.customer.addresses.find((a) => a._id === this.order.address);
    },
    orderTotal: function () {
      if (this.isMatrix) {
        return this.order.total + this.order.fee;
      } else {
        return this.order.shopTotal + this.order.fee;
      }
    },
    totalProducts: function () {
      return this.order.products.reduce(
        (total, product) =>
          total +
          product.quantity *
            (this.isWholesale
              ? this.isMatrix
                ? product.wholesalePrice
                : product.shopWholesalePrice
              : this.isMatrix
              ? product.price
              : product.shopPrice),
        0
      );
    },
    totalPieces: function () {
      return this.order.products.reduce((total, product) => total + product.quantity, 0);
    },
    getOrderStatus: function () {
      return this.statusOptions.find((s) => s.value === this.order.status);
    },
    wholesaleQty: function () {
      return this.$store.getters["application/getWholeSaleQty"];
    },
    isWholesaleEnabled: function () {
      return this.$store.getters["application/isWholesaleEnabled"];
    },
    isWholesale: function () {
      if (this.isWholesaleEnabled) {
        return this.totalPieces >= this.wholesaleQty;
      } else {
        return false;
      }
    },
    isMatrix: function () {
      return this.$store.getters["application/isMatrix"];
    },
  },
  methods: {},
  components: {
    ProductDetails,
  },
};
</script>

<style lang="css" scoped>
.half {
  min-width: 200px !important;
}
</style>
