import { render, staticRenderFns } from "./LoginHolder.vue?vue&type=template&id=bb2cd9f8&scoped=true&"
import script from "./LoginHolder.vue?vue&type=script&lang=js&"
export * from "./LoginHolder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb2cd9f8",
  null
  
)

export default component.exports