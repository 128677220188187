import axios from 'axios';

const state = {

};

const getters = {

};

const actions = {
    findOrder: ({ commit, getters }, params) => {
        return new Promise(
            (resolve, reject) => {
                axios.get(`/api/v1/orders/reference/${params.reference}/invoice?phone=${params.phone}`).then(response => {
                    const { data } = response;

                    if (data.status === "ERROR") {
                        commit(
                            'ERROR_MSG',
                            {
                                title: 'Error!',
                                content: "Pedido no encontrado o estatus inválido"
                            },
                            { root: true }
                        );
                    }

                    resolve(response.data)



                },
                    error => {
                        // http failed, let the calling function know that action did not work out
                        reject(error);
                    }
                );


            });
    },
    send: ({ commit, getters }, payload) => {
        return new Promise(
            (resolve, reject) => {
                axios.post(`/api/v1/invoice`, payload).then(response => {
                    const data = response.data;

                    if (data.status === 'OK') {
                        commit(
                            'OK_MSG',
                            {
                                title: 'Factura generada!',
                                content: data.message
                            },
                            { root: true }
                        );
                        resolve(data);
                    } else {

                        if (data.detailMessage) {
                            commit(
                                'ERROR_MSG',
                                {
                                    title: 'Error!',
                                    content: data.detailMessage
                                },
                                { root: true }
                            );
                            reject(null);
                        } else {
                            commit(
                                'ERROR_MSG',
                                {
                                    title: 'Error!',
                                    content: data.message
                                },
                                { root: true }
                            );
                            reject(null);
                        }

                    }

                },
                    error => {
                        // http failed, let the calling function know that action did not work out
                        reject(error);
                    }
                );


            });
    }
};

const mutations = {

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
