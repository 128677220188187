<template>
  <div class="ui segment">
    <div class="ui stackable grid">
      <div class="eleven wide computer nine wide tablet ten wide mobile column">
        <CategoryLocation @onLoadCategory="loadCategoryProds" />
      </div>
      <div
        class="five wide computer six wide tablet column sixteen wide mobile"
      >
        <div class="flex">
          <SearchFilter @onFilterChange="applyFilter" />
          <div class=" flex-1 ui action six wide">
            <form class="ui form" v-on:submit="e => e.preventDefault()">
              <div class="inline field">
                <div class="ui fluid action input">
                  <input
                    type="text"
                    placeholder="Buscar por nombre, marca, etc..."
                    v-model="searchTerm"
                    @keyup.enter="enterClicked()"
                  />
                  <button class="ui teal icon button" @click="search">
                    <i class="search icon"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryLocation from './CategoryLocation';
import SearchFilter from './SearchFilter';
import MetricsService, { EventKey } from '@/services/metrics';

export default {
  name: 'CategorySelector',
  data: function() {
    return {
      searchTerm: ''
    };
  },
  computed: {
    categories: function() {
      return this.$store.getters['categories/getTree'];
    },
    brands: function() {
      return this.$store.getters['brands/getBrandsTree'];
    },
    options: function() {
      return this.categories.concat(this.brands);
    }
  },
  created: async function() {},
  methods: {
    open: function() {
      this.isOpen = true;
    },
    applyFilter: function(payload) {
      this.$emit('onSelectionChanged', payload);
    },
    search: function() {
      if (this.searchTerm) {
        this.$emit('onSelectionChanged', {
          searchTerm: this.searchTerm
        });

        // metric search with search term
        MetricsService.emitEvent(
          { name: EventKey.SEARCH },
          {
            search_term: this.searchTerm
          }
        );
        this.searchTerm = '';
      }
    },
    loadCategoryProds: function(payload) {
      this.$emit('onSelectionChanged', payload);
    },
    enterClicked: function() {
      this.search();
    }
  },
  components: {
    CategoryLocation,
    SearchFilter
  }
};
</script>

<style lang="css" scoped></style>
